import * as React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function CircularProgressBar(props) {
  const { primaryColor, secondaryColor, size, thickness, value } = props;
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: secondaryColor,
        }}
        size={size}
        thickness={thickness}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        sx={{
          color: primaryColor,
          position: "absolute",
          left: 0,
        }}
        size={size}
        thickness={thickness}
        value={value}
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p className="text-xs" style={{ color: primaryColor }}>{`${Math.round(
          props.value
        )}%`}</p>
      </Box>
    </Box>
  );
}

CircularProgressBar.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

CircularProgressBar.defaultProps = {
  primaryColor: "#FF6C44",
  secondaryColor: "#FAC1B8",
  size: 50,
  thickness: 4,
};
