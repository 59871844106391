import {
  Autocomplete,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import CustomSelectField from "components/FormComponents/CustomSelectField";
import CustomTextField from "components/FormComponents/CustomTextField";
import { FetchDiagnosisDataQuery } from "Pages/Clients/ClientQueries";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { HiInformationCircle } from "react-icons/hi";
const interventionData = [
  {
    id: 1,
    label: "Acceptance and Commitment Therapy ",
    value: "Acceptance and Commitment Therapy ",
  },
  {
    id: 2,
    label: "Cognitive Behavioral Therapy (CBT)",
    value: "Cognitive Behavioral Therapy (CBT)",
  },
  {
    id: 3,
    label: "Cognitive Challenging",
    value: "Cognitive Challenging",
  },
  {
    id: 4,
    label: "Cognitive Refocusing",
    value: "Cognitive Refocusing",
  },
  {
    id: 5,
    label: "Cognitive Reframing",
    value: "Cognitive Reframing",
  },
  {
    id: 6,
    label: "Communication Skills",
    value: "Communication Skills",
  },
  {
    id: 7,
    label: "Dialectical Behavioral Therapy(DBT)",
    value: "Dialectical Behavioral Therapy(DBT)",
  },
  {
    id: 8,
    label: "Emotion Focused Therapy(EFT)",
    value: "Emotion Focused Therapy(EFT)",
  },
  {
    id: 9,
    label: "EMDR",
    value: "EMDR",
  },
  {
    id: 10,
    label: "Evaluation",
    value: "Evaluation",
  },
  {
    id: 11,
    label: "Exploration of Coping Patterns",
    value: "Exploration of Coping Patterns",
  },
  {
    id: 12,
    label: "Exploration of Emotions",
    value: "Exploration of Emotions",
  },
  {
    id: 13,
    label: "Exploration of Relationship Patterns",
    value: "Exploration of Relationship Patterns",
  },
  {
    id: 14,
    label: "Exposure Therap",
    value: "Exposure Therap",
  },
  {
    id: 15,
    label: "Gottman",
    value: "Gottman",
  },
  {
    id: 16,
    label: "Guided Imagery",
    value: "Guided Imagery",
  },
  {
    id: 17,
    label: "Insight",
    value: "Insight",
  },
  {
    id: 18,
    label: "Interactive Feedback",
    value: "Interactive Feedback",
  },
  {
    id: 19,
    label: "Interpersonal Resolutions",
    value: "Interpersonal Resolutions",
  },
  {
    id: 20,
    label: "Mentalization",
    value: "Mentalization",
  },
  {
    id: 21,
    label: "Mindfulness-Based Cognitive Therapy (MBCT)",
    value: "Mindfulness-Based Cognitive Therapy (MBCT)",
  },
  {
    id: 22,
    label: "Mindfulness Training",
    value: "Mindfulness Training",
  },
  {
    id: 23,
    label: "Preventative Services",
    value: "Preventative Services",
  },
  {
    id: 24,
    label: "Problem Solving",
    value: "Problem Solving",
  },
  {
    id: 25,
    label: "Psychodynamic",
    value: "Psychodynamic",
  },
  {
    id: 26,
    label: "Psychoeducation",
    value: "Psychoeducation",
  },
  {
    id: 27,
    label: "Reflection",
    value: "Reflection",
  },
  {
    id: 28,
    label: "Socratic Questions",
    value: "Socratic Questions",
  },
  {
    id: 29,
    label: "Solution Focused",
    value: "Solution Focused",
  },
  {
    id: 30,
    label: "Taught Coping Skills",
    value: "Taught Coping Skills",
  },
  {
    id: 31,
    label: "Validation",
    value: "Validation",
  },
  {
    id: 32,
    label: "Other",
    value: "Other",
  },
];
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "50vh",
      //   width: 250,
    },
  },
};
const mentalStateData = [
  {
    id: 1,
    label: "congruent",
    value: "congruent",
  },
  {
    id: 2,
    label: "incongruent",
    value: "incongruent",
  },
  {
    id: 3,
    label: "appropriate to subject",
    value: "appropriate to subject",
  },
];

const homeWorkData = [
  {
    id: 1,
    label: "skill",
    value: "skill",
  },
  {
    id: 2,
    label: "journal prompt",
    value: "journal prompt",
  },
  {
    id: 3,
    label: "course",
    value: "course",
  },
];

const PsychotherapyNote = ({ disabled }) => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const presenting_data = useWatch({
    control,
    name: "presenting_data",
  });
  //  queries
  const { data: diagnosisData } = FetchDiagnosisDataQuery();

  const { ref: mentalStateRef, ...mentalStateReg } = register("mentalstate");
  const { ref: additional_notesRef, ...additional_notesReg } =
    register("additional_notes");

  const mentalState = useWatch({
    control,
    name: "mental_state",
  });
  const intervention = useWatch({
    control,
    name: "intervention",
  });

  const homework = useWatch({
    control,
    name: "homework",
  });
  const handleSetValue = (key, val) => {
    setValue(key, val, { shouldValidate: true });
  };

  return (
    <div className="my-8 space-y-4">
      <div>
        <Typography
          variant="body2"
          fontWeight={500}
          marginTop="2rem !important"
          marginBottom={1}
        >
          Diagnosis:
        </Typography>

        <Autocomplete
          value={presenting_data || []}
          onChange={(event, value) => {
            let ids = value?.map((val) => val.id);

            if (ids) setValue("presenting_data", value);
          }}
          options={
            diagnosisData?.data?.map((item) => {
              return {
                id: item.id,
                name: `${item.code} - ${item.name}`,
              };
            }) || []
          }
          disableCloseOnSelect
          disabled={disabled}
          getOptionLabel={(option) => option.name}
          renderOption={(prop, option) => <li {...prop}>{option?.name}</li>}
          isOptionEqualToValue={(option, value) => {
            return Boolean(option.id === value.id);
          }}
          multiple
          renderInput={(params) => (
            <TextField
              className="borderless-select"
              {...params}
              variant="standard"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={disabled}
                      onClick={() =>
                        openInNewTab("https://habitbetter.com/diagnosticcodes/")
                      }
                    >
                      <HiInformationCircle className="w-6 text-hb_orange-dark" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </div>
      <CustomTextField
        label="Mental State"
        variant="standard"
        multiline
        rows={3}
        inputRef={mentalStateRef}
        {...mentalStateReg}
        disabled={disabled}
        error={errors.intervention}
        helperText={errors.intervention && errors.intervention.message}
      />

      <CustomSelectField
        fullWidth
        variant="standard"
        className="my-4"
        label="Affect"
        options={mentalStateData || []}
        value={mentalState}
        onChange={(e) => setValue("mental_state", e.target.value)}
        disabled={disabled}
        error={errors.mental_state}
        helperText={errors.mental_state && errors.mental_state.message}
      />

      <Typography variant="body2" fontWeight={500} marginTop="2rem !important">
        Intervention
      </Typography>
      {/* <CustomSelectField
        fullWidth
        variant="standard"
        options={interventionData || []}
        value={intervention}
        onChange={(e) => handleSetValue("intervention", e.target.value)}
        MenuProps={MenuProps}
        disabled={disabled}
        error={errors.intervention}
        helperText={errors.intervention && errors.intervention.message}
      /> */}
        <Autocomplete
          value={intervention || []}
          onChange={(event, value) => {
            let ids = value?.map((val) => val.id);

            if (ids) setValue("intervention", value);
          }}
          options={
            interventionData || []
          }
          disableCloseOnSelect
          disabled={disabled}
          getOptionLabel={(option) => option.label}
          renderOption={(prop, option) => <li {...prop}>{option?.label}</li>}
          isOptionEqualToValue={(option, value) => {
            return Boolean(option.id === value.id);
          }}
          multiple
          renderInput={(params) => (
            <TextField
              className="borderless-select"
              {...params}
              variant="standard"
              InputProps={{
                ...params.InputProps
              }}
            />
          )}
        />

      <Typography
        variant="body2"
        fontWeight={100}
        marginTop="2rem !important"
        marginBottom={1}
      >
        Intervention Description
      </Typography>
      <textArea
        type="text"
        className="mr-3 mt-5 border-b-2 sm:w-80 md:w-full"
        disabled={disabled}
        {...register("text_intervention_data")}
      ></textArea>
      <Typography
        variant="body2"
        /* fontWeight={500} */ marginTop="2rem !important"
      >
        Homework
      </Typography>
      <CustomSelectField
        fullWidth
        variant="standard"
        options={homeWorkData || []}
        value={homework}
        onChange={(e) => setValue("homework", e.target.value)}
        disabled={disabled}
        error={errors.homework}
        helperText={errors.homework && errors.homework.message}
      />

      <Typography
        variant="body2"
        fontWeight={100}
        marginTop="2rem !important"
        marginBottom={1}
      >
        Homework Description
      </Typography>
      <textArea
        type="text"
        className="mr-3 mt-5 border-b-2 sm:w-80 md:w-full"
        disabled={disabled}
        {...register("text_homeWork_data")}
      ></textArea>

      <CustomTextField
        label="Additional Session Notes"
        variant="standard"
        multiline
        rows={4}
        inputRef={additional_notesRef}
        {...additional_notesReg}
        disabled={disabled}
        error={errors.additional_notes}
        helperText={errors.additional_notes && errors.additional_notes.message}
      />
    </div>
  );
};

export default PsychotherapyNote;
