import { Button, Checkbox } from "@mui/material";
import { CustomTextField } from "components";
import MuiPhoneNumber from "material-ui-phone-number";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AddressAutocompleteTextfield from "components/AddressAutocompleteTextfield";
import { useEffect } from "react";

const userFormSchema = yup.object().shape({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  email: yup
    .string()
    .required("Email is required")
    .email("Email must be a valid email"),
  phone: yup.string().required("Phone is required"),
  street: yup.string().required("Street is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  zip_code: yup.string().required("Zip code is required"),
  is_primary: yup.boolean(),
});

const InviteClientUserForm = ({
  handleParentFormData,
  editIndex,
  divRef,
  values,
  clientType,
  onKeyDown
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
    reset,
    clearErrors,
  } = useForm({
    resolver: yupResolver(userFormSchema),
    defaultValues: {
      ...values,
    },
  });
  const { zip_code, street, city, state, is_primary } = watch();
  const { ref: refFirstName, ...RegisterFirstName } = register("first_name");
  const { ref: refLastName, ...RegisterLastName } = register("last_name");
  const { ref: refEmail, ...RegisterEmail } = register("email");
  const { ref: refCity, ...RegisterCity } = register("city");
  const { ref: refState, ...RegisterState } = register("state");
  const { ref: refZipCode, ...RegisterZipCode } = register("zip_code");

  const handleGoogleAddress = (address) => {
    setValue("state", address.state);
    setValue("street", address.address);
    setValue("city", address.city);
    setValue("zip_code", address.postal_code);
    clearErrors("state");
    clearErrors("street");
    clearErrors("city");
    if(address?.postal_code){
      clearErrors("zip_code");
    }
  };
  const handleManualStreetAddress = (street) => {
    setValue("street", street);
    clearErrors("street");
  };

  const handleFormReset = () => {
    reset({
      first_name: "",
      last_name: "",
      email: "",
      phone: "", 
      street: "",
      city: "",
      state: "",
      zip_code: "",
      is_primary: false,
    });
  }

  const removeSpecialCharacters = (text) => {
    return text?.replace(/[^A-Z0-9]+/gi, "");
  };

  const onSubmit = (formData) => {
    formData.phone = removeSpecialCharacters(formData.phone)
    formData.name = `${formData.first_name} ${formData.last_name}`;
    handleParentFormData(formData, editIndex, handleFormReset);
  };

  useEffect(() => {
    if (String(editIndex) && values && typeof values === 'object') {
      Object.entries(values).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [editIndex, clientType, setValue, values]);
  


  return (
    <fieldset ref={divRef} className="kamal-test bg-gray-100 p-5">
      <div className="my-4">
        <Controller
          name="first_name"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <CustomTextField
              {...field}
              required
              label="First name *"
              type="text"
              inputRef={refFirstName}
              {...RegisterFirstName}
              error={Boolean(errors.first_name)}
              helperText={errors.first_name && errors.first_name.message}
              autoComplete="first_name"
              onKeyDown={onKeyDown}
            />
          )}
        />
      </div>
      <div className="my-4">
        <Controller
          name="last_name"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <CustomTextField
              {...field}
              required
              label="Last name *"
              type="text"
              inputRef={refLastName}
              {...RegisterLastName}
              error={Boolean(errors.last_name)}
              helperText={errors.last_name && errors.last_name.message}
              autoComplete="last_name"
              onKeyDown={onKeyDown}
            />
          )}
        />
      </div>
      <div className="my-4">
        <Controller
          name="email"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <CustomTextField
              {...field}
              required
              label="Email *"
              type="email"
              inputRef={refEmail}
              {...RegisterEmail}
              error={Boolean(errors.email)}
              helperText={errors.email && errors.email.message}
              autoComplete="email"
              onKeyDown={onKeyDown}
            />
          )}
        />
      </div>
      <div className="pt-2">
        <Controller
          control={control}
          name="phone"
          render={({ field, fieldState }) => (
            <MuiPhoneNumber
              defaultCountry={"us"}
              value={field.value}
              onChange={field.onChange}
              sx={{
                width: "100%",
                input: { color: "#264265", fontSize: "1rem" },
                "& .MuiInputLabel-root": {
                  color: "#264265B2",
                  fontSize: "1rem",
                  marginTop: "-.4rem",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#000000CC",
                },
              }}
              onBlur={field.onBlur}
              name={field.name}
              error={Boolean(fieldState.error)}
              helperText={fieldState.error && fieldState.error.message}
              label="Phone *"
              onKeyDown={onKeyDown}
            />
          )}
        />
      </div>
      <div className="my-4">
        <Controller
          name="street"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <AddressAutocompleteTextfield
              {...field}
              handleGoogleAddress={handleGoogleAddress}
              label="Street *"
              placeholder="Street"
              defaultValue={street}
              disableClearable
              error={Boolean(errors.street)}
              helperText={errors.street && errors.street.message}
              handleManualStreetAddress={handleManualStreetAddress}
              onKeyDown={onKeyDown}
            />
          )}
        />
      </div>
      <div className="my-4">
        <CustomTextField
          required
          label="City *"
          placeholder="City"
          InputLabelProps={{
            shrink: Boolean(city),
          }}
          disableClearable
          inputRef={refCity}
          {...RegisterCity}
          error={Boolean(errors.city)}
          helperText={errors.city && errors.city.message}
          autoComplete="city"
          onKeyDown={onKeyDown}
        />
      </div>
      <div className="my-4">
        <CustomTextField
          label="State *"
          placeholder="State"
          InputLabelProps={{
            shrink: Boolean(state),
          }}
          disableClearable
          inputRef={refState}
          {...RegisterState}
          error={Boolean(errors.state)}
          helperText={errors.state && errors.state.message}
          autoComplete="state"
          onKeyDown={onKeyDown}
        />
      </div>

      <div className="my-4">
        <CustomTextField
          type="number"
          label="Zip code / Postal code *"
          placeholder="Zip code / Postal code"
          disableClearable
          InputLabelProps={{
            shrink: Boolean(zip_code),
          }}
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value))
              .toString()
              .slice(0, 6);
          }}
          inputRef={refZipCode}
          {...RegisterZipCode}
          error={Boolean(errors.zip_code)}
          helperText={errors.zip_code && errors.zip_code.message}
          autoComplete="zip_code"
          onKeyDown={onKeyDown}
        />
      </div>
      {(clientType === "couple" || clientType === "family") && (
        <div className="my-4 inline">
          <div className="flex items-center space-x-4">
            <p className="my-6 text-base text-hb_blue-800">
              Make this user primary
            </p>

            <Checkbox
              onChange={(e) => {
                setValue("is_primary", e.target.checked);
              }}
              checked={is_primary}
              sx={{
                "& .MuiSvgIcon-root": { fontSize: 28 },
                "&.Mui-checked": {
                  color: "#FF6C44",
                },
              }}
              onKeyDown={onKeyDown}
            />
          </div>
        </div>
      )}

      <div className="my-2">
        <small>
          <b>Note:</b> Ensure you click the <b>Add User</b> button to save the
          user information before clicking <b>Invite Client</b>.
        </small>
      </div>
      <Button
        variant="contained"
        className="min-w-[150px]"
        sx={{ background: "#FF6C44" }}
        type="button"
        onClick={handleSubmit(onSubmit)}
      >
        Add User
      </Button>
    </fieldset>
  );
};
export default InviteClientUserForm;
