import { Card, CardContent, CardHeader } from "@mui/material";
import { ErrorBoundary } from "components";
import React from "react";

const CommonCard = (props) => {
  const {
    title,
    cardClass,
    cardContentClass,
    raised,
    children,
    headerActions,
    displayContents,
    displayTitleDividerLine,
    titleTypographyVariant,
  } = props;

  return (
    <>
      <Card className={`mb-4 ${cardClass}`} raised={raised}>
        <CardHeader
          title={title}
          action={headerActions}
          color="text"
          titleTypographyProps={{
            variant: titleTypographyVariant,
          }}
          sx={{
            "& .MuiCardHeader-title": {
              color: "#234266",
              fontSize: "0.875rem",
              fontWeight: "bold",
            },
          }}
        />
        <div
          style={{
            display: displayContents ? "" : "none",
            transition: "all 600ms ease",
          }}
        >
          <CardContent className={"p-0"}>
            <ErrorBoundary>{children}</ErrorBoundary>
          </CardContent>
        </div>
      </Card>
    </>
  );
};

export default CommonCard;

CommonCard.defaultProps = {
  title: "",
  cardClass: "",
  cardContentClass: "",
  raised: false,
  children: null,
  headerActions: null,
  displayContents: true,
  displayTitleDividerLine: true,
  titleTypographyVariant: "h6",
};
