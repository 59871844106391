import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "react-query";
import Routes from "Routes";
import PrimaryTheme from "Themes/PrimaryTheme";
import "./App.scss";
import { useEffect } from "react";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV || "development",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: true,
      blockAllMedia: false,
    }),
    Sentry.feedbackIntegration({
      showBranding: false,
      showName: false,
      showEmail: false,
      colorScheme: "light",
      triggerLabel: "Feedback",
      triggerAriaLabel: "Feedback",
      formTitle: "CareBetter Feedback",
      submitButtonLabel: "Submit",
      messagePlaceholder:
        "How can we improve CareBetter? You may also report a bug here.",
      themeLight: {
        accentBackground: "#213F61",
      },
    }),
  ],
  // Tracing
  tracesSampleRate: 0.25, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^https:\/\/api\.carebetter\.com\/api/,
    /^https:\/\/stage\.api\.carebetter\.com\/api/,
    /^https:\/\/api\.habitbetter\.com\/api/, // Tracing for both api domains for now, TODO: Remove once all envs are updated
    /^https:\/\/stage\.api\.habitbetter\.com\/api/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * 7,
        enabled: false,
        staleTime: Infinity,
        retry: 1,
      },
    },
  });

  const fullPageReload = () => {
    window.location.reload();
  };

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "token") {
        fullPageReload();
      }
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={PrimaryTheme}>
            <ToastContainer closeButton={false} />
            <Routes />
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
