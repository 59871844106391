import styled from "@emotion/styled/macro";
import { Tab, Tabs } from "@mui/material";
import { ErrorBoundary } from "components";
import React, { useMemo, useState } from "react";

function ToggleSliderTabs(props) {
  const { options, value: selectedItem, setSelectedItem, className } = props;

  const isControlled = useMemo(
    () => Boolean(setSelectedItem),
    [setSelectedItem]
  );
  const [selectedTab, setSelectedTab] = useState(options[0]);

  const handleChangeTab = (event, value) => {
    if (isControlled) {
      setSelectedItem(value);
    } else {
      setSelectedTab(value);
    }
  };
  return (
    <ErrorBoundary>
      <StyledTabs
        centered
        selectionFollowsFocus
        textColor="primary"
        variant="fullWidth"
        TabIndicatorProps={{
          style: {
            height: "0px",
            display: "none",
          },
        }}
        // aria-label="Solutions-Grid-Tabs"
        value={isControlled ? selectedItem : selectedTab}
        onChange={handleChangeTab}
        className={className}
      >
        {options.map((solutionsItem, solutionIndex) => (
          <StyledTab
            disableFocusRipple
            disableRipple
            label={solutionsItem}
            value={solutionsItem}
            key={solutionsItem}
          />
        ))}
      </StyledTabs>
    </ErrorBoundary>
  );
}

export default ToggleSliderTabs;

ToggleSliderTabs.defaultProps = {
  options: ["option1", "option2", "option3"],
  selectedItem: "option1",
  className: "",
};

const StyledTabs = styled(Tabs)`
  border-radius: 40px;
  background: #234266;
  padding: 4px;
  border: none;
  display: flex;
  transition: transform 0.5s cubic-bezier(0.15, 0.88, 0.15, 0.97);
  min-height: auto;

  &.MuiTabs-indicator {
    display: none;
    border: none;
  }

  width: 100% !important;
`;

const StyledTab = styled(Tab)`
  max-height: 32px;
  min-height: 32px;
  border-radius: 40px;
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem;
  background: #234266;
  padding: auto;
  border: none;
  display: flex;
  transition: transform 0.5s cubic-bezier(0.15, 0.88, 0.15, 0.97);
  text-transform: capitalize;
  min-width: unset;
  font-weight: normal;

  &.Mui-selected {
    color: #234266;
    background-color: white;
    transition: transform 0.5s cubic-bezier(0.15, 0.88, 0.15, 0.97);
    &:hover {
      // color: ;
    }
  }

  &:hover {
    // color: red;
  }
  // remove this after applying theme
  font-size: 14px;
`;
