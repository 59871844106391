import { useEffect } from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  AddServicesQuery,
  FetchCoachServicesQuery,
  FetchInsuranceCodeQuery,
  UpdateServiceQuery,
} from "../ProfileQueries";
import { Button, Grid, IconButton } from "@mui/material";
import CommonDialog from "components/CommonDialog";
import { CustomSelectField, CustomTextField } from "components";
import { AddCircleOutline, Cancel as CancelIcon } from "@mui/icons-material";

const defaultValues = {
  services: [
    {
      name: "",
      currency: "",
      rate: "",
      insurance_id: "",
    },
  ],
};

const lateCancellationOptions = [
  { label: "Custom", value: "custom" },
  { label: "Percentage", value: "percentage" },
];

const currencyData = [
  {
    id: 1,
    label: "USD",
    value: "USD",
    symbol: " $",
  },
  {
    id: 2,
    label: "EUR",
    value: "EUR",
    symbol: "€",
  },
  {
    id: 3,
    label: "GBP",
    value: "GBP",
    symbol: "£",
  },
  {
    id: 4,
    label: "AUD",
    value: "AUD",
    symbol: "A$",
  },
  {
    id: 5,
    label: "CAD",
    value: "CAD",
    symbol: "C$",
  },
];

//yup schema
const serviceSchema = {
  name: yup
    .string()
    .required("Service name is required!")
    .min(3, "Service name must be at least 3 characters"),
  rate: yup
    .number()
    .nullable()
    .required("Rate is required!")
    .typeError("Rate is required!"),
  currency: yup.object().required("Currency is required!").nullable(),
  insurance_id: yup.object().required("Insurance is required!").nullable(),
  late_cancel_deduction_type: yup
    .object()
    .required("Late Cancel Fee is required!"),
  late_cancel_deduction_value: yup
    .number()
    .required("Amount is required!")
    .typeError("Amount is required!")
    .test("is-valid-deduction", function (value) {
      const { rate, late_cancel_deduction_type } = this.parent;

      if (!rate || !late_cancel_deduction_type) return true;

      const type = late_cancel_deduction_type.value;

      if (type === "custom" && value > rate) {
        return this.createError({
          message: "Amount should not be greater than rate",
        });
      }

      if (type === "percentage" && value > 100) {
        return this.createError({
          message: "Percentage Can't be greater that 100",
        });
      }

      return true;
    }),
};
const servicesSetSchema = yup.object().shape({
  services: yup
    .array()
    .of(yup.object().shape(serviceSchema))
    .required("Must have fields"),
});

const AddEditServiceModal = (props) => {
  const { open, handleClose, servicesData, isEdit, insuranceCodeList } = props;

  // react-hook-form
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
  } = useForm({
    resolver: yupResolver(servicesSetSchema),
    defaultValues,
  });

  const {
    fields: servicesSetFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "services",
  });

  // queries
  const { mutate: AddServices } = AddServicesQuery();
  const { mutate: UpdateService } = UpdateServiceQuery();
  // handlers
  const handleSetValue = (key, val) => {
    setValue(key, val, { shouldValidate: true });
  };
  const handleUpdateServices = (setIdx, field, val) => {
    servicesSetFields[setIdx][field] = val;
    handleSetValue("services", servicesSetFields);
  };
  const handleAddEditServiceSubmit = (formData) => {
    const payload = {
      services: formData?.services?.map((item) => {
        return {
          name: item.name,
          currency: item.currency.value,
          rate: item.rate,
          insurance_id: item?.insurance_id?.id,
          late_cancel_deduction_type: item?.late_cancel_deduction_type.value,
          late_cancel_deduction_value: item?.late_cancel_deduction_value,
        };
      }),
    };
    if (isEdit && payload.services[0]) {
      const formatPayload = { ...payload.services[0], id: servicesData.id };
      UpdateService(
        { services: [formatPayload] },
        {
          onSuccess: () => {
            handleClose();
            reset();
          },
        }
      );
    } else {
      AddServices(payload, {
        onSuccess: () => {
          handleClose();
          reset();
        },
      });
    }
  };

  //Submit and Cancel buttons
  const DialogActions = (props) => {
    const { handleClose } = props;
    return (
      <>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit" form="add-edit-service">
          Save
        </Button>
      </>
    );
  };

  const services = useWatch({
    control,
    name: "services",
  });

  // usEffects
  useEffect(() => {
    if (servicesData) {
      const {
        name,
        hourly_rate,
        currency,
        insurance,
        late_cancel_deduction_type,
        late_cancel_deduction_value,
      } = servicesData;

      handleUpdateServices(0, "name", name);
      handleUpdateServices(0, "rate", hourly_rate);
      currencyData?.forEach((item) => {
        if (item.value === currency) {
          handleUpdateServices(0, "currency", item);
        }
      });

      handleUpdateServices(
        0,
        "late_cancel_deduction_type",
        lateCancellationOptions?.filter(
          (item) => item?.value === late_cancel_deduction_type
        )[0]
      );
      handleUpdateServices(
        0,
        "late_cancel_deduction_value",
        late_cancel_deduction_value
      );
      handleUpdateServices(0, "insurance_id", insurance);
    }
  }, [isEdit, servicesData]);

  return (
    <CommonDialog
      open={open}
      onClose={handleClose}
      title={isEdit ? "EDIT SERVICE" : "ADD SERVICE"}
      actions={DialogActions({ handleClose })}
      maxWidth="md"
      aria-labelledby={isEdit ? "edit-service-dialog" : "add-service-dialog"}
      aria-describedby={
        isEdit
          ? "edit-service-dialog-description"
          : "add-services-dialog-description"
      }
    >
      <form
        id="add-edit-service"
        onSubmit={handleSubmit(handleAddEditServiceSubmit)}
      >
        {servicesSetFields?.map((field, index) => {
          return (
            <Grid key={field.id} container className="flex items-center">
              <Grid item className="ml-auto">
                {servicesSetFields.length > 1 && (
                  <IconButton
                    className="relative top-2"
                    onClick={() => remove(index)}
                  >
                    <CancelIcon className="text-hb_blue-800" />
                  </IconButton>
                )}
              </Grid>
              <Grid container className="flex justify-between">
                <Grid item xs={6} className="pr-4 md:pr-12">
                  <CustomTextField
                    required
                    label="Service Name *"
                    value={field.name}
                    onChange={(e) =>
                      handleUpdateServices(index, "name", e.target.value)
                    }
                    error={Boolean(
                      Array.isArray(errors.services) &&
                        errors.services[index]?.name
                    )}
                    helperText={
                      Array.isArray(errors.services) &&
                      errors.services[index]?.name &&
                      errors.services[index]?.name.message
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={5}
                  className="w-full pr-6"
                  style={{ marginBottom: "-2px" }}
                >
                  <CustomSelectField
                    variant="standard"
                    options={currencyData || []}
                    value={field.currency}
                    onChange={(event) => {
                      handleUpdateServices(
                        index,
                        "currency",
                        event.target.value
                      );
                    }}
                    fullWidth={true}
                    label="Currency *"
                    error={Boolean(
                      Array.isArray(errors.services) &&
                        errors.services[index]?.currency
                    )}
                    helperText={
                      Array.isArray(errors.services) &&
                      errors.services[index]?.currency &&
                      errors.services[index]?.currency.message
                    }
                  />
                </Grid>
              </Grid>
              <Grid container className="flex justify-between">
                <Grid item xs={6} className="flex items-center space-x-4">
                  <div className="flex items-center">
                    <CustomTextField
                      required
                      type="number"
                      label="Rate *"
                      value={field.rate}
                      onChange={(e) =>
                        handleUpdateServices(index, "rate", e.target.value)
                      }
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 6);
                      }}
                      error={Boolean(
                        Array.isArray(errors.services) &&
                          errors.services[index]?.rate
                      )}
                      helperText={
                        Array.isArray(errors.services) &&
                        errors.services[index]?.rate &&
                        errors.services[index]?.rate.message
                      }
                    />

                    <div className="relative -bottom-4 ml-4 flex">
                      <span
                        className="relative -top-1"
                        style={{
                          transform: "rotate(25deg)",
                          width: 1,
                          height: 28,
                          background: "#707070",
                        }}
                      ></span>
                      <p className="ml-3 text-sm text-hb_gray-700">Hour</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={5} className="my-2 flex items-center space-x-4">
                  <CustomSelectField
                    variant="standard"
                    options={
                      insuranceCodeList?.map((item, index) => {
                        return {
                          id: item.id,
                          label: `${index === 0 ? "" : item.code} ${item.name}`,
                          value: `${index === 0 ? "" : item.code} ${item.name}`,
                        };
                      }) || []
                    }
                    fullWidth={true}
                    label="Insurance *"
                    value={field.insurance_id}
                    onChange={(e) =>
                      handleUpdateServices(
                        index,
                        "insurance_id",
                        e.target.value
                      )
                    }
                    error={Boolean(
                      Array.isArray(errors.services) &&
                        errors.services[index]?.insurance_id
                    )}
                    helperText={
                      Array.isArray(errors.services) &&
                      errors.services[index]?.insurance_id &&
                      errors.services[index]?.insurance_id?.message
                    }
                  />
                </Grid>
                <Grid container className="flex justify-between">
                  <Grid
                    item
                    xs={4}
                    className="my-2 flex items-center space-x-4"
                  >
                    <CustomSelectField
                      variant="standard"
                      options={lateCancellationOptions}
                      fullWidth={true}
                      label="Set Late Cancel Fee *"
                      value={field?.late_cancel_deduction_type}
                      onChange={(e) =>
                        handleUpdateServices(
                          index,
                          "late_cancel_deduction_type",
                          e.target.value
                        )
                      }
                      error={Boolean(
                        Array.isArray(errors.services) &&
                          errors.services[index]?.late_cancel_deduction_type
                      )}
                      helperText={
                        Array.isArray(errors.services) &&
                        errors.services[index]?.late_cancel_deduction_type &&
                        errors.services[index]?.late_cancel_deduction_type
                          ?.message
                      }
                    />
                  </Grid>

                  <Grid item xs={5} className="flex items-center space-x-4">
                    <div className="flex items-center">
                      <CustomTextField
                        sx={{ minWidth: "200px" }}
                        required
                        className="mt-1"
                        type="number"
                        label={
                          services[index]?.late_cancel_deduction_type?.value ===
                          "percentage"
                            ? "Enter Percentage *"
                            : "Enter Amount *"
                        }
                        value={field?.late_cancel_deduction_value}
                        onChange={(e) =>
                          handleUpdateServices(
                            index,
                            "late_cancel_deduction_value",
                            e.target.value
                          )
                        }
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 6);
                        }}
                        error={Boolean(
                          Array.isArray(errors.services) &&
                            errors.services[index]?.late_cancel_deduction_value
                        )}
                        helperText={
                          Array.isArray(errors.services) &&
                          errors.services[index]?.late_cancel_deduction_value &&
                          errors.services[index]?.late_cancel_deduction_value
                            .message
                        }
                      />

                      <div className="relative -bottom-4 ml-4 flex">
                        <span
                          className="relative -top-1"
                          style={{
                            transform: "rotate(25deg)",
                            width: 1,
                            height: 28,
                            background: "#707070",
                          }}
                        ></span>
                        <p className="ml-3 text-sm text-hb_gray-700">
                          {services[index]?.late_cancel_deduction_type
                            ?.value === "percentage"
                            ? "%"
                            : services[index]?.currency?.symbol || "$"}
                        </p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        })}

        {!isEdit && (
          <div className="relative -left-2 my-6">
            <Button
              endIcon={<AddCircleOutline />}
              className="text-hb_blue-800"
              onClick={() =>
                append({ name: "", currency: "", rate: "", insurance_id: "" })
              }
            >
              ADD MORE
            </Button>
          </div>
        )}
      </form>
    </CommonDialog>
  );
};

export default AddEditServiceModal;
