import { useAPI, useToast } from "services";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as Sentry from "@sentry/react";

const FetchUserDetails = () => {
  const method = "GET";
  const url = `api/coach/me`;
  return useAPI({ method, url });
};

const UploadUserAvatar = (body) => {
  const method = "POST";
  const url = `api/coach/upload-avatar`;
  const formData = new FormData();
  formData.append("avatar", body?.file);
  return useAPI({
    method,
    url,
    body: formData,
    contentType: "multipart/form-data",
  });
};
const FetchProfileDetails = () => {
  const method = "GET";
  const url = `api/coach/profile`;
  return useAPI({ method, url });
};

const FetchSpecialtyData = () => {
  const method = "GET";
  const url = `api/coach/specialities`;
  return useAPI({ method, url });
};
export const FetchSpecialtyDataQuery = () => {
  return useQuery("GET_SPECIALTY_DATA", FetchSpecialtyData, {
    enabled: true,
  });
};

const UpdateWorkingTime = (body) => {
  const method = "PUT";
  const url = `api/coach/work/timings`;
  return useAPI({ method, url, body });
};
export const UpdateWorkingTimeQuery = (payload) => {
  const responseToast = useToast();
  return useMutation(UpdateWorkingTime, {
    onSuccess: (response) => {
      responseToast.toastSuccess(" Add Working Time successfully!");
    },
    onError: ErrorHandler,
  });
};
const UpdateProfile = (body) => {
  const method = "PUT";
  const url = `api/coach/profile`;
  return useAPI({ method, url, body });
};
const FetchCoachSubscriptionPlans = () => {
  const method = "GET";
  const url = `api/coach/subscription/plans`;
  return useAPI({ method, url });
};
const FetchSavedCards = () => {
  const method = "GET";
  const url = `api/coach/cards`;
  return useAPI({ method, url });
};
const AddNewCard = (body) => {
  const method = "POST";
  const url = `api/coach/cards`;
  return useAPI({ method, url, body });
};
const CreateSubscription = (body) => {
  const method = "POST";
  const url = `api/coach/subscription/create`;
  return useAPI({ method, url, body });
};

const DeleteSubscription = (body) => {
  const method = "DELETE";
  const url = `api/coach/subscription`;
  return useAPI({ method, url, body });
};

const ResumeSubscription = (body) => {
  const method = "POST";
  const url = `api/coach/subscription/resume`;
  return useAPI({ method, url, body });
};

const UpdateBillingLogo = (body) => {
  const method = "POST";
  const url = `api/coach/billing-logo`;
  let formData = new FormData();
  formData.append("image", body?.file);
  return useAPI({
    method,
    url,
    body: formData,
    contentType: "multipart/form-data",
    headers: {
      Accept: "*/*",
    },
  });
};
const UpdateSubscription = (body) => {
  const method = "PUT";
  const url = `api/coach/subscription/update`;
  return useAPI({ method, url, body });
};

const CreateBankAccount = (body) => {
  const method = "POST";
  const url = `api/coach/bank`;
  return useAPI({ method, url, body });
};

export const CreateBankAccountQuery = (payload) => {
  const queryClient = useQueryClient();
  return useMutation(CreateBankAccount, {
    onSuccess: (response) => {
      // responseToast.toastSuccess(" Generate invoice successfully!");
      queryClient.invalidateQueries("GET_PROFILE_DETAILS");
      window.open(response.data, "_self");
    },
    onError: ErrorHandler,
  });
};

const UpdateBillings = (body) => {
  const method = "PUT";
  const url = `api/coach/billing-info`;
  return useAPI({ method, url, body });
};

export const UpdateBillingsQuery = (payload) => {
  const queryClient = useQueryClient();
  const responseToast = useToast();
  return useMutation(UpdateBillings, {
    onSuccess: (response) => {
      responseToast.toastSuccess(response.message);
      queryClient.invalidateQueries("GET_PROFILE_DETAILS");
    },
    onError: ErrorHandler,
  });
};

const UpdatePreferences = (body) => {
  const method = "PUT";
  const url = `api/coach/settings`;
  return useAPI({ method, url, body });
};
export const UpdatePreferencesQuery = (payload) => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  return useMutation(UpdatePreferences, {
    onSuccess: () => {
      responseToast.toastSuccess("Status has been updated successfully");
      queryClient.invalidateQueries("GET_NOTIFICATION_SETTING");
    },

    onError: ErrorHandler,
  });
};
const UpdateBankAccount = (body) => {
  const method = "PUT";
  const url = `api/coach/bank`;
  return useAPI({ method, url, body });
};
export const UpdateBankAccountQuery = () => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  return useMutation(UpdateBankAccount, {
    onSuccess: (response) => {
      responseToast.toastSuccess(response.message);
      queryClient.invalidateQueries("GET_PROFILE_DETAILS");
    },

    onError: ErrorHandler,
  });
};

const FetchCurrentSubscriptionPlan = () => {
  const method = "GET";
  const url = `api/coach/subscription/plans/selected`;
  return useAPI({ method, url });
};

const FetchNotificationSetting = () => {
  const method = "GET";
  const url = `api/coach/settings`;
  return useAPI({ method, url });
};

export const FetchNotificationSettingQuery = () => {
  return useQuery("GET_NOTIFICATION_SETTING", FetchNotificationSetting, {
    enabled: true,
  });
};

const FetchClientRequests = () => {
  const method = "GET";
  const url = `api/coach/users/invite`;
  return useAPI({ method, url });
};

const AcceptClientRequest = (body) => {
  const method = "POST";
  const url = `api/coach/accept/user/invite`;
  return useAPI({ method, url, body });
};

const DenyClientRequest = (body) => {
  const method = "DELETE";
  const url = `api/coach/user/invite/${body.invite_id}`;
  return useAPI({ method, url });
};
const AddServices = (body) => {
  const method = "POST";
  const url = `api/coach/services`;
  return useAPI({ method, url, body });
};
const UpdateService = (body) => {
  const method = "PUT";
  const url = `api/coach/services`;
  return useAPI({ method, url, body });
};
const FetchServiceByID = (id) => {
  const method = "GET";
  const url = `api/coach/services/${id}`;
  return useAPI({
    method,
    url,
  });
};
export const FetchServiceByIDQuery = (id) => {
  return useQuery([`SERVICE_DATA_${id}`, id], () => FetchServiceByID(id), {
    onSuccess: () => {},
    onError: ErrorHandler,
  });
};

const DeleteService = (body) => {
  const method = "DELETE";
  const url = `api/coach/services/${body.id}`;
  return useAPI({ method, url });
};
const FetchCoachServices = (body) => {
  const method = "GET";
  const url = `api/coach/services/all`;
  return useAPI({ method, url });
};

const FetchInsuranceCode = () => {
  const method = "GET";
  const url = `api/coach/insurance`;
  return useAPI({ method, url });
};
export const FetchInsuranceCodeQuery = (payload) => {
  return useQuery("INSURANCE_CODE", () => FetchInsuranceCode(payload), {
    enabled: true,
  });
};
export const AddServicesQuery = (payload) => {
  const queryClient = useQueryClient();
  const responseToast = useToast();
  return useMutation(AddServices, {
    onSuccess: () => {
      queryClient.invalidateQueries("FETCH_COACH_SERVICES");
      queryClient.invalidateQueries("FETCH_COACH_SERVICES_PROFILE");
      responseToast.toastSuccess(" Services added successfully!");
    },
    onError: ErrorHandler,
  });
};
export const UpdateServiceQuery = (payload) => {
  const queryClient = useQueryClient();
  const responseToast = useToast();
  return useMutation(UpdateService, {
    onSuccess: () => {
      queryClient.invalidateQueries("FETCH_COACH_SERVICES_PROFILE");
      queryClient.invalidateQueries("FETCH_COACH_SERVICES");
      responseToast.toastSuccess(" Services updated successfully!");
    },
    onError: ErrorHandler,
  });
};
export const DeleteServiceQuery = (payload) => {
  const queryClient = useQueryClient();
  const responseToast = useToast();
  return useMutation(DeleteService, {
    onSuccess: () => {
      queryClient.invalidateQueries("FETCH_COACH_SERVICES_PROFILE");
      queryClient.invalidateQueries("FETCH_COACH_SERVICES");
      responseToast.toastSuccess(" Services deleted successfully!");
    },
    onError: ErrorHandler,
  });
};
export const FetchUserDetailsQuery = () => {
  return useQuery("GET_LOGGED_IN_USER_DETAILS", FetchUserDetails, {
    enabled: true,
  });
};

export const UploadUserAvatarQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(UploadUserAvatar, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("GET_LOGGED_IN_USER_DETAILS");
      queryClient.invalidateQueries("GET_PROFILE_DETAILS");
    },
    onError: ErrorHandler,
  });
};

export const FetchProfileDetailsQuery = () => {
  return useQuery("GET_PROFILE_DETAILS", FetchProfileDetails, {
    enabled: true,
  });
};

export const UpdateProfileQuery = (payload) => {
  const queryClient = useQueryClient();
  const responseToast = useToast();
  return useMutation(UpdateProfile, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("GET_PROFILE_DETAILS");
      responseToast.toastSuccess("Profile Updated successfully!");
    },
    onError: ErrorHandler,
  });
};

export const FetchCoachSubscriptionPlansQuery = () => {
  return useQuery("GET_COACH_SUBSCRIPTION_PLANS", FetchCoachSubscriptionPlans, {
    enabled: true,
  });
};
export const FetchSavedCardsQuery = () => {
  return useQuery("GET_SAVED_CARDS", FetchSavedCards, {
    enabled: true,
  });
};
export const AddNewCardQuery = (payload) => {
  return useMutation(AddNewCard, {
    onError: ErrorHandler,
  });
};
const CheckCouponCode = (body) => {
  const method = "POST";
  const url = `api/coach/subscription/coupon`;
  return useAPI({ method, url, body });
};
export const CheckCouponCodeQuery = () => {
  const responseToast = useToast();
  return useMutation(CheckCouponCode, {
    onSuccess: (res) => {
      // queryClient.invalidateQueries("COUPON_LIST_TABLE");
      responseToast.toastSuccess(res?.message);
    },
    onError: ErrorHandler,
  });
};

export const CreateSubscriptionQuery = (payload) => {
  const queryClient = useQueryClient();
  return useMutation(CreateSubscription, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("GET_COACH_SUBSCRIPTION_PLANS");
      queryClient.invalidateQueries("GET_PROFILE_DETAILS");
      queryClient.invalidateQueries("GET_CURRENT_SUBSCRIPTION_PLAN");
    },
    onError: ErrorHandler,
  });
};

export const ResumeSubscriptionQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(ResumeSubscription, {
    onSuccess: () => {
      queryClient.invalidateQueries("GET_COACH_SUBSCRIPTION_PLANS");
      queryClient.invalidateQueries("GET_PROFILE_DETAILS");
      queryClient.invalidateQueries("GET_CURRENT_SUBSCRIPTION_PLAN");
    },
  });
};

export const CancelSubscriptionQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(DeleteSubscription, {
    onSuccess: () => {
      queryClient.invalidateQueries("GET_COACH_SUBSCRIPTION_PLANS");
      queryClient.invalidateQueries("GET_PROFILE_DETAILS");
      queryClient.invalidateQueries("GET_CURRENT_SUBSCRIPTION_PLAN");
    },
  });
};

const FetchCurrentAccountStatusPlan = () => {
  const method = "GET";
  const url = `api/coach/bank/link`;
  return useAPI({ method, url });
};
export const FetchCurrentAccountStatusPlanQuery = (payload) => {
  return useQuery("GET_CURRENT_ACCOUNT_STATUS", FetchCurrentAccountStatusPlan, {
    enabled: Boolean(payload),
  });
};
export const UpdateBillingLogoQuery = () => {
  return useMutation(UpdateBillingLogo, {
    onSuccess: () => {
      // queryClient.invalidateQueries("GET_PROFILE_DETAILS");
    },

    onError: ErrorHandler,
  });
};

export const UpdateSubscriptionQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(UpdateSubscription, {
    onSuccess: () => {
      queryClient.invalidateQueries("GET_CURRENT_SUBSCRIPTION_PLAN");
    },
    onError: ErrorHandler,
  });
};
export const FetchCurrentSubscriptionPlanQuery = () => {
  return useQuery(
    "GET_CURRENT_SUBSCRIPTION_PLAN",
    FetchCurrentSubscriptionPlan,
    {
      enabled: true,
    }
  );
};
export const FetchClientRequestsQuery = () => {
  return useQuery("GET_CLIENT_REQUESTS", FetchClientRequests, {
    enabled: true,
  });
};
export const AcceptClientRequestQuery = (payload) => {
  const queryClient = useQueryClient();
  return useMutation(AcceptClientRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries("GET_CLIENT_REQUESTS");
      queryClient.invalidateQueries(["CLIENTS_LIST"]);
    },
    onError: ErrorHandler,
  });
};
export const DenyClientRequestQuery = (payload) => {
  const queryClient = useQueryClient();
  return useMutation(DenyClientRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries("GET_CLIENT_REQUESTS");
    },
    onError: ErrorHandler,
  });
};
export const FetchCoachServicesQuery = (payload) => {
  return useQuery("FETCH_COACH_SERVICES", () => FetchCoachServices(payload), {
    enabled: true,
  });
};

const FetchCoachWorkTimings = () => {
  return useAPI({
    method: "GET",
    url: "api/coach/work-timings",
  });
};
const FetchCoachServicesProfile = (body) => {
  const method = "GET";
  const url = `api/coach/services/list`;
  return useAPI({ method, url });
};
export const FetchCoachServicesProfileQuery = (payload) => {
  return useQuery(
    "FETCH_COACH_SERVICES_PROFILE",
    () => FetchCoachServicesProfile(payload),
    {
      enabled: true,
    }
  );
};

export const FetchCoachWorkTimingsQuery = () => {
  return useQuery("FETCH_COACH_WORK_TIMINGS", FetchCoachWorkTimings, {
    enabled: true,
  });
};

const UpdateCoachWorkTimings = (body) => {
  const method = "PUT";
  const url = `api/coach/work-timings`;
  return useAPI({ method, url, body });
};

export const UpdateCoachWorkTimingsQuery = (payload) => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  return useMutation(UpdateCoachWorkTimings, {
    onSuccess: (response) => {
      responseToast.toastSuccess("Updated Availability Time successfully!");
      queryClient.invalidateQueries(["FETCH_COACH_WORK_TIMINGS"]);
    },
    onError: ErrorHandler,
  });
};

const ErrorHandler = (error) => {
  const responseToast = useToast();
  Sentry.captureException(error);
  if (error?.errors) {
    responseToast.toastError("Something went wrong!", error.errors);
  } else if (error?.message) {
    responseToast.toastError(error.message);
  }
};

const FetchTimezones = () => {
  const method = "GET";
  const url = `api/coach/timezones`;
  return useAPI({ method, url });
};

export const FetchTimezonesQuery = () => {
  return useQuery("GET_TIMEZONES", FetchTimezones, {
    enabled: true,
  });
};
