import React, { useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import RouteConstants from "Routes/RouteConstants";
import Styles from "./Header.module.scss";
import {
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Badge,
  Button,
  Divider,
  Box,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import {
  ManageAccounts as ManageAccountsIcon,
  Logout as LogoutIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@mui/icons-material";
import CareBetterLogo from "assets/Images/CareBetterLogo.png";
import {
  FetchClientRequestsQuery,
  FetchUserDetailsQuery,
  AcceptClientRequestQuery,
  DenyClientRequestQuery,
} from "Pages/Profile/ProfileQueries";
import {
  PersonAdd as PersonAddIcon,
  Notifications as NotificationsIcon,
} from "@mui/icons-material";
import ChatBoxModal from "Pages/Clients/components/ChatBox";
import CommonDialog from "components/CommonDialog";
import { ClientContextProvider } from "utils/Context/ClientContext";
import {
  GetNotificationsQuery,
  LogOutUserQuery,
  ReadNotificationQuery,
} from "Pages/LoginPage/LoginQueries";
import { useEffect } from "react";
import { useToast } from "services";
import FreeSessionDialog from "./FreeSessionDialog";
import { setLocalStorageItem } from "utils/CustomHooks/useLocalStorage";

const Header = () => {
  const history = useHistory();
  const responseToast = useToast();
  const { mutate: logOut } = LogOutUserQuery();

  //states
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
  const [requestsAnchorEl, setRequestsAnchorEl] = useState(null);
  const isProfileMenuOpen = Boolean(profileAnchorEl);
  const isNotificationsMenuOpen = Boolean(notificationsAnchorEl);
  const isRequestsMenuOpen = Boolean(requestsAnchorEl);
  const [showBadge, setShowBadge] = useState(false);
  const [openChatModal, setOpenChatModal] = useState({
    open: false,
    uuid: "",
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentInvite, setCurrentInvite] = useState({
    invite_id: null,
    user_id: null,
  });

  //queries
  const { data: loggedInUserDetails } = FetchUserDetailsQuery();
  const { data: clientRequests } = FetchClientRequestsQuery();
  const { mutate: AcceptClientRequest } = AcceptClientRequestQuery();
  const { mutate: DenyClientRequest } = DenyClientRequestQuery();
  const { data: notificationsData } = GetNotificationsQuery();
  const { mutateAsync: readNotification } = ReadNotificationQuery();

  //handlers
  const saveClientToLocalStorage = (clientId) => {
    localStorage.setItem("selectedClientId", clientId);
  };

  const handleProfileMenuClick = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
    setProfileAnchorEl(null);
  };
  const handleNotificationsMenuClick = (event) => {
    setNotificationsAnchorEl(event.currentTarget);
  };

  const handleCloseNotificationMenu = (data) => {
    readNotification({ id: data?.id }).then((res) => {
      history.push(`/${data?.data?.screen}`);
      saveClientToLocalStorage(data?.data?.client_id);
    });
    setNotificationsAnchorEl(null);
  };
  const handleCloseNotificationMenuReadAll = () => {
    readNotification().then((res) => {
      responseToast?.toastSuccess(res?.message);
    });
    setNotificationsAnchorEl(null);
  };
  const handleCloseNotificationModal = (data) => {
    setNotificationsAnchorEl(null);
  };

  const handleRequestsMenuClick = (event) => {
    setRequestsAnchorEl(event.currentTarget);
  };
  const handleRequestsMenuClose = () => {
    setRequestsAnchorEl(null);
  };

  const handleDenyRequest = (invite_id, user_id) => {
    DenyClientRequest({
      user_id,
      invite_id,
    });
  };
  const handleCloseChatModal = () => {
    setOpenChatModal({ open: false, uuid: "" });
  };

  useEffect(() => {
    if (
      Array.isArray(notificationsData?.data?.data) &&
      notificationsData.data?.data?.length > 0
    ) {
      setShowBadge(true);
    } else {
      setShowBadge(false);
    }
  }, [notificationsData?.data?.data]);

  const handleOpenDialog = (invite_id, user_id, uuid, email) => {
    setCurrentInvite({ invite_id, user_id, uuid, email });
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleSubmitDialog = (invite_id, user_id, freeSessionEnabled) => {
    AcceptClientRequest(
      {
        invite_id,
        user_id,
        free: freeSessionEnabled,
      },
      {
        onSuccess: (response) => {
          handleCloseDialog();
          // setOpenChatModal({
          //   open: true,
          //   uuid: currentInvite.uuid,
          //   email: currentInvite.email
          // });
        },
        onError: (error) => {
          //  console.error("Failed to accept client request:", error);
        },
      }
    );
  };

  useEffect(() => {
    if (loggedInUserDetails?.data?.timezone) {
      setLocalStorageItem("timezone", loggedInUserDetails?.data?.timezone);
    }
  }, [loggedInUserDetails]);

  return (
    <>
      <header
        className="flex justify-between bg-white px-8 "
        style={{
          boxShadow: "0px 4px 12px #455E7C0D",
          position: "sticky",
          top: 0,
          zIndex: 100,
          marginBottom: 19,
          height: 80,
        }}
      >
        {/* <h1 className="flex  items-center	text-hb_blue-500 font-bold text-lg">
        LOGO
      </h1> */}
        <div
          className="flex cursor-pointer items-center"
          onClick={() => history.push(RouteConstants.PRACTICE)}
        >
          <img
            className="h-auto w-12"
            src={CareBetterLogo}
            alt="CareBetter Logo"
          />
        </div>
        <nav className={Styles.navLinks}>
          <div>
            <NavLink
              activeStyle={{
                color: "#264265",
                textDecoration: "underline",
                font: "normal normal 500 16px/25px Poppins",
              }}
              style={{
                color: "#264265B3",
                font: "normal normal 400 16px/25px Poppins",
              }}
              to={RouteConstants.PRACTICE}
            >
              My Practice
            </NavLink>
          </div>
          <div>
            <NavLink
              activeStyle={{
                color: "#264265",
                textDecoration: "underline",
                font: "normal normal 500 16px/25px Poppins",
              }}
              style={{
                color: "#264265B3",
                font: "normal normal 400 16px/25px Poppins",
              }}
              to={RouteConstants.CLIENTS}
            >
              My Clients
            </NavLink>
          </div>
          <div>
            <NavLink
              activeStyle={{
                color: "#264265",
                textDecoration: "underline",
                font: "normal normal 500 16px/25px Poppins",
              }}
              style={{
                color: "#264265B3",
                font: "normal normal 400 16px/25px Poppins",
              }}
              to={RouteConstants.COURSES}
            >
              My Courses
            </NavLink>
          </div>
          <div>
            <NavLink
              activeStyle={{
                color: "#264265",
                textDecoration: "underline",
                font: "normal normal 500 16px/25px Poppins",
              }}
              style={{
                color: "#264265B3",
                font: "normal normal 400 16px/25px Poppins",
              }}
              to={RouteConstants.HABITS}
            >
              My Habits
            </NavLink>
          </div>

          <div>
            <NavLink
              activeStyle={{
                color: "#264265",
                textDecoration: "underline",
                font: "normal normal 500 16px/25px Poppins",
              }}
              style={{
                color: "#264265B3",
                font: "normals normal 400 16px/25px Poppins",
              }}
              to={RouteConstants.CHECKINS}
            >
              My Check-Ins
            </NavLink>
          </div>
        </nav>
        <div className="flex items-center space-x-2">
          <IconButton
            onClick={handleRequestsMenuClick}
            className="text-hb_blue-800"
          >
            <Badge badgeContent={clientRequests?.data?.length} color="orange">
              <PersonAddIcon />
            </Badge>
          </IconButton>
          <Menu
            anchorEl={requestsAnchorEl}
            open={isRequestsMenuOpen}
            onClose={handleRequestsMenuClose}
            sx={{
              "& .MuiPaper-root": {
                borderRadius: "10px",
                paddingBottom: "1rem",
              },
              "& .MuiList-root": {
                height: "100%",
              },
            }}
          >
            {clientRequests?.data &&
            Array.isArray(clientRequests?.data) &&
            clientRequests?.data?.length > 0 ? (
              <>
                <div className="p-2">
                  <h1 className="font-semibold text-hb_blue-800">
                    CLIENT REQUESTS{" "}
                  </h1>
                </div>
                <Divider />
                {clientRequests?.data?.map((item) => (
                  <MenuItem
                    className="flex flex-col items-start space-y-2"
                    divider
                  >
                    <div className="flex items-center space-x-4">
                      <Avatar
                        sx={{ width: 56, height: 56 }}
                        src={item?.avatar || ""}
                      />
                      <div className="flex flex-col">
                        <h2 className="text-base">{item?.name}</h2>
                        <h2 className="text-hb_gray-700">{item?.email}</h2>
                      </div>
                    </div>
                    <div
                      className="flex items-center justify-between space-x-4"
                      style={{ width: "100%" }}
                    >
                      <Button
                        variant="contained"
                        fullWidth
                        className="rounded py-1"
                        onClick={() =>
                          handleOpenDialog(
                            item.invite_id,
                            item.user_id,
                            item.uuid,
                            item.email
                          )
                        }
                      >
                        Accept
                      </Button>
                      <Button
                        variant="outlined"
                        fullWidth
                        className="rounded py-1"
                        onClick={() =>
                          handleDenyRequest(item.invite_id, item.user_id)
                        }
                      >
                        Deny
                      </Button>
                    </div>
                  </MenuItem>
                ))}
              </>
            ) : (
              <div className="my-auto flex items-center justify-center">
                <p className="p-2 text-hb_gray-900">No new client requests!</p>
              </div>
            )}
          </Menu>

          <div className="flex items-center space-x-2">
            <IconButton
              id="fade-button"
              aria-controls={isNotificationsMenuOpen ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={isNotificationsMenuOpen ? "true" : undefined}
              style={{ color: "black" }}
            >
              <Box
                sx={{ color: "action.active" }}
                onClick={handleNotificationsMenuClick}
              >
                {showBadge ? (
                  <Badge
                    sx={{
                      "& .MuiBadge-dot": {
                        padding: "3px",
                        marginTop: "6px",
                        minWidth: "6px",
                        width: "6px",
                        height: "6px",
                        background: "#ff4d4f",
                        marginRight: "7px",
                      },
                    }}
                    className="mx-2"
                    badgeContent={notificationsData?.data?.data?.length}
                    color="orange"
                  >
                    <NotificationsIcon className="text-hb_blue-800" />
                  </Badge>
                ) : (
                  <>
                    <NotificationsIcon className="text-hb_blue-800" />
                  </>
                )}
              </Box>
            </IconButton>
            <Menu
              anchorEl={notificationsAnchorEl}
              open={isNotificationsMenuOpen}
              onClose={handleCloseNotificationModal}
              on
              sx={{
                "& .MuiPaper-root": {
                  minWidth: 300,
                  borderRadius: "10px",
                  paddingBottom: "1rem",
                },
                "& .MuiList-root": {
                  height: "100%",
                },
              }}
            >
              {notificationsData?.data?.data &&
              Array.isArray(notificationsData?.data?.data) &&
              notificationsData?.data?.data?.length > 0 ? (
                <>
                  <div className="flex items-center justify-between">
                    <div className="p-2">
                      <h1 className="font-semibold text-hb_blue-800">
                        NOTIFICATIONS
                      </h1>
                    </div>
                    <div
                      className="mx-4 cursor-pointer"
                      onClick={handleCloseNotificationMenuReadAll}
                    >
                      <h5 className="text-sm font-semibold text-hb_orange-dark">
                        Clear All
                      </h5>
                    </div>
                  </div>

                  <Divider />
                  {notificationsData?.data?.data?.map((item) => (
                    <MenuItem
                      className="flex flex-col items-start space-y-2"
                      divider
                      onClick={() => handleCloseNotificationMenu(item)}
                    >
                      <div>
                        <p className="text-hb_blue-800"> {item?.data?.title}</p>
                      </div>
                      <div>
                        <p>{item?.data?.description} </p>
                        <div />
                      </div>
                    </MenuItem>
                  ))}
                </>
              ) : (
                <div className="flex items-center justify-center">
                  <p className="text-hb_gray-900">No new notifications!!</p>
                </div>
              )}
            </Menu>
          </div>

          <IconButton
            onClick={() =>
              window.open("https://habitbetter.com/apphelp", "_blank")
            }
            // onClick={() => window.open('/apphelp', "blank")}
            className="text-hb_blue-800"
          >
            <HelpIcon />
          </IconButton>

          <div className="my-account flex items-center">
            <span className="mr-2 text-lg font-normal text-hb_blue-800">
              <p className="user-name capitalize">
                {loggedInUserDetails?.data?.name || "User"}
              </p>
            </span>
            <IconButton>
              <Link to={RouteConstants.PROFILE}>
                <Avatar
                  alt={loggedInUserDetails?.data?.name}
                  src={loggedInUserDetails?.data?.avatar || ""}
                  className="my-auto flex items-center"
                  sx={{ width: 48, height: 48 }}
                />
              </Link>
            </IconButton>
            <IconButton
              size="small"
              onClick={handleProfileMenuClick}
              label="menu"
              id="profile-menu"
              aria-controls="profile-menu"
              aria-haspopup="true"
              aria-expanded={isProfileMenuOpen ? "true" : undefined}
            >
              <KeyboardArrowDownIcon
                className="cursor-pointer text-3xl text-hb_blue-800"
                sx={{ fontSize: 32, color: "black" }}
              />
            </IconButton>
            <Menu
              id="profile-menu"
              anchorEl={profileAnchorEl}
              open={isProfileMenuOpen}
              onClose={handleProfileMenuClose}
              MenuListProps={{
                "aria-labelledby": "profile-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  history.push("/profile");
                  handleProfileMenuClose();
                }}
              >
                <ManageAccountsIcon fontSize="small" className="mr-2" />
                My Account
              </MenuItem>
              <MenuItem
                onClick={() => {
                  logOut();
                }}
              >
                <LogoutIcon fontSize="small" className="mr-2" />
                Log Out
              </MenuItem>
            </Menu>
          </div>
        </div>
      </header>
      {openChatModal.open && (
        <ClientContextProvider>
          <CommonDialog
            chat
            maxWidth={"md"}
            open={openChatModal.open}
            onClose={handleCloseChatModal}
          >
            <div className="h-[500px]">
              <ChatBoxModal
                isAdd={true}
                handleClose={handleCloseChatModal}
                open={openChatModal}
                memberId={openChatModal?.uuid}
                memberEmail={openChatModal?.email}
              />
            </div>
          </CommonDialog>
        </ClientContextProvider>
      )}
      <FreeSessionDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onSubmit={handleSubmitDialog}
        inviteId={currentInvite.invite_id}
        userId={currentInvite.user_id}
      />
    </>
  );
};

export default Header;
