import {
  Autocomplete,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { FetchDiagnosisDataQuery } from "Pages/Clients/ClientQueries";
import CustomTextField from "components/FormComponents/CustomTextField";
import React, { Fragment } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { HiInformationCircle } from "react-icons/hi";

const BiopsychosocialAssessment = ({ disabled }) => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const textBoxData = [
    "Presenting Problem",
    "Signs and Symptoms (DSM-V-TR based) resulting in impairment(s): (Include current examples for treatment planning, e.g., social, occupational, affective, cognitive, physical)",
    "History of Presenting Problem (Events, precipitating factors, or incidents leading to need for services)",
    "Frequency/duration/severity/cycling of symptoms",
    "Was there a clear time when Sxs worsened ?",
    "Family mental health history",
  ];

  const relationshipsData = [
    "RelationshipsStrengths/support",
    "Stressors/problems",
    "Recent changes",
    "Changes desired",
    "Comment on family circumstances",
  ];

  const {
    register,
    formState: { errors },
    setValue,
    control,
  } = useFormContext();
  const presenting_data = useWatch({
    control,
    name: "presenting_data",
  });
  const { data: diagnosisData } = FetchDiagnosisDataQuery();
  const { ref: PresentingProblemRef, ...PresentingProblemReg } =
    register("Presenting_Problem");
  const { ref: FamilyMentalRef, ...FamilyMentalReg } =
    register("Family_Mental");
  const { ref: HistoryPresentingProblemRef, ...HistoryPresentingProblemReg } =
    register("History_Presenting_Problem");
  const { ref: SignSymptomsRef, ...SignSymptomsReg } =
    register("Sign_Symptoms");
  const { ref: cleartimeRef, ...cleartimeReg } = register("clear_time");
  const { ref: cyclingsymptomsRef, ...cyclingsymptomsReg } =
    register("cyclings_symptoms");

  const { ref: ChildhoodHistoryRef, ...ChildhoodHistoryReg } =
    register("Childhood_History");
  const { ref: RelationshipsStrengthsRef, ...RelationshipsStrengthsReg } =
    register("Relationships_Strengths");
  const { ref: StressorsproblemsRef, ...StressorsproblemsReg } =
    register("Stressors_problems");
  const { ref: RecentchangesRef, ...RecentchangesReg } =
    register("Recent_changes");
  const { ref: ChangesdesiredRef, ...ChangesdesiredReg } =
    register("Changes_desired");
  const { ref: CommentFamilyRef, ...CommentFamilyReg } =
    register("Comment_Family");
  const { ref: StrengthsupportRef, ...StrengthsupportReg } =
    register("Strengths_support");
  const { ref: StressorproblemsRef, ...StressorproblemsReg } =
    register("Stressor_problems");
  const { ref: RecentchangeRef, ...RecentchangeReg } =
    register("Recent_change");
  const { ref: ChangesdesireRef, ...ChangesdesireReg } =
    register("Changes_desire");
  const { ref: PlansNextSessionRef, ...PlansNextSessionReg } =
    register("PlansNext_session");
  const { ref: AssessmentProgressRef, ...AssessmentProgressReg } = register(
    "Assessment_Progress"
  );
  const { ref: ObjectiveFindingsRef, ...ObjectiveFindingsReg } =
    register("Objective_Findings");

  const { ref: SubjectiveComplaintRef, ...SubjectiveComplaintReg } = register(
    "Subjective_Complaint"
  );
  const { ref: SummaryPriorRef, ...SummaryPriorReg } =
    register("summary_prior");
  const { ref: BenefitsPreviousRef, ...BenefitsPreviousReg } =
    register("Benefits_Previous");
  const { ref: SetbacksPreviousRef, ...SetbacksPreviousReg } =
    register("Setbacks_Previous");
  const { ref: SummaryuseRef, ...SummaryuseReg } = register("Summary_use");
  const { ref: PatientsperceptionRef, ...PatientsperceptionReg } = register(
    "Patients_perception"
  );
  const { ref: SummaryHealthRef, ...SummaryHealthReg } =
    register("Summary_Health");
  const { ref: PhysicalfactorsRef, ...PhysicalfactorsReg } =
    register("Physical_factors");
  const { ref: CurrentImpactRef, ...CurrentImpactReg } =
    register("Current_Impact");
  const { ref: ChangesDesiredRef, ...ChangesDesiredReg } =
    register("Changes_Desired");
  const { ref: RecentchangedRef, ...RecentchangedReg } =
    register("recent_changed");
  const { ref: StrengthsSupportRef, ...StrengthsSupportReg } =
    register("strengths_Support");

  const { ref: StrengthsRef, ...StrengthsReg } = register("Strengths");
  const { ref: StressorsProblemsRef, ...StressorsProblemsReg } =
    register("Stressors_Problems");
  const { ref: StrengthCulturalsupportRef, ...StrengthCulturalsupportReg } =
    register("Strength_Cultural");
  const { ref: StressorCulturalproblemsRef, ...StressorCulturalproblemsReg } =
    register("Stressor_Cultural");
  const { ref: RecentCulturalchangeRef, ...RecentCulturalchangeReg } =
    register("Recent_Cultural");
  const { ref: ChangeCulturalsdesireRef, ...ChangeCulturalsdesireReg } =
    register("Culturals_desire");
  const { ref: StrengthSpiritualRef, ...StrengthSpiritualReg } =
    register("strength_spiritual");
  const { ref: StressorSpiritualRef, ...StressorSpiritualReg } =
    register("Stressor_spiritual");
  const { ref: RecentSpiritualRef, ...RecentSpiritualReg } =
    register("Recent_spiritual");
  const { ref: ChangesSpiritualRef, ...ChangesSpiritualReg } =
    register("Changes_spiritual");

  const { ref: strenghtEducationRef, ...strenghtEducationReg } =
    register("strenght_education");
  const { ref: WeaknessesRef, ...WeaknessesReg } = register("Weaknesses");
  const { ref: HistoryLegalRef, ...HistoryLegalReg } =
    register("History_Legal");
  const { ref: StatusImpactRef, ...StatusImpactReg } =
    register("Status_Imapct");

  const textBoxWithHeadingsData = [
    {
      title: "Social Relationships",
      items: [
        "Strengths/support",
        "Stressors/problems",
        "Recent changes",
        "Changes desired",
      ],
      DataRef: [
        StrengthsupportRef,
        StressorproblemsRef,
        RecentchangeRef,
        ChangesdesireRef,
      ],
      DataReg: [
        StrengthsupportReg,
        StressorproblemsReg,
        RecentchangeReg,
        ChangesdesireReg,
      ],
    },

    {
      title: "Cultural/Ethnic",
      items: [
        "Strengths/support",
        "Stressors/problems",
        "Beliefs/practices to incorporate into therapy",
      ],
      DataRef: [
        StrengthCulturalsupportRef,
        StressorCulturalproblemsRef,
        RecentCulturalchangeRef,
        ChangeCulturalsdesireRef,
      ],
      DataReg: [
        StrengthCulturalsupportReg,
        StressorCulturalproblemsReg,
        RecentCulturalchangeReg,
        ChangeCulturalsdesireReg,
      ],
    },

    {
      title: "Spiritual/Religious",
      items: [
        "Strengths/support",
        "Stressors/problems",
        "Beliefs/practices to incorporate into therapy",
        "Recent changes",
        "Changes desired",
      ],
      DataRef: [
        StrengthSpiritualRef,
        StressorSpiritualRef,
        RecentSpiritualRef,
        ChangesSpiritualRef,
      ],
      DataReg: [
        StrengthSpiritualReg,
        StressorSpiritualReg,
        RecentSpiritualReg,
        ChangesSpiritualReg,
      ],
    },

    {
      title: "Legal",
      items: ["History", "Status/impact/stressors"],
      DataRef: [HistoryLegalRef, StatusImpactRef],
      DataReg: [HistoryLegalReg, StatusImpactReg],
    },

    {
      title: "Education",
      items: ["Strengths", "Weaknesses"],
      DataRef: [strenghtEducationRef, WeaknessesRef],
      DataReg: [strenghtEducationReg, WeaknessesReg],
    },

    {
      title: "Employment/Vocational",
      items: ["Strengths/support", "Stressors/problems"],
      DataRef: [StrengthsRef, StressorsProblemsRef],
      DataReg: [StrengthsReg, StressorsProblemsReg],
    },

    {
      title: "Military",
      items: ["Current impact"],
      DataRef: [CurrentImpactRef],
      DataReg: [CurrentImpactReg],
    },

    {
      title: "Leisure/Recreational",
      items: ["Strengths/support", "Recent changes", "Changes desired"],
      DataRef: [StrengthsSupportRef, RecentchangedRef, ChangesDesiredRef],
      DataReg: [StrengthsSupportReg, RecentchangedReg, ChangesDesiredReg],
    },

    {
      title: "Physical Health",
      items: [
        "Summary of health",
        "Physical factors affecting mental condition",
      ],
      DataRef: [SummaryHealthRef, PhysicalfactorsRef],
      DataReg: [SummaryHealthReg, PhysicalfactorsReg],
    },

    {
      title: "Chemical/Substance Use History",
      items: ["Summary of use", "Patient’s perception of problem"],
      DataRef: [SummaryuseRef, PatientsperceptionRef],
      DataReg: [SummaryuseReg, PatientsperceptionReg],
    },

    {
      title: "Counseling/Prior Treatment History",
      items: [
        "Summary of prior treatment",
        "Benefits of previous treatment",
        "Setbacks of previous treatment",
      ],
      DataRef: [SummaryPriorRef, BenefitsPreviousRef, SetbacksPreviousRef],
      DataReg: [SummaryPriorReg, BenefitsPreviousReg, SetbacksPreviousReg],
    },

    {
      items: ["Subjective Complaint"],
      DataRef: [SubjectiveComplaintRef],
      DataReg: [SubjectiveComplaintReg],
    },

    {
      items: ["Objective Findings"],
      DataRef: [ObjectiveFindingsRef],
      DataReg: [ObjectiveFindingsReg],
    },

    {
      items: ["Assessment Of Progress"],
      DataRef: [AssessmentProgressRef],
      DataReg: [AssessmentProgressReg],
    },

    {
      items: ["Plans for Next Session"],
      DataRef: [PlansNextSessionRef],
      DataReg: [PlansNextSessionReg],
    },
  ];

  const relationshipsDataRef = [
    RelationshipsStrengthsRef,
    StressorsproblemsRef,
    RecentchangesRef,
    ChangesdesiredRef,
    CommentFamilyRef,
  ];
  const relationshipsDataReg = [
    RelationshipsStrengthsReg,
    StressorsproblemsReg,
    RecentchangesReg,
    ChangesdesiredReg,
    CommentFamilyReg,
  ];

  const PresentingProblemTextFieldRef = [
    PresentingProblemRef,
    SignSymptomsRef,
    HistoryPresentingProblemRef,
    cyclingsymptomsRef,
    cleartimeRef,
    FamilyMentalRef,
  ];
  const PresentingProblemTextFieldReg = [
    PresentingProblemReg,
    SignSymptomsReg,
    HistoryPresentingProblemReg,
    cyclingsymptomsReg,
    cleartimeReg,
    FamilyMentalReg,
  ];

  return (
    <div className="my-8 space-y-4">
      <div>
        <Typography
          variant="body2"
          fontWeight={500}
          marginTop="2rem !important"
          marginBottom={1}
        >
          Diagnosis:
        </Typography>

        <Autocomplete
          value={presenting_data || []}
          onChange={(event, value) => {
            let ids = value?.map((val) => val.id);

            if (ids) setValue("presenting_data", value);
          }}
          options={
            diagnosisData?.data?.map((item) => {
              return {
                id: item.id,
                name: `${item.code} - ${item.name}`,
              };
            }) || []
          }
          disableCloseOnSelect
          disabled={disabled}
          getOptionLabel={(option) => option.name}
          renderOption={(prop, option) => <li {...prop}>{option?.name}</li>}
          isOptionEqualToValue={(option, value) => {
            return Boolean(option.id === value.id);
          }}
          multiple
          renderInput={(params) => (
            <TextField
              className="borderless-select"
              {...params}
              variant="standard"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={disabled}
                      onClick={() =>
                        openInNewTab("https://habitbetter.com/diagnosticcodes/")
                      }
                    >
                      <HiInformationCircle className="w-6 text-hb_orange-dark" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </div>
      {textBoxData?.map((el, idx) => {
        return (
          <div>
            <Typography
              variant="body"
              fontWeight={100}
              marginTop="1rem !important"
              // className="flex flex-wrap"
            >
              {el}
            </Typography>
            <CustomTextField
              variant="standard"
              multiline
              rows={4}
              inputRef={PresentingProblemTextFieldRef[idx]}
              {...PresentingProblemTextFieldReg[idx]}
              key={idx}
              disabled={disabled}
            />
          </div>
        );
      })}

      <Typography variant="body2" fontWeight={500} marginTop="2rem !important">
        Current Family and Significant Relationships
      </Typography>

      {relationshipsData?.map((el, idx) => (
        <CustomTextField
          variant="standard"
          label={el}
          inputRef={relationshipsDataRef[idx]}
          {...relationshipsDataReg[idx]}
          multiline
          rows={4}
          disabled={disabled}
          key={idx}
        />
      ))}
      <div className="my-2">
        <Typography variant="body" fontWeight={100} marginTop="1rem !important">
          Childhood/Adolescent History (Developmental milestones, past
          behavioral concerns, environment, abuse, school, social, mental
          health)
        </Typography>
        <CustomTextField
          variant="standard"
          multiline
          rows={4}
          inputRef={ChildhoodHistoryRef}
          {...ChildhoodHistoryReg}
          error={Boolean(errors.Childhood_History)}
          helperText={
            errors.Childhood_History && errors.Childhood_History.message
          }
          disabled={disabled}
        />
      </div>

      {textBoxWithHeadingsData?.map((el, pIdx) => (
        <Fragment key={pIdx}>
          {el.title && (
            <Typography
              variant="body2"
              fontWeight={500}
              marginTop="2rem !important"
            >
              {el.title}
            </Typography>
          )}

          {el.items?.map((item, idx) => {
            return (
              <CustomTextField
                variant="standard"
                label={item}
                multiline
                rows={4}
                key={idx}
                inputRef={el?.DataRef?.[idx]}
                {...el?.DataReg?.[idx]}
                error={Boolean(errors.Social_Relationships)}
                disabled={disabled}
              />
            );
          })}
        </Fragment>
      ))}
    </div>
  );
};

export default BiopsychosocialAssessment;
