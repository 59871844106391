import {
  Autocomplete,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { FetchDiagnosisDataQuery } from "Pages/Clients/ClientQueries";
import CustomTextField from "components/FormComponents/CustomTextField";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { HiInformationCircle } from "react-icons/hi";

const PatientSafetyPlan = ({ disabled }) => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const presenting_data = useWatch({
    control,
    name: "presenting_data",
  });
  //  queries
  const { data: diagnosisData } = FetchDiagnosisDataQuery();
  const { ref: worthlivingRef, ...worthlivingReg } = register("worth_living");
  const { ref: environmentRef, ...environmentReg } = register("environment");
  const { ref: environment1Ref, ...environment1Reg } = register("environment1");
  const { ref: environment2Ref, ...environment2Reg } = register("environment2");
  const { ref: CareServicesRef, ...CareServicesReg } =
    register("care_services");
  const { ref: ClinicianNameRef, ...ClinicianNameReg } =
    register("clinician_name");
  const { ref: socialRef, ...socialReg } = register("social");
  const { ref: social1Ref, ...social1Reg } = register("social1");
  const { ref: social2Ref, ...social2Reg } = register("social2");
  const { ref: social3Ref, ...social3Reg } = register("social3");
  const { ref: helpRef, ...helpReg } = register("help");
  const { ref: help1Ref, ...help1Reg } = register("help1");
  const { ref: help2Ref, ...help2Reg } = register("help2");
  const { ref: copingSkillsRef, ...copingSkillsReg } =
    register("coping_skills");
  const { ref: copingSkills2Ref, ...copingSkills2Reg } =
    register("coping_skills2");
  const { ref: copingSkills1Ref, ...copingSkills1Reg } =
    register("coping_skills1");
  const { ref: WarningsignsRef, ...WarningsignsReg } = register("waring_signs");
  const { ref: Warningsigns1Ref, ...Warningsigns1Reg } =
    register("waring_signs1");
  const { ref: Warningsigns2Ref, ...Warningsigns2Reg } =
    register("waring_signs2");

  return (
    <div className="my-8 space-y-8">
      <div>
        <Typography
          variant="body2"
          fontWeight={500}
          marginTop="2rem !important"
          marginBottom={1}
        >
          Diagnosis:
        </Typography>

        <Autocomplete
          value={presenting_data || []}
          onChange={(event, value) => {
            let ids = value?.map((val) => val.id);

            if (ids) setValue("presenting_data", value);
          }}
          options={
            diagnosisData?.data?.map((item) => {
              return {
                id: item.id,
                name: `${item.code} - ${item.name}`,
              };
            }) || []
          }
          disableCloseOnSelect
          disabled={disabled}
          getOptionLabel={(option) => option.name}
          renderOption={(prop, option) => <li {...prop}>{option?.name}</li>}
          isOptionEqualToValue={(option, value) => {
            return Boolean(option.id === value.id);
          }}
          multiple
          renderInput={(params) => (
            <TextField
              className="borderless-select"
              {...params}
              variant="standard"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={disabled}
                      onClick={() =>
                        openInNewTab("https://habitbetter.com/diagnosticcodes/")
                      }
                    >
                      <HiInformationCircle className="w-6 text-hb_orange-dark" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </div>
      <Typography variant="body2" fontWeight={500} marginTop="2rem !important">
        Warning signs (thoughts, images, mood, situation, behavior) that a
        crisis may be developing
      </Typography>
      <CustomTextField
        label="1"
        variant="standard"
        inputRef={WarningsignsRef}
        {...WarningsignsReg}
        disabled={disabled}
      />
      <CustomTextField
        label="2"
        variant="standard"
        inputRef={Warningsigns1Ref}
        {...Warningsigns1Reg}
        disabled={disabled}
      />
      <CustomTextField
        label="3"
        variant="standard"
        inputRef={Warningsigns2Ref}
        {...Warningsigns2Reg}
        disabled={disabled}
      />

      <Typography variant="body2" fontWeight={500} marginTop="2rem !important">
        Internal coping strategies – Things I can do to take my mind off my
        problems without contacting another person (relaxation technique,
        physical activity, coping skills)
      </Typography>
      <CustomTextField
        label="1"
        variant="standard"
        inputRef={copingSkillsRef}
        {...copingSkillsReg}
        disabled={disabled}
      />
      <CustomTextField
        label="2"
        variant="standard"
        inputRef={copingSkills1Ref}
        {...copingSkills1Reg}
        disabled={disabled}
      />
      <CustomTextField
        label="3"
        variant="standard"
        inputRef={copingSkills2Ref}
        {...copingSkills2Reg}
        disabled={disabled}
      />

      <Typography variant="body2" fontWeight={500} marginTop="2rem !important">
        People and social settings that provide distraction:
      </Typography>
      <CustomTextField
        label="Name/Phone"
        variant="standard"
        inputRef={socialRef}
        {...socialReg}
        disabled={disabled}
      />
      <CustomTextField
        label="Name/Phone"
        variant="standard"
        inputRef={social1Ref}
        {...social1Reg}
        disabled={disabled}
      />

      <CustomTextField
        label="Place"
        variant="standard"
        inputRef={social2Ref}
        {...social2Reg}
        disabled={disabled}
      />
      <CustomTextField
        label="Place"
        variant="standard"
        inputRef={social3Ref}
        {...social3Reg}
        disabled={disabled}
      />

      <Typography variant="body2" fontWeight={500} marginTop="2rem !important">
        People whom I can ask for help
      </Typography>
      <CustomTextField
        label="Name/Phone"
        variant="standard"
        inputRef={helpRef}
        {...helpReg}
        disabled={disabled}
      />

      <CustomTextField
        label="Name/Phone"
        variant="standard"
        inputRef={help1Ref}
        {...help1Reg}
        disabled={disabled}
      />
      <CustomTextField
        label="Name/Phone"
        variant="standard"
        inputRef={help2Ref}
        {...help2Reg}
        disabled={disabled}
      />

      <Typography variant="body2" fontWeight={500} marginTop="2rem !important">
        Professionals or agencies I can contact during a crisis
      </Typography>
      <CustomTextField
        label="Clinician Name / Phone / Emergency Phone"
        variant="standard"
        inputRef={ClinicianNameRef}
        {...ClinicianNameReg}
        disabled={disabled}
      />
      <CustomTextField
        label="Local Urgent Care Services / Address / Phone"
        variant="standard"
        inputRef={CareServicesRef}
        {...CareServicesReg}
        disabled={disabled}
      />
      <CustomTextField
        label="Suicide Prevention Lifeline Phone:"
        variant="standard"
        value="1-800-273-TALK (8255)"
        disabled
      />

      <Typography variant="body2" fontWeight={500} marginTop="2rem !important">
        Making the environment safe
      </Typography>
      <CustomTextField
        label="1"
        variant="standard"
        inputRef={environmentRef}
        {...environmentReg}
        disabled={disabled}
      />
      <CustomTextField
        label="2"
        variant="standard"
        inputRef={environment1Ref}
        {...environment1Reg}
        disabled={disabled}
      />
      <CustomTextField
        label="3"
        variant="standard"
        inputRef={environment2Ref}
        {...environment2Reg}
        disabled={disabled}
      />

      <CustomTextField
        label="The one thing that is most important to me and worth living for is"
        variant="standard"
        multiline
        rows={4}
        inputRef={worthlivingRef}
        {...worthlivingReg}
        disabled={disabled}
      />
    </div>
  );
};

export default PatientSafetyPlan;
