import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Typography,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FetchProfileDetailsQuery } from "Pages/Profile/ProfileQueries";
import { HiInformationCircle } from "react-icons/hi";

const FreeSessionDialog = ({ open, onClose, onSubmit, inviteId, userId }) => {
  const handleSubmit = (freeSessionEnabled) => {
    onSubmit(inviteId, userId, freeSessionEnabled);
    onClose();
  };
  const { data: profileDetails } = FetchProfileDetailsQuery();

  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth maxWidth="xs">
      <DialogTitle className="flex items-center justify-between rounded-t-lg p-5">
        <Typography className="text-xl font-semibold">
          Enable Free Session{" "}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          className="text-gray-500 hover:text-gray-800"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="bg-white p-5">
        <Typography className="text-base">
          Provide client free access to the mobile app?
        </Typography>
      </DialogContent>
      <DialogActions className="space-x-4 p-5">
        <Button
          color="error"
          variant="contained"
          onClick={() => handleSubmit(0)}
          className="rounded  py-2 px-6 font-medium text-white shadow"
        >
          No
        </Button>

        <div className="flex items-center">
          <Button
            onClick={() => handleSubmit(1)}
            color="primary"
            variant="contained"
            className=" rounded py-2 px-4 font-bold text-white"
            disabled={profileDetails?.data?.free_user_available_count === 0}
          >
            Yes
          </Button>
          {profileDetails?.data?.free_user_available_count === 0 && (
            <Tooltip
              arrow
              title="You have exceed your limit of Free Access. clicking NO you can accept the invite Without Free Access."
            >
              <IconButton>
                <HiInformationCircle className=" text-hb_orange-dark" />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default FreeSessionDialog;
