import { FormHelperText, InputLabel } from "@mui/material";
import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./quill.css";

const toolbarOptions = [
  [{ header: [1, 2, 3, false] }],
  ["bold", "italic", "underline", "blockquote"],
  [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
  ["clean"],
];

const RichTextEditor = (props) => {
  const {
    label,
    required,
    defaultValue,
    onChange,
    error,
    helperText,
    focusOnError,
    allErrors,
    errorFocusKey,
    inputRef,
    readOnly,
    ...restQuillProps
  } = props;

  return (
    <div>
      <InputLabel
        required={required}
        sx={{
          marginBottom: 2,
          color: "fieldLabel.main",
          fontSize: 14,
          fontWeight: 400,
        }}
      >
        {label}
      </InputLabel>
      <>
        <ReactQuill
          defaultValue={defaultValue}
          onChange={(content, delta, source, editor) => {
            onChange(editor.getLength() < 2 ? null : content);
          }}
          // onBlur={(previousRange, source, editor) => {
          //   onChange(editor.getHTML());
          // }}
          {...restQuillProps}
          ref={inputRef}
          modules={{
            toolbar: readOnly ? null : toolbarOptions,
          }}
          readOnly={readOnly}
        />

        <FormHelperText error={Boolean(error)}>{helperText}</FormHelperText>
      </>
    </div>
  );
};

RichTextEditor.defaultProps = {
  label: "",
  required: false,
  defaultValue: "",
  onChange: () => null,
  focusOnError: false,
  allErrors: null,
  errorFocusKey: "",
  readOnly: false,
};

export default RichTextEditor;
