import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { Backdrop } from "@mui/material";
import { useIsFetching, useIsMutating } from "react-query";
import RouteConstants from "./RouteConstants";
import LoginPage from "Pages/LoginPage";
import AppContainer from "Pages/AppContainer";
import SignupPage from "Pages/SignupPage";
import Loader from "components/Loader";
import Lottie from "react-lottie";

const LottieOptions = {
  loop: true,
  autoplay: true,
  animationData: Loader,
  rendererSettings: {
    // preserveAspectRatio: "xMidYMid slice",
  },
};

const Routes = () => {
  const runningAPICount = useIsFetching();
  const mutatingAPICount = useIsMutating();

  return (
    <>
      <Router>
        <Switch>
          <Redirect exact from="/" to="/login" />
          <Route path={RouteConstants.LOGIN} component={LoginPage} />
          <Route path={RouteConstants.SIGNUP} component={SignupPage} />
          <Route path={RouteConstants.REDIRECTOR} component={AppContainer} />
        </Switch>
      </Router>
      <Backdrop
        open={Boolean(runningAPICount || mutatingAPICount)}
        sx={{
          zIndex: (theme) => theme.zIndex.modal + 100,
          backgroundColor: "rgba(0,0,0,0.3)",
        }}
      >
        <Lottie
          // isClickToPauseDisabled={false}
          options={LottieOptions}
          height={150}
          width={"100%"}
        />
      </Backdrop>
    </>
  );
};

export default Routes;
