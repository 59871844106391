import { Grid, Paper, useMediaQuery } from "@mui/material";
import GroupLogo from "assets/Images/GroupLogo.svg";
import CareBetterLogo from "assets/Images/CareBetterWordmark.png";
import { useHistory } from "react-router-dom";
import RouteConstants from "Routes/RouteConstants";
import SignupForm from "./SignupForm";

const SignupPage = () => {
  const history = useHistory();
  const isSmallScreen = useMediaQuery("(max-width:750px)");
  const accessToken = localStorage.getItem("token");

  if (accessToken && accessToken !== "null") {
    history.push(RouteConstants.PRACTICE);
  }
  return (
    <div className="flex-center h-screen w-screen">
      <Grid container className="flex items-center">
        {isSmallScreen || (
          <Grid item xs={6}>
            <div className="flex-center">
              <img src={GroupLogo} alt="group-logo" />
            </div>
          </Grid>
        )}

        <Grid item xs={isSmallScreen ? 12 : 6}>
          <div className="flex-center">
            <Paper variant="outlined" className="w-3/4">
              <div className="flex-center my-8">
                <img
                  src={CareBetterLogo}
                  alt="habitbetter_logo"
                  className="w-64"
                ></img>
              </div>
              <div
                className="hide-scrollbar mx-8 lg:mx-16"
                style={{ maxHeight: "80vh", overflow: "auto" }}
              >
                <SignupForm />
              </div>
            </Paper>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SignupPage;
