import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAPI, useToast } from "services";
import * as Sentry from "@sentry/react";

const FetchClients = (body) => {
  const method = "GET";
  let url = `api/coach/my-client`;
  if (body?.search) {
    url += `?search=${body.search}`;
  }
  return useAPI({
    method,
    url,
  });
};

const FetchPendingInvoiceClients = (body) => {
  const method = "GET";
  let url = `api/coach/client/pending/invoice`;
  return useAPI({
    method,
    url,
  });
};

const FetchFamilyClients = (body) => {
  const method = "GET";
  let url = `api/coach/my-client/${body.familyType}`;
  if (body?.search) {
    url += `?search=${body.search}`;
  }
  return useAPI({
    method,
    url,
  });
};

const CreateClientAppointment = (body) => {
  delete body.uuid;
  const method = "POST";
  const url = `api/coach/client-appointment`;
  return useAPI({
    method,
    url,
    body,
  });
};
const CreateGroupAppointment = (body) => {
  const { data } = body;
  const method = "POST";
  const url = `api/coach/group/${body?.groupId}/appointment`;
  return useAPI({
    method,
    url,
    body: data,
  });
};
export const CreateGroupAppointmentQuery = (payload) => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  return useMutation(CreateGroupAppointment, {
    onSuccess: (response) => {
      responseToast.toastSuccess(
        response.message || "Appointment Created Successfully"
      );
      queryClient.invalidateQueries(`FETCH_COACH_APPOINTMENT`);
      queryClient.invalidateQueries([`CLIENT_APPOINTMENTS`]);
      queryClient.invalidateQueries("PENDING_APPOINTMENTS");
      queryClient.invalidateQueries("FETCH_COACH_DUE_AMOUNT");
      queryClient.invalidateQueries([`CLIENT_APPOINTMENT_WIDGET`]);
    },
    onError: ErrorHandler,
  });
};

const AcceptClientAppointment = (body) => {
  const method = "POST";
  const url = `api/coach/session/${body?.session_id}/accept`;
  return useAPI({
    method,
    url,
    body: { ...body?.payload },
  });
};

export const AcceptClientAppointmentQuery = () => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  return useMutation(AcceptClientAppointment, {
    onSuccess: (response) => {
      responseToast.toastSuccess(response.message);
      queryClient.invalidateQueries(`FETCH_COACH_APPOINTMENT`);
      queryClient.invalidateQueries([`CLIENT_APPOINTMENTS`]);
      queryClient.invalidateQueries("PENDING_APPOINTMENTS");
      queryClient.invalidateQueries("FETCH_COACH_DUE_AMOUNT");
      queryClient.invalidateQueries([`CLIENT_APPOINTMENT_WIDGET`]);
      queryClient.invalidateQueries(["COACH_BOOKED_TIMINGS"]);
    },
    onError: ErrorHandler,
  });
};
const DenyClientAppointment = (body) => {
  const method = "POST";
  const url = `api/coach/session/${body?.session_id}/reject`;
  return useAPI({
    method,
    url,
  });
};

export const DenyClientAppointmentQuery = () => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  return useMutation(DenyClientAppointment, {
    onSuccess: (response) => {
      responseToast.toastSuccess(response.message);
      queryClient.invalidateQueries(["COACH_BOOKED_TIMINGS"]);
    },
    onError: ErrorHandler,
  });
};

const DeleteInvoice = (body) => {
  const method = "DELETE";
  const url = `api/coach/billings?billing_id=${body?.billindId}`;
  return useAPI({
    method,
    url,
  });
};
export const DeleteInvoiceQuery = (payload) => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  return useMutation(DeleteInvoice, {
    onSuccess: (response) => {
      responseToast.toastSuccess(
        response.message || "Deleted Invoices Successfully"
      );
      queryClient.invalidateQueries("FETCH_CLIENT_BILLINGS");
      queryClient.invalidateQueries([[`CLIENT_APPOINTMENTS`]]);
      queryClient.invalidateQueries("PENDING_APPOINTMENTS");
      queryClient.invalidateQueries("FETCH_COACH_DUE_AMOUNT");
      queryClient.invalidateQueries([`CLIENT_APPOINTMENT_WIDGET`]);
    },
    onError: ErrorHandler,
  });
};

const FetchClientAppointments = (body) => {
  const method = "GET";
  const url = `api/coach/client-appointment?uuid=${body.uuid}`;
  return useAPI({
    method,
    url,
  });
};

const FetchClientDiagnosisList = (body) => {
  const method = "GET";
  const url = `api/coach/diagnosis_list?${body?.typeId}=${body?.id}`;
  return useAPI({
    method,
    url,
  });
};
export const FetchClientDiagnosisListQuery = (payload) => {
  return useQuery(
    [`CLIENT_DIAGNOSIS_LIST`, payload?.id, payload?.typeId],
    () => FetchClientDiagnosisList(payload),
    {
      enabled: Boolean(payload?.id),
      onSuccess: () => {},
      onError: ErrorHandler,
    }
  );
};

const FetchClientAppointmentWidget = (body) => {
  const method = "GET";
  const url = `api/coach/client-appointment/widget?uuid=${body.uuid}`;
  return useAPI({
    method,
    url,
  });
};
const FetchClientCheckin = (body) => {
  const method = "GET";
  const url = `api/coach/custom/${body?.user_id}/checkins/settings`;
  return useAPI({
    method,
    url,
  });
};

export const FetchClientCheckinQuery = (payload) => {
  return useQuery(
    [`CLIENT_CHECKIN_SETTING`, payload],
    () => FetchClientCheckin(payload),
    {
      enabled: Boolean(payload?.user_id),
      onSuccess: () => {},
      onError: ErrorHandler,
    }
  );
};
const FetchClientCheckinNow = (body) => {
  const method = "POST";
  const url = `api/coach/custom/checkins/${body?.checkin_id}/now/${body?.user_id}`;
  return useAPI({
    method,
    url,
  });
};

export const FetchClientCheckinNowQuery = () => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  return useMutation(FetchClientCheckinNow, {
    onSuccess: (response) => {
      responseToast.toastSuccess(response.message);
    },
    onError: ErrorHandler,
  });
};
const FetchClientPendingAppointments = (body) => {
  const method = "GET";
  const url = `api/coach/pending/appointments?${body?.typeId}=${body?.id}`;
  return useAPI({
    method,
    url,
  });
};
const UpdateClientAppointment = (body) => {
  delete body.uuid;
  const method = "PUT";
  const url = `api/coach/client-appointment`;
  return useAPI({
    method,
    url,
    body,
  });
};

const UpdateUploadDocument = (body) => {
  const method = "PUT";
  const url = `api/coach/documents/${body?.documentid}/mark-complete`;
  return useAPI({
    method,
    url,
  });
};
export const UpdateUploadDocumentQuery = () => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  return useMutation(UpdateUploadDocument, {
    onSuccess: (response) => {
      responseToast.toastSuccess(response.message);
      queryClient.invalidateQueries("SEND_DOCUMENT");
      queryClient.invalidateQueries("PEND_DOCUMENT");
      queryClient.invalidateQueries("SEND_DOCUMENT_FAMILY");
      queryClient.invalidateQueries("PEND_DOCUMENT_FAMILY");
    },
    onError: ErrorHandler,
  });
};
const UpdateClientBillings = (body) => {
  const method = "PUT";
  const url = `api/coach/billings`;
  return useAPI({
    method,
    url,
    body,
  });
};
export const UpdateClientBillingsQuery = () => {
  const queryClient = useQueryClient();
  const responseToast = useToast();
  return useMutation(UpdateClientBillings, {
    onSuccess: (response) => {
      responseToast.toastSuccess(
        response.message || "Invoice Updated Successfully"
      );
      queryClient.invalidateQueries("FETCH_CLIENT_BILLINGS");
      queryClient.invalidateQueries("FETCH_COACH_BILLINGS");
      queryClient.invalidateQueries("FETCH_COACH_TOTAL_AMOUNT");
      queryClient.invalidateQueries("FETCH_COACH_DUE_AMOUNT");
    },
    onError: ErrorHandler,
  });
};

const FetchGroups = (body) => {
  const method = "GET";
  let url = `api/coach/group`;
  if (body?.search) {
    url += `?search=${body.search}`;
  }
  return useAPI({
    method,
    url,
  });
};

const CreateGroup = (body) => {
  const method = "POST";
  const url = `api/coach/group`;
  return useAPI({
    method,
    url,
    body,
  });
};

const FetchClientNotes = (body) => {
  const method = "GET";
  const url = `api/coach/client-notes?uuid=${body.uuid}`;
  return useAPI({
    method,
    url,
  });
};

const FetchClientNoteByID = (id) => {
  const method = "GET";
  const url = `api/coach/get-client-notes?id=${id}`;
  return useAPI({
    method,
    url,
  });
};

const CreateClientNotes = (body) => {
  delete body.uuid;
  const method = "POST";
  const url = `api/coach/client-notes`;
  return useAPI({
    method,
    url,
    body,
  });
};

const RemoveUser = (payload) => {
  const method = "POST";
  const url = `api/coach/remove/client`;
  const body = { uuid: payload?.uuid };
  return useAPI({ method, url, body });
};

export const RemoveUserQuery = (payload) => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  return useMutation(RemoveUser, {
    onSuccess: (response) => {
      responseToast.toastSuccess(
        response.message || "Remove User Successfully"
      );
      queryClient.invalidateQueries("CLIENTS_LIST");
    },
    onError: ErrorHandler,
  });
};

const UpdateClientNote = (body) => {
  const method = "PUT";
  const url = `api/coach/client-notes`;
  return useAPI({
    method,
    url,
    body,
  });
};

const DeleteClientNote = (body) => {
  const method = "DELETE";
  const url = `api/coach/client-notes`;
  return useAPI({
    method,
    url,
    body,
  });
};

const FetchClientGoalsByUUID = (body) => {
  const method = "GET";
  const url = `api/coach/client-goals?uuid=${body.uuid}`;
  return useAPI({
    method,
    url,
  });
};

const InviteClient = (body) => {
  const method = "POST";
  const url = `api/coach/invites`;
  return useAPI({
    method,
    url,
    body,
  });
};

const AddFamilyMember = (body) => {
  const method = "POST";
  const url = `api/coach/invite/family`;
  return useAPI({
    method,
    url,
    body,
  });
};

const FetchCoachToken = () => {
  const method = "GET";
  const url = `api/coach/token`;
  return useAPI({
    method,
    url,
  });
};

export const FetchCoachTokenQuery = () => {
  return useQuery(`FETCH_COACH_TOKEN`, FetchCoachToken, {
    enabled: true,
    onSuccess: () => {},
    onError: ErrorHandler,
  });
};

const FetchClientHabits = (body) => {
  const method = "POST";
  const url = `api/coach/client-habits`;
  return useAPI({
    method,
    url,
    body,
  });
};

const FetchClientDailyPrompts = (body) => {
  const method = "GET";
  const url = `api/coach/user/daily-practices?user_id=${body?.user_id}&page=${
    body?.page
  }&perpage=${10}`;
  return useAPI({
    method,
    url,
  });
};

export const FetchClientDailyPromptsQuery = (payload) => {
  return useQuery(
    [`CLIENT_DAILY_PROMPTS`, payload],
    () => FetchClientDailyPrompts(payload),
    {
      enabled: Boolean(payload?.user_id && payload?.page),
      onSuccess: () => {},
      onError: () => {},
    }
  );
};

const FetchSendDocuments = (body) => {
  const method = "GET";
  const url = `api/coach/client/${body?.id}/documents`;
  return useAPI({
    method,
    url,
    body,
  });
};

const FetchPendingDocuments = (body) => {
  const method = "GET";
  const url = `api/coach/client/${body?.id}/documents/pending`;
  return useAPI({
    method,
    url,
    body,
  });
};

export const FetchPendingDocumentsQuery = (payload) => {
  return useQuery(
    [`PEND_DOCUMENT`, payload?.id],
    () => FetchPendingDocuments(payload),
    {
      enabled: payload?.id ? true : false,
      onSuccess: () => {},
      onError: ErrorHandler,
    }
  );
};

export const FetchSendDocumentsQuery = (payload) => {
  return useQuery(
    [`SEND_DOCUMENT`, payload?.id],
    () => FetchSendDocuments(payload),
    {
      enabled: Boolean(payload?.id),
      onSuccess: () => {},
      onError: ErrorHandler,
    }
  );
};

const FetchClientCourses = (body) => {
  const method = "GET";
  const url = `api/coach/client/${body?.id}/course`;
  return useAPI({
    method,
    url,
  });
};

const UpdateCheckinSetting = (payload) => {
  const { user_id, body } = payload;
  const method = "POST";
  const url = `api/coach/client/${user_id}/checkins/settings`;
  return useAPI({ method, url, body });
};

const UpdateReminderAppointment = (aptData) => {
  const { user_id, body } = aptData;
  const method = "PUT";
  const url = `api/coach/client/${user_id}/appointment/reminder`;
  return useAPI({ method, url, body });
};

export const UpdateReminderAppointmentQuery = (payload) => {
  const responseToast = useToast();
  return useMutation(UpdateReminderAppointment, {
    onSuccess: (response) => {
      responseToast.toastSuccess(
        response.message || "Sent Appointment Reminder"
      );
    },
    onError: ErrorHandler,
  });
};

export const UpdateCheckinSettingQuery = (payload) => {
  const responseToast = useToast();
  return useMutation(UpdateCheckinSetting, {
    onSuccess: (res) => {
      responseToast.toastSuccess(res?.message);
    },
    onError: ErrorHandler,
  });
};

const FetchClientCheckins = (body) => {
  const method = "POST";
  const url = `api/coach/client-checkins`;
  return useAPI({
    method,
    url,
    body,
  });
};

const FetchClientCustomCheckinsList = (body) => {
  const method = "GET";
  const url = `api/coach/custom/coachusercheckins/${body?.user_id}`;
  return useAPI({
    method,
    url,
  });
};

export const FetchClientCustomCheckinsListQuery = (payload) => {
  return useQuery(
    [`CLIENT_CUSTOM_CHECKINS_LISTS`, payload?.user_id],
    () => FetchClientCustomCheckinsList(payload),
    {
      enabled: Boolean(payload?.user_id),
      onSuccess: () => {},
      onError: ErrorHandler,
    }
  );
};

const FetchClientCheckinsGad7 = (body) => {
  const method = "GET";
  const url = `api/coach/client/${body.user_id}/checkins/gad-7`;
  return useAPI({
    method,
    url,
  });
};

const FetchClientCheckinsPhq9 = (body) => {
  const method = "GET";
  const url = `api/coach/client/${body.user_id}/checkins/phq-9`;
  return useAPI({
    method,
    url,
  });
};

const FetchGroupByID = (id) => {
  const method = "GET";
  const url = `api/coach/group/${id}`;
  return useAPI({
    method,
    url,
  });
};

const UpdateGroupByID = (body) => {
  const method = "PUT";
  const url = `api/coach/group`;
  return useAPI({
    method,
    url,
    body,
  });
};
const UpdateDiagnosisFormByID = (body) => {
  const method = "PUT";
  const url = `api/coach/update/diagnosis`;
  return useAPI({
    method,
    url,
    body,
  });
};
export const UpdateDiagnosisFormByIDQuery = () => {
  const queryClient = useQueryClient();
  const responseToast = useToast();
  return useMutation(UpdateDiagnosisFormByID, {
    onSuccess: (response) => {
      responseToast.toastSuccess(response.message);
      queryClient.invalidateQueries([`CLIENT_DIAGNOSIS_LIST`]);
    },
    onError: ErrorHandler,
  });
};

const FetchClientJournals = (body) => {
  const method = "GET";
  const url = `api/coach/client/journals?uuid=${body.uuid}`;
  return useAPI({
    method,
    url,
  });
};

const FetchClientsBillings = (body) => {
  const method = "GET";
  const url = `api/coach/client/billings?user_id=${body?.user_id}`;
  return useAPI({
    method,
    url,
  });
};

export const FetchClientsBillingsQuery = (payload) => {
  return useQuery(
    ["FETCH_CLIENT_BILLINGS", payload],
    () => FetchClientsBillings(payload),
    {
      enabled: Boolean(payload?.user_id),
      onSuccess: () => {},
      onError: ErrorHandler,
    }
  );
};

const RemoveClientAppointment = (body) => {
  const method = "DELETE";
  const url = `api/coach/client-appointment`;
  return useAPI({
    method,
    url,
    body,
  });
};

const FetchClientCheckinsGraph = (body) => {
  const method = "POST";
  const url = `api/coach/client-checkins-graph`;
  return useAPI({
    method,
    url,
    body,
  });
};

const CreateClientBilling = (body) => {
  const method = "POST";
  const url = `api/coach/billings`;
  return useAPI({
    method,
    url,
    body,
  });
};

export const CreateClientBillingQuery = () => {
  const queryClient = useQueryClient();
  const responseToast = useToast();
  return useMutation(CreateClientBilling, {
    onSuccess: (response) => {
      responseToast.toastSuccess(
        response.message || "Invoice Created Successfully"
      );
      queryClient.invalidateQueries("FETCH_COACH_TOTAL_AMOUNT");
      queryClient.invalidateQueries("FETCH_COACH_DUE_AMOUNT");
      queryClient.invalidateQueries("FETCH_CLIENT_BILLINGS");
      queryClient.invalidateQueries("PENDING_APPOINTMENTS");
      queryClient.invalidateQueries("FETCH_COACH_BILLINGS");
      window.open(response.data);
    },
    onError: ErrorHandler,
  });
};
const FetchDiagnosisData = () => {
  const method = "GET";
  const url = `api/coach/diagnosis`;
  return useAPI({ method, url });
};
export const FetchDiagnosisDataQuery = () => {
  return useQuery("GET_DIAGNOSIS_DATA", FetchDiagnosisData, {
    enabled: true,
  });
};

const CreateClientDiagnosis = (body) => {
  const method = "POST";
  const url = `api/coach/create/diagnosis`;
  return useAPI({ method, url, body });
};

export const CreateClientDiagnosisQuery = (payload) => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  return useMutation(CreateClientDiagnosis, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(["CLIENT_DIAGNOSIS_LIST"]);
      responseToast.toastSuccess(response?.message);
    },
    onError: ErrorHandler,
  });
};

const FetchClientCheckinsGad7Graph = (body) => {
  const method = "GET";
  const url = `api/coach/client/${body.user_id}/checkins/gad-7/graph`;
  return useAPI({
    method,
    url,
  });
};

const FetchClientCheckinsPhq9Graph = (body) => {
  const method = "GET";
  const url = `api/coach/client/${body.user_id}/checkins/phq-9/graph`;
  return useAPI({
    method,
    url,
  });
};

const FetchClientInvitees = (body) => {
  const method = "GET";
  let url = `api/coach/invites?status=${body.status}`;
  if (body?.search) {
    url += `?search=${body.search}`;
  }
  return useAPI({
    method,
    url,
  });
};

export const FetchClientsQuery = (payload) => {
  return useQuery(["CLIENTS_LIST", payload], () => FetchClients(payload), {
    enabled: true,
    onSuccess: () => {},
    onError: ErrorHandler,
  });
};

export const FetchPendingInvoiceClientsQuery = (payload) => {
  return useQuery(
    ["CLIENTS_LIST_PENDING_INVOICE"],
    () => FetchPendingInvoiceClients(payload),
    {
      enabled: payload?.source === "practice",
      onSuccess: (res) => {
        console.log(res);
      },
      onError: ErrorHandler,
    }
  );
};

export const FetchFamilyClientsQuery = (payload) => {
  return useQuery(
    ["FAMILY_CLIENTS_LIST", payload],
    () => FetchFamilyClients(payload),
    {
      enabled: true,
      onSuccess: () => {},
      onError: ErrorHandler,
    }
  );
};

export const FetchCoupleClientsQuery = (payload) => {
  return useQuery(
    ["COUPLE_CLIENTS_LIST", payload],
    () => FetchFamilyClients(payload),
    {
      enabled: true,
      onSuccess: () => {},
      onError: ErrorHandler,
    }
  );
};

export const CreateClientAppointmentQuery = (payload) => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  return useMutation(CreateClientAppointment, {
    onSuccess: (response) => {
      responseToast.toastSuccess("Appointment Scheduled");
      queryClient.invalidateQueries(`FETCH_COACH_APPOINTMENT`);
      queryClient.invalidateQueries([`CLIENT_APPOINTMENTS`]);
      queryClient.invalidateQueries("PENDING_APPOINTMENTS");
      queryClient.invalidateQueries("FETCH_COACH_DUE_AMOUNT");
      queryClient.invalidateQueries([`CLIENT_APPOINTMENT_WIDGET`]);
    },
    onError: ErrorHandler,
  });
};

const ReScheduleClientAppointment = (body) => {
  const method = "PUT";
  const url = `api/coach/client-appointment`;
  return useAPI({
    method,
    url,
    body,
  });
};
export const ReScheduleClientAppointmentQuery = (payload) => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  return useMutation(ReScheduleClientAppointment, {
    onSuccess: (response) => {
      responseToast.toastSuccess(
        response.message || "Appointment Created Successfully"
      );
      queryClient.invalidateQueries(`FETCH_COACH_APPOINTMENT`);
      queryClient.invalidateQueries([`CLIENT_APPOINTMENTS`]);
      queryClient.invalidateQueries("PENDING_APPOINTMENTS");
      queryClient.invalidateQueries("FETCH_COACH_DUE_AMOUNT");
      queryClient.invalidateQueries([`CLIENT_APPOINTMENT_WIDGET`]);
      queryClient.invalidateQueries([`FAMILY_APPOINTMENTS`]);
    },
    onError: ErrorHandler,
  });
};

export const FetchClientAppointmentsQuery = (payload) => {
  return useQuery(
    [`CLIENT_APPOINTMENTS`, payload?.uuid],
    () => FetchClientAppointments(payload),
    {
      enabled: Boolean(payload?.uuid),
      onSuccess: () => {},
      onError: ErrorHandler,
    }
  );
};

export const FetchClientAppointmentWidgetQuery = (payload) => {
  return useQuery(
    [`CLIENT_APPOINTMENT_WIDGET`, payload?.uuid],
    () => FetchClientAppointmentWidget(payload),
    {
      enabled: Boolean(payload?.uuid),
      onSuccess: () => {},
      onError: ErrorHandler,
    }
  );
};
export const FetchClientPendingAppointmentsQuery = (payload) => {
  return useQuery(
    [`PENDING_APPOINTMENTS`, payload?.id, payload?.typeId],
    () => FetchClientPendingAppointments(payload),
    {
      enabled: Boolean(payload?.id),
      onSuccess: () => {},
      onError: ErrorHandler,
    }
  );
};

export const UpdateClientAppointmentQuery = () => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  return useMutation(UpdateClientAppointment, {
    onSuccess: (response) => {
      responseToast.toastSuccess(
        response.message || "Appointment Updated Successfully"
      );
      queryClient.invalidateQueries([`PENDING_APPOINTMENTS`]);
      queryClient.invalidateQueries([`CLIENT_APPOINTMENT_WIDGET`]);
      queryClient.invalidateQueries([`FETCH_COACH_APPOINTMENT`]);
      queryClient.invalidateQueries([`FAMILY_APPOINTMENTS`]);
    },
    onError: ErrorHandler,
  });
};

export const FetchGroupsQuery = (payload) => {
  return useQuery(["GROUPS_LIST", payload], () => FetchGroups(payload), {
    enabled: true,
    onSuccess: () => {},
    onError: ErrorHandler,
  });
};
export const CreateGroupQuery = () => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  return useMutation(CreateGroup, {
    onSuccess: (response) => {
      responseToast.toastSuccess(
        response.message || "Group Created Successfully"
      );
      queryClient.invalidateQueries("GROUPS_LIST");
    },
    onError: ErrorHandler,
  });
};
export const FetchClientNotesQuery = (payload) => {
  return useQuery(
    [`CLIENT_NOTES_${payload?.uuid}`, payload?.uuid],
    () => FetchClientNotes(payload),
    {
      enabled: Boolean(payload?.uuid),
      onSuccess: () => {},
      onError: ErrorHandler,
    }
  );
};
export const FetchClientNoteByIDQuery = (id) => {
  return useQuery([`CLIENT_NOTE_${id}`, id], () => FetchClientNoteByID(id), {
    onSuccess: () => {},
    onError: ErrorHandler,
  });
};
export const CreateClientNotesQuery = () => {
  const responseToast = useToast();

  return useMutation(CreateClientNotes, {
    onSuccess: (response) => {
      responseToast.toastSuccess(
        response.message || "Client Notes Created Successfully"
      );
    },
    onError: ErrorHandler,
  });
};
export const UpdateClientNoteQuery = () => {
  const responseToast = useToast();
  return useMutation(UpdateClientNote, {
    onSuccess: (response) => {
      responseToast.toastSuccess(
        response.message || "Client Notes Updated Successfully"
      );
    },
    onError: ErrorHandler,
  });
};
export const DeleteClientNoteQuery = () => {
  const responseToast = useToast();
  return useMutation(DeleteClientNote, {
    onSuccess: (response) => {
      responseToast.toastSuccess(
        response.message || "Client Notes Deleted Successfully"
      );
    },
    onError: ErrorHandler,
  });
};
export const FetchClientGoalsByUUIDQuery = (payload) => {
  return useQuery(
    [`CLIENT_GOALS`, payload?.uuid],
    () => FetchClientGoalsByUUID(payload),
    {
      enabled: Boolean(payload?.uuid),
      onSuccess: () => {},
      onError: ErrorHandler,
    }
  );
};

export const InviteClientQuery = () => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  return useMutation(InviteClient, {
    onSuccess: (response) => {
      responseToast.toastSuccess("Client invited");
      queryClient.invalidateQueries("CLIENTS_LIST");
      queryClient.invalidateQueries([`CLIENT_INVITES`]);
      queryClient.invalidateQueries([`CLIENT_NON_REGISTERED`]);
    },
    onError: ErrorHandler,
  });
};

export const AddFamilyMemberQuery = () => {
  const responseToast = useToast();
  return useMutation(AddFamilyMember, {
    onSuccess: (response) => {
      responseToast.toastSuccess("Client invited");
    },
    onError: ErrorHandler,
  });
};

export const FetchClientHabitsQuery = (payload) => {
  return useQuery(
    [
      `CLIENT_HABITS_${payload?.user_id}`,
      payload?.user_id,
      payload?.period_type,
    ],
    () => FetchClientHabits(payload),
    {
      enabled: Boolean(payload?.user_id),
      onSuccess: () => {},
      onError: ErrorHandler,
    }
  );
};

const FetchClientGoalHabits = (body) => {
  const method = "GET";
  const url = `api/coach/link-habits`;
  return useAPI({
    method,
    url,
    params: body,
  });
};
export const FetchClientGoalHabitsQuery = (payload) => {
  return useQuery(
    [`CLIENT_LINKED_HABITS_${payload?.user_id || payload?.family_id}`, payload],
    () => FetchClientGoalHabits(payload),
    {
      enabled: Boolean(payload?.user_id) || Boolean(payload?.family_id),
      onSuccess: () => {},
      onError: ErrorHandler,
    }
  );
};
export const FetchClientCoursesQuery = (payload) => {
  return useQuery(
    [`CLIENT_COURSES_${payload?.id}`, payload?.id],
    () => FetchClientCourses(payload),
    {
      enabled: Boolean(payload?.id),
      onSuccess: () => {},
      onError: ErrorHandler,
    }
  );
};
export const FetchClientCheckinsQuery = (payload) => {
  return useQuery(
    [
      `CLIENT_CHECKINS_${payload?.user_id}`,
      payload?.user_id,
      payload?.from,
      payload?.to,
    ],
    () => FetchClientCheckins(payload),
    {
      enabled: Boolean(payload?.user_id),
      onSuccess: () => {},
      onError: ErrorHandler,
    }
  );
};
export const FetchClientCheckinsGad7Query = (payload) => {
  return useQuery(
    [`CLIENT_CHECKINS_GAD7_${payload?.user_id}`, payload?.user_id],
    () => FetchClientCheckinsGad7(payload),
    {
      enabled: Boolean(payload?.user_id),
      onSuccess: () => {},
      onError: ErrorHandler,
    }
  );
};
export const FetchClientCheckinsPhq9Query = (payload) => {
  return useQuery(
    [`CLIENT_CHECKINS_GAD7_BY_DATE_${payload?.user_id}`, payload?.user_id],
    () => FetchClientCheckinsPhq9(payload),
    {
      enabled: Boolean(payload?.user_id),
      onSuccess: () => {},
      onError: ErrorHandler,
    }
  );
};
export const FetchGroupByIDQuery = (id) => {
  return useQuery([`GROUP_BY_ID_${id}`, id], () => FetchGroupByID(id), {
    enabled: false,
    onSuccess: () => {},
    onError: ErrorHandler,
  });
};

export const UpdateGroupByIDQuery = () => {
  const queryClient = useQueryClient();
  const responseToast = useToast();
  return useMutation(UpdateGroupByID, {
    onSuccess: (response) => {
      responseToast.toastSuccess(
        response.message || "Group Updated Successfully"
      );
      queryClient.invalidateQueries("GROUPS_LIST");
    },
    onError: ErrorHandler,
  });
};
export const FetchClientJournalsQuery = (payload) => {
  return useQuery(
    [`CLIENT_JOURNALS_${payload?.uuid}`, payload?.uuid],
    () => FetchClientJournals(payload),
    {
      enabled: Boolean(payload?.uuid),
      onSuccess: () => {},
      onError: ErrorHandler,
    }
  );
};
export const RemoveClientAppointmentQuery = () => {
  const queryClient = useQueryClient();
  const responseToast = useToast();
  return useMutation(RemoveClientAppointment, {
    onSuccess: (response) => {
      responseToast.toastSuccess(
        response.message || "Appointment Removed Successfully"
      );

      queryClient.invalidateQueries([`FAMILY_APPOINTMENTS`]);
      queryClient.invalidateQueries([`CLIENT_APPOINTMENTS`]);
      queryClient.invalidateQueries("PENDING_APPOINTMENTS");
      queryClient.invalidateQueries([`CLIENT_APPOINTMENT_WIDGET`]);
    },
    onError: ErrorHandler,
  });
};
export const FetchClientCheckinsGraphQuery = (payload) => {
  return useQuery(
    [`CLIENT_CHECKINS_GRAPH_${payload?.user_id}`, payload?.user_id],
    () => FetchClientCheckinsGraph(payload),
    {
      enabled: Boolean(payload?.user_id),
      onSuccess: () => {},
      onError: ErrorHandler,
    }
  );
};
export const FetchClientCheckinsGad7GraphQuery = (payload) => {
  return useQuery(
    [`CLIENT_CHECKINS_GAD7_GRAPH_${payload?.user_id}`, payload?.user_id],
    () => FetchClientCheckinsGad7Graph(payload),
    {
      enabled: Boolean(payload?.user_id),
      onSuccess: (response) => {},
      onError: ErrorHandler,
    }
  );
};
export const FetchClientCheckinsPhq9GraphQuery = (payload) => {
  return useQuery(
    [`CLIENT_CHECKINS_PHQ9_GRAPH_${payload?.user_id}`, payload?.user_id],
    () => FetchClientCheckinsPhq9Graph(payload),
    {
      enabled: Boolean(payload?.user_id),
      onSuccess: (response) => {},
      onError: ErrorHandler,
    }
  );
};

export const FetchClientInviteesQuery = (payload) => {
  return useQuery(
    [`CLIENT_INVITES`, payload],
    () => FetchClientInvitees(payload),
    {
      enabled: Boolean(payload.status),
      onSuccess: () => {},
      onError: ErrorHandler,
    }
  );
};

const UpdateClientProfile = (body) => {
  const method = "PUT";
  const url = `api/coach/invites`;
  return useAPI({
    method,
    url,
    body,
  });
};
export const UpdateClientProfileQuery = () => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  return useMutation(UpdateClientProfile, {
    onSuccess: (response) => {
      responseToast.toastSuccess(
        response.message || " Update Client Profile Successfully"
      );
      queryClient.invalidateQueries("CLIENTS_LIST");
    },
    onError: ErrorHandler,
  });
};

const AddGoal = (payload) => {
  return useAPI({
    method: "POST",
    url: "api/coach/client-goal",
    body: payload,
  });
};

export const AddGoalQuery = () => {
  const responseToast = useToast();
  const queryClient = useQueryClient();

  return useMutation(AddGoal, {
    onSuccess: (response) => {
      queryClient.invalidateQueries([`CLIENT_GOALS`]);
      responseToast.toastSuccess("Goal created successfully!");
    },
    onError: ErrorHandler,
  });
};
const CoachDocumentUpload = (body) => {
  const method = "POST";
  const url = `api/coach/documents/upload`;
  const formData = new FormData();
  formData.append("document", body?.file);
  formData.append("name", body?.name);
  formData.append("user_id", body?.user_id);
  formData.append("emergency_contact_email", body?.emergency_contact_email);
  formData.append("parent_email", body?.parent_email);
  if (body?.via && Array.isArray(body.via)) {
    formData.append("via[]", body.via[0]);
    formData.append("via[]", body.via[1]);
  }

  return useAPI({
    method,
    url,
    body: formData,
    contentType: "multipart/form-data",
  });
};
export const CoachDocumentUploadQuery = () => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  return useMutation(CoachDocumentUpload, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("SEND_DOCUMENT");
      queryClient.invalidateQueries("PEND_DOCUMENT");
      responseToast.toastSuccess(response.message || "Send Successfully");
    },
    onError: ErrorHandler,
  });
};

const FetchTemplateList = () => {
  const method = "GET";
  const url = `api/coach/client/notes/template`;
  return useAPI({
    method,
    url,
  });
};
export const FetchTemplateListQuery = (payload) => {
  return useQuery([`TEMPLATE_LIST`], () => FetchTemplateList(payload), {
    enabled: true,
    onSuccess: () => {},
    onError: ErrorHandler,
  });
};

const ClientNoteUpload = (body) => {
  const method = "POST";
  const url = `api/coach/client-progress-notes-document`;
  const formData = new FormData();
  formData.append("document", body);
  return useAPI({
    method,
    url,
    body: formData,
    contentType: "multipart/form-data",
  });
};
export const ClientNoteUploadQuery = () => {
  return useMutation(ClientNoteUpload, {
    onSuccess: (response) => {},
    onError: ErrorHandler,
  });
};

const ErrorHandler = (error) => {
  const responseToast = useToast();
  Sentry.captureException(error);
  if (error?.errors) {
    responseToast.toastError("Something went wrong!", error.errors);
  } else if (error?.message) {
    responseToast.toastError(error.message);
  }
};

const FetchFamilyHabits = (body) => {
  const method = "POST";
  const url = `api/coach/family/habits`;
  return useAPI({
    method,
    url,
    body,
  });
};

export const FetchFamilyHabitsQuery = (payload) => {
  return useQuery(
    [
      `FAMILY_HABITS_${payload?.family_id}`,
      payload?.family_id,
      payload?.period_type,
    ],
    () => FetchFamilyHabits(payload),
    {
      enabled: Boolean(payload?.family_id),
      onSuccess: () => {},
      onError: ErrorHandler,
    }
  );
};

const FetchFamilyCourses = (body) => {
  const method = "POST";
  const url = `api/coach/family/courses`;
  return useAPI({
    method,
    url,
    body,
  });
};

export const FetchFamilyCoursesQuery = (payload) => {
  return useQuery(
    [`FAMILY_COURSES_${payload?.family_id}`, payload?.family_id],
    () => FetchFamilyCourses(payload),
    {
      enabled: Boolean(payload?.family_id),
      onSuccess: () => {},
      onError: ErrorHandler,
    }
  );
};

const FetchFamilyGoalsByID = (body) => {
  const method = "GET";
  const url = `api/coach/client-goals?family_id=${body.family_id}`;
  return useAPI({
    method,
    url,
  });
};

export const FetchFamilyGoalsByIDQuery = (payload) => {
  return useQuery(
    [`FAMILY_GOALS`, payload?.family_id],
    () => FetchFamilyGoalsByID(payload),
    {
      enabled: Boolean(payload?.family_id),
      onSuccess: () => {},
      onError: ErrorHandler,
    }
  );
};

const FetchFamilyNotesByID = (body) => {
  const method = "GET";
  const url = `api/coach/client-notes?family_id=${body.family_id}`;
  return useAPI({
    method,
    url,
  });
};

export const FetchFamilyNotesByIDQuery = (payload) => {
  return useQuery(
    [`FAMILY_NOTES_${payload?.family_id}`, payload?.family_id],
    () => FetchFamilyNotesByID(payload),
    {
      enabled: Boolean(payload?.family_id),
      onSuccess: () => {},
      onError: ErrorHandler,
    }
  );
};

const FetchFamilyPendingNotesByID = (body) => {
  const method = "GET";
  const url = `api/coach/client-notes/pending/sessions?family_id=${body.family_id}`;
  return useAPI({
    method,
    url,
  });
};

export const FetchFamilyPendingNotesByIDQuery = (payload) => {
  return useQuery(
    [`FAMILY_PENDING_NOTES_${payload?.family_id}`, payload?.family_id],
    () => FetchFamilyPendingNotesByID(payload),
    {
      enabled: Boolean(payload?.family_id),
      onSuccess: () => {},
      onError: ErrorHandler,
    }
  );
};

const FetchClientPendingNotesByID = (body) => {
  const method = "GET";
  const url = `api/coach/client-notes/pending/sessions?uuid=${body.uuid}`;
  return useAPI({
    method,
    url,
  });
};

export const FetchClientPendingNotesByIDQuery = (payload) => {
  return useQuery(
    [`CLIENT_PENDING_NOTES_${payload?.uuid}`, payload?.uuid],
    () => FetchClientPendingNotesByID(payload),
    {
      enabled: Boolean(payload?.uuid),
      onSuccess: () => {},
      onError: ErrorHandler,
    }
  );
};

const FetchFamilyDailyPrompts = (body) => {
  const method = "GET";
  const url = `api/coach/user/daily-practices?family_id=${
    body?.family_id
  }&page=${body?.page}&perpage=${10}`;
  return useAPI({
    method,
    url,
  });
};

export const FetchFamilyDailyPromptsQuery = (payload) => {
  return useQuery(
    [`FAMILY_DAILY_PROMPTS`, payload],
    () => FetchFamilyDailyPrompts(payload),
    {
      enabled: Boolean(payload?.family_id && payload?.page),
      onSuccess: () => {},
      onError: () => {},
    }
  );
};

const FetchFamilyJournals = (body) => {
  const method = "GET";
  const url = `api/coach/client/journals?family_id=${body.family_id}`;
  return useAPI({
    method,
    url,
  });
};

export const FetchFetchFamilyJournalsQuery = (payload) => {
  return useQuery(
    [`FAMILY_JOURNALS`, payload],
    () => FetchFamilyJournals(payload),
    {
      enabled: Boolean(payload?.family_id),
      onSuccess: () => {},
      onError: () => {},
    }
  );
};

const AddFamilyGoal = (payload) => {
  return useAPI({
    method: "POST",
    url: "api/coach/family/goal",
    body: payload,
  });
};

export const AddFamilyGoalQuery = () => {
  const responseToast = useToast();
  const queryClient = useQueryClient();

  return useMutation(AddFamilyGoal, {
    onSuccess: (response) => {
      queryClient.invalidateQueries([`FAMILY_GOALS`]);
      responseToast.toastSuccess("Goal created successfully!");
    },
    onError: ErrorHandler,
  });
};

const FetchPendingFamilyDocuments = () => {
  const method = "GET";
  const url = `api/coach/family/documents/pending `;
  return useAPI({
    method,
    url,
  });
};

export const FetchFamilyPendingDocumentsQuery = (payload) => {
  return useQuery(
    [`PEND_DOCUMENT_FAMILY`, payload?.id],
    FetchPendingFamilyDocuments,
    {
      enabled: payload?.id ? true : false,
      onSuccess: () => {},
      onError: ErrorHandler,
    }
  );
};

const FetchFamilySendDocuments = (body) => {
  const method = "GET";
  const url = `api/coach/family/${body?.id}/documents`;
  return useAPI({
    method,
    url,
    body,
  });
};

export const FetchFamilySendDocumentsQuery = (payload) => {
  return useQuery(
    [`SEND_DOCUMENT_FAMILY`, payload?.id],
    () => FetchFamilySendDocuments(payload),
    {
      enabled: Boolean(payload?.id),
      onSuccess: () => {},
      onError: ErrorHandler,
    }
  );
};

const CreateFamilyAppointment = (body) => {
  delete body.uuid;
  const method = "POST";
  const url = `api/coach/family/client-appointment`;
  return useAPI({
    method,
    url,
    body,
  });
};

export const CreateFamilyAppointmentQuery = (payload) => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  return useMutation(CreateFamilyAppointment, {
    onSuccess: (response) => {
      /*
      Need to check whether we have to invalidate the queries or families or these are working fine
      */
      responseToast.toastSuccess("Appointment Scheduled");
      queryClient.invalidateQueries(`FETCH_COACH_APPOINTMENT`);
      queryClient.invalidateQueries([`CLIENT_APPOINTMENTS`]);
      queryClient.invalidateQueries("PENDING_APPOINTMENTS");
      queryClient.invalidateQueries("FETCH_COACH_DUE_AMOUNT");
      queryClient.invalidateQueries([`CLIENT_APPOINTMENT_WIDGET`]);
      queryClient.invalidateQueries([`FAMILY_APPOINTMENTS`]);
    },
    onError: ErrorHandler,
  });
};

const CoachFamilyDocumentUpload = (body) => {
  const method = "POST";
  const url = `api/coach/family/documents/upload`;
  const formData = new FormData();
  formData.append("document", body?.file);
  formData.append("name", body?.name);
  formData.append("user_id[]", body?.user_id);
  if (body?.via && Array.isArray(body.via)) {
    formData.append("via[]", body.via[0]);
    formData.append("via[]", body.via[1]);
  }

  return useAPI({
    method,
    url,
    body: formData,
    contentType: "multipart/form-data",
  });
};

export const CoachFamilyDocumentUploadQuery = () => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  return useMutation(CoachFamilyDocumentUpload, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("PEND_DOCUMENT_FAMILY");
      queryClient.invalidateQueries("SEND_DOCUMENT_FAMILY");
      responseToast.toastSuccess(response.message || "Send Successfully");
    },
    onError: ErrorHandler,
  });
};

const FetchFamilyAppointments = (body) => {
  const method = "GET";
  const url = `api/coach/family/client-appointment?family_id=${body.id}`;
  return useAPI({
    method,
    url,
  });
};

export const FetchFamilyAppointmentsQuery = (payload, calledfrom = "") => {
  return useQuery(
    [`FAMILY_APPOINTMENTS`, payload?.id],
    () => FetchFamilyAppointments(payload),
    {
      enabled: Boolean(payload?.id),
      onSuccess: () => {},
      onError: ErrorHandler,
    }
  );
};
