import { useState, useEffect, useRef } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormHelperText,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import { CustomSelectField, CustomTextField } from "components";
import {
  AddCircleOutlineOutlined,
  Close as CloseIcon,
} from "@mui/icons-material";
import { useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FetchCoachServicesQuery,
  FetchInsuranceCodeQuery,
  FetchProfileDetailsQuery,
} from "Pages/Profile/ProfileQueries";
import { InviteClientQuery } from "./ClientQueries";
import SubscriptionModal from "Pages/Profile/components/SubscriptionModal";
import InviteClientUserForm from "./InviteClientUserForm";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useToast } from "services";
import EmergencyContactForm from "./EmergencyContactForm";
import AddEditServiceModal from "Pages/Profile/components/AddEditServiceModal";
function removeNullProperties(obj) {
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    const hasProperties = value && Object.keys(value).length > 0;
    if (value === null || value === undefined || value === "") {
      delete obj[key];
    } else if (typeof value !== "string" && hasProperties) {
      removeNullProperties(value);
    }
  });
  return obj;
}

const clientFormSchema = yup.object().shape({
  client_type: yup.string().required("Client type is required"),
  service: yup.object().nullable(),
  is_free: yup.boolean(),
  isFree: yup.boolean(),
  users: yup
    .array()
    .of(
      yup.object().shape({
        first_name: yup.string().required("First name is required"),
        last_name: yup.string().required("Last name is required"),
        email: yup
          .string()
          .email("Email must be a valid email")
          .required("Email is required"),
        phone: yup.string().required("Phone is required"),
        street: yup.string().required("Street is required"),
        city: yup.string().required("City is required"),
        state: yup.string().required("State is required"),
        zip_code: yup.string().required("Zip code is required"),
        is_primary: yup.boolean(),
      })
    )
    .when("client_type", {
      is: "couple",
      then: (schema) =>
        schema.test(
          "minLength",
          "Two users are required",
          (value) => value && value.length === 2
        ),
      otherwise: (schema) =>
        schema.when("client_type", {
          is: "family",
          then: (schema) =>
            schema.test(
              "minLength",
              "At least two users are required",
              (value) => value && value.length >= 2
            ),
          otherwise: (schema) =>
            schema.test(
              "minLength",
              "At least one user is required",
              (value) => value && value.length === 1
            ),
        }),
    }),
  parent_firstname: yup
    .string()
    .nullable()
    .when("client_type", {
      is: "child",
      then: (schema) => schema.required("Parent firstname is required"),
    }),
  parent_lastname: yup
    .string()
    .nullable()
    .when("client_type", {
      is: "child",
      then: (schema) => schema.required("Parent lastname is required"),
    }),
  parent_email: yup
    .string()
    .email("Email must be a valid email")
    .nullable()
    .when("client_type", {
      is: "child",
      then: (schema) => schema.required("Parent email is required"),
    }),
  parent_phone: yup
    .string()
    .nullable()
    .when("client_type", {
      is: "child",
      then: (schema) => schema.required("Parent phone is required"),
    }),
  emergency_firstname: yup.string().nullable(),
  emergency_lastname: yup.string().nullable(),
  emergency_email: yup.string().email("Email must be a valid email").nullable(),
  emergency_phone: yup.string().nullable(),
});

const clientTypeOptions = [
  {
    id: 1,
    label: "Adult",
    value: "adult",
  },
  {
    id: 2,
    label: "Child",
    value: "child",
  },
  {
    id: 3,
    label: "Couple",
    value: "couple",
  },
  {
    id: 4,
    label: "Family",
    value: "family",
  },
];

const reminderOptions = [
  {
    id: 1,
    label: "Email",
    value: "email",
    checked: false,
  },

  {
    id: 2,
    label: "App",
    value: "app",
    checked: false,
  },
];

const AddClientModal = (props) => {
  const { data: profileDetails } = FetchProfileDetailsQuery();

  const isSubscriptionActive = profileDetails?.data?.subscribed;

  const { open, handleClose, clientRequestEmail } = props;
  const divRef = useRef(null);
  const { toastError } = useToast();
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    watch,
    setError,
    register,
    clearErrors,
  } = useForm({
    resolver: yupResolver(clientFormSchema),
    defaultValues: {
      client_type: "",
      service: "",
      name: "",
      users: [],
      address: "",
      reminder: [],
      isFree: false,
    },
  });

  const { users } = watch();
  const client_type = useWatch({
    control,
    name: "client_type",
  });

  const selectedService = useWatch({
    control,
    name: "service",
  });

  const isFree = useWatch({
    control,
    name: "isFree",
  });

  //states
  const [reminder, setReminder] = useState(reminderOptions);
  const [editIndex, setEditIndex] = useState(undefined);
  const [subscriptionMessageModal, setSubscriptionMessageModal] =
    useState(false);
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [addModalService, setAddModalService] = useState(false);
  //queries
  const { mutateAsync: InviteClient } = InviteClientQuery();
  const { data: coachServicesData, dataUpdatedAt } = FetchCoachServicesQuery();
  const { data: insuranceCodeList } = FetchInsuranceCodeQuery();

  //handler
  const handleClientTypeChange = (event, value) => {
    reset();
    handleSetValue("client_type", value);
    handleSetValue("service", {
      id: coachServicesData?.data?.[0]?.id,
      label: coachServicesData?.data?.[0]?.name,
      value: coachServicesData?.data?.[0]?.name,
    });
  };

  const handleReminderTypeChange = (id, status) => {
    const newReminder = reminder?.map((item) => {
      if (item.id === id) {
        return { ...item, checked: status };
      }
      return item;
    });

    setReminder(newReminder);
  };
  const handleSetValue = (key, value) => {
    setValue(key, value, { shouldValidate: true });
  };

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission on Enter key press
    }
  };

  const handleClientFormSubmit = (formData, event) => {
    formData.set_reminder = formData.reminder;
    delete formData.reminder;
    if (!formData.phone) {
      delete formData.phone;
    }
    formData.free = formData.isFree ? 1 : 0;
    delete formData.isFree;
    formData.service_id = formData.service?.id;
    formData.service = formData.service.value;

    if (formData.service === "" || !formData.service_id) {
      setError("service", { message: "Service is required" });
      return;
    }

    if (formData.set_reminder === "") {
      toastError("Alteast one Invitation Reminder source should be selected");
      return;
    }

    if (!isSubscriptionActive) {
      setSubscriptionMessageModal(true);
      return;
    }

    formData?.users?.forEach((item) => {
      item["phone"] = item.phone?.replace(/[^A-Z0-9]+/gi, "");
    });
    InviteClient(removeNullProperties(formData)).then((res) => {
      if (
        res.message === "Please buy subscription first to access this feature!"
      )
        setSubscriptionMessageModal(true);
      else {
        reset();
        handleClose();
      }
    });
  };
  const handleChangeService = (e) => {
    handleSetValue("service", e.target.value);
  };

  //useEffects
  useEffect(() => {
    handleSetValue(
      "reminder",
      reminder
        ?.filter((item) => item.checked)
        ?.map((item) => item.value)
        .join(",")
    );
  }, [reminder]);

  useEffect(() => {
    if (clientRequestEmail) {
      setValue("email", clientRequestEmail);
    }
  }, [clientRequestEmail]);

  useEffect(() => {
    if (dataUpdatedAt || open) {
      handleSetValue("service", {
        id: coachServicesData?.data?.[0]?.id,
        label: coachServicesData?.data?.[0]?.name,
        value: coachServicesData?.data?.[0]?.name,
      });
    }
  }, [dataUpdatedAt, open]);

  const handleSubscriptionModalClose = () => {
    setSubscriptionModal(false);
    setCurrentStep(1);
  };

  const handleParentFormData = (user, index, reset) => {
    clearErrors("users");
    if (users.length === 0) {
      user.is_primary = true;
    }
    if (users.length === 1 && index !== undefined) {
      user.is_primary = true;
    }

    let newUsers = [...users];

    if (user.is_primary) {
      newUsers = users.map((item) => {
        return { ...item, is_primary: false };
      });
    }
    if (index !== undefined) {
      newUsers[index] = user;
      setValue("users", newUsers);
    } else {
      setValue("users", [...newUsers, user]);
    }
    reset();
    setEditIndex(undefined);
  };

  const handleViewClick = (index) => {
    setEditIndex(index);
    if (divRef.current) {
      divRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };

  const showUserForm = () => {
    if (client_type === "adult" || client_type === "child") {
      return users?.[0] && editIndex === undefined ? false : true;
    }
    if (client_type === "couple") {
      return users.length >= 2 && editIndex === undefined ? false : true;
    }
    return true;
  };

  const handleRemove = (index) => {
    const newUsers = [...users];
    newUsers.splice(index, 1);
    if (users[index].is_primary && newUsers?.[0]) {
      newUsers[0].is_primary = true;
    }
    setValue("users", newUsers);
  };

  useEffect(() => {
    setValue("client_type", clientTypeOptions[0]?.value);
  }, []);

  return (
    <>
      <AddEditServiceModal
        open={addModalService}
        insuranceCodeList={insuranceCodeList?.data}
        handleClose={() => setAddModalService(false)}
      ></AddEditServiceModal>

      <SubscriptionModal
        open={subscriptionModal}
        handleClose={handleSubscriptionModalClose}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
      />
      <Dialog
        open={subscriptionMessageModal}
        onClose={() => setSubscriptionMessageModal(false)}
      >
        <DialogContent>
          <Typography variant="body2" className="text-base font-medium">
            Subscribe to CareBetter Professional to:
          </Typography>
          <ul
            style={{
              listStyle: "disc",
              marginLeft: "2rem",
            }}
            className="my-4 "
          >
            <li className="mt-1">
              <Typography variant="body2">
                Create and Assign habits/coping skills
              </Typography>
            </li>
            <li className="mt-1">
              <Typography variant="body2">
                Create and Assign Courses & journal Prompts
              </Typography>
            </li>
            <li className="mt-1">
              <Typography variant="body2">
                Create and Assign Check-Ins
              </Typography>
            </li>
            <li className="mt-1">
              <Typography variant="body2">Send messages</Typography>
            </li>
            <li className="mt-1">
              <Typography variant="body2">
                Schedule Appointments, Send invoices and get paid!
              </Typography>
            </li>
          </ul>

          <Button
            fullWidth
            variant="contained"
            sx={{ background: "#FF6C44", mt: 2 }}
            onClick={() => {
              setSubscriptionModal(true);
              setSubscriptionMessageModal(false);
            }}
          >
            Subscribe Now
          </Button>
        </DialogContent>
      </Dialog>
      <div>
        <Dialog open={open} fullWidth maxWidth="sm">
          <DialogTitle className="font-semibold" color="primary">
            <Grid
              container
              xs={12}
              direction="row"
              className="justify-between"
              alignItems="center"
            >
              INVITE CLIENT
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <form
              id="create-client-form"
              onSubmit={handleSubmit(handleClientFormSubmit)}
            >
              <div className="flex flex-col space-y-4 px-2">
                <div>
                  <FormControl required error={Boolean(errors?.client_type)}>
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      className="text-base text-hb_blue-800"
                    >
                      CLIENT TYPE
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="client-type-radio-group"
                      className="ml-6"
                      value={client_type || ""}
                      onChange={handleClientTypeChange}
                    >
                      {clientTypeOptions?.map((option) => (
                        <FormControlLabel
                          key={option.id}
                          value={option.value}
                          control={
                            <Radio
                              sx={{
                                color: "#FF6C44",
                                "&.Mui-checked": {
                                  color: "#FF6C44",
                                },
                              }}
                              onKeyDown={onKeyDown}
                            />
                          }
                          label={option.label}
                        />
                      ))}
                    </RadioGroup>
                    {errors.client_type && (
                      <FormHelperText className="relative -left-3">
                        {errors.client_type.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
                <div className="my-4">
                  <Grid container spacing={1}>
                    <Grid item xs={11}>
                      <CustomSelectField
                        variant="standard"
                        required
                        options={
                          coachServicesData?.data?.map((item) => {
                            return {
                              id: item.id,
                              label: item.name,
                              value: item.name,
                            };
                          }) || []
                        }
                        value={selectedService}
                        onChange={handleChangeService}
                        fullWidth={true}
                        label={
                          !coachServicesData?.data?.length
                            ? "Add a service to enable this field."
                            : "Default Service"
                        }
                        error={Boolean(errors.service)}
                        helperText={errors.service && errors.service.message}
                        onKeyDown={onKeyDown}
                        disabled={!coachServicesData?.data?.length}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        color="primary"
                        aria-label="add-client"
                        component="span"
                        style={{ height: "100%" }}
                        onClick={() => setAddModalService(true)}
                        id="client-filter-button"
                      >
                        <AddCircleOutlineOutlined></AddCircleOutlineOutlined>
                      </IconButton>
                    </Grid>
                  </Grid>
                </div>
                <>
                  {users?.[0] &&
                    users?.map((user, index) => (
                      <>
                        <div className="bg-gray-100 p-5">
                          <div>{`${user.first_name} ${user.last_name}`}</div>
                          <div>{`${user.email}, ${user.phone}`}</div>
                          <div>{`${user.street}, ${user.city}, ${user.state}, ${user.zip_code}`}</div>
                          <div className="flex justify-end gap-2">
                            <div className="pt-3">
                              {user.is_primary &&
                                (client_type === "couple" ||
                                  client_type === "family") && (
                                  <>
                                    <CheckCircleIcon /> Primary{" "}
                                  </>
                                )}
                            </div>
                            <Button
                              sx={{ color: "#FF6C44" }}
                              variant="text"
                              onClick={() => handleViewClick(index)}
                            >
                              Edit
                            </Button>

                            <Button
                              sx={{ color: "#FF6C44" }}
                              variant="text"
                              onClick={() => handleRemove(index)}
                            >
                              Remove
                            </Button>
                          </div>
                        </div>
                      </>
                    ))}
                </>
                {showUserForm() && (
                  <InviteClientUserForm
                    handleParentFormData={handleParentFormData}
                    editIndex={editIndex}
                    divRef={divRef}
                    values={
                      editIndex !== undefined
                        ? users[editIndex]
                        : {
                            is_primary: false,
                          }
                    }
                    clientType={client_type}
                    onKeyDown={onKeyDown}
                  />
                )}

                <FormHelperText className="ml-0" error={errors?.users}>
                  {errors?.users?.message}
                </FormHelperText>

                {(client_type === "adult" || client_type === "child") && (
                  <>
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      className="text-base text-hb_blue-800"
                    >
                      Parent / Guardian Contact
                    </FormLabel>

                    <EmergencyContactForm
                      inputKey="parent"
                      register={register}
                      errors={errors}
                      control={control}
                      onKeyDown={onKeyDown}
                      clientType={client_type}
                    />
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      className="text-base text-hb_blue-800"
                    >
                      Emergency Contact
                    </FormLabel>

                    <EmergencyContactForm
                      inputKey="emergency"
                      register={register}
                      errors={errors}
                      control={control}
                      onKeyDown={onKeyDown}
                      clientType={client_type}
                    />
                  </>
                )}

                <div className="my-4 pt-6">
                  <FormControl
                    component="fieldset"
                    error={Boolean(errors?.reminder)}
                  >
                    <FormLabel
                      component="legend"
                      className="text-base text-hb_blue-800"
                      style={
                        Boolean(errors.reminder) ? { color: "#d32f2f" } : {}
                      }
                    >
                      Send Invitation Reminder *
                    </FormLabel>
                    <FormGroup aria-label="position" row className="px-4 pt-4">
                      {reminderOptions?.map((option) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              onKeyDown={onKeyDown}
                              onChange={(e) => {
                                handleReminderTypeChange(
                                  option.id,
                                  e.target.checked
                                );
                              }}
                              sx={{
                                "& .MuiSvgIcon-root": { fontSize: 28 },
                                "&.Mui-checked": {
                                  color: "#FF6C44",
                                },
                              }}
                            />
                          }
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "1rem",
                              color: "#264265B2",
                            },
                          }}
                          label={option.label}
                          labelPlacement="end"
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </div>
                <div className="my-4 inline">
                  <div className="flex items-center space-x-4">
                    <p className="my-6 text-base text-hb_blue-800">
                      Provide client free access to the mobile app ?
                    </p>
                    <Checkbox
                      onKeyDown={onKeyDown}
                      onChange={(e) => {
                        handleSetValue("isFree", e.target.checked);
                      }}
                      checked={isFree}
                      sx={{
                        "& .MuiSvgIcon-root": { fontSize: 28 },
                        "&.Mui-checked": {
                          color: "#FF6C44",
                        },
                      }}
                    />
                  </div>
                </div>

                <div className="pt-8 pb-4">
                  <Button
                    variant="contained"
                    className="w-full"
                    sx={{ background: "#FF6C44" }}
                    type="submit"
                  >
                    Invite Client
                  </Button>
                </div>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default AddClientModal;
