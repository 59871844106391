import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAPI, useToast } from "services";
import * as Sentry from "@sentry/react";

const CourseImageUpload = (body) => {
  const method = "POST";
  const url = `api/coach/upload/course/image`;
  const formData = new FormData();
  formData.append("image", body?.file);
  return useAPI({
    method,
    url,
    body: formData,
    contentType: "multipart/form-data",
  });
};
const FetchTags = () => {
  const method = "GET";
  const url = `api/coach/tags`;
  return useAPI({ method, url });
};
const FetchCategories = () => {
  const method = "GET";
  const url = `api/coach/category`;
  return useAPI({ method, url });
};
const FetchListOfClients = (body) => {
  const method = "GET";
  let url = `api/coach/my-client`;
  if (body?.search) {
    url = `api/coach/my-client?search=${body.search}`;
  }
  return useAPI({ method, url });
};
const FetchListOfGroups = (body) => {
  const method = "GET";
  let url = `api/coach/group`;
  if (body?.search) {
    url += `?search=${body.search}`;
  }
  return useAPI({ method, url });
};
const FetchLessonsByCourseID = (body) => {
  const method = "POST";
  const url = `api/coach/course/lesson`;
  return useAPI({ method, url, body: { course_id: 1 } });
};
const CreateCourse = (body) => {
  const method = "POST";
  const url = `api/coach/courses`;
  return useAPI({ method, url, body });
};
const UpdateCourse = (body) => {
  const method = "PUT";
  const url = `api/coach/course`;
  return useAPI({ method, url, body });
};
const FetchCoursesList = (body) => {
  const method = "GET";
  let url = `api/coach/courses`;
  if (body?.search) {
    url = `api/coach/courses?search=${body.search}`;
  } else if (body?.likedFilter) {
    url = `api/coach/courses?favourite=${body?.likedFilter ? 1 : 0}`;
  }
  return useAPI({ method, url });
};
const FetchAdminCoursesList = (body) => {
  const method = "GET";
  let url = `api/coach/admin/course`;
  if (body?.search) {
    url = `api/coach/admin/course?search=${body.search}`;
  } else if (body?.id) {
    url = `api/coach/admin/course?user_id=${body.id}`;
  } else if (body?.id && body?.habitsPayload) {
    url = `api/coach/admin/course?user_id=${body.id}&search=${body?.habitsPayload.search}`;
  }
  return useAPI({ method, url, body });
};

const FetchAdminLessonsList = (body) => {
  const method = "GET";
  let url = `api/coach/daily-practices/admin?page=${1}&perpage=${200}`;
  if (body?.search) {
    url = `api/coach/daily-practices/admin&search=${body.search}`;
  }
  return useAPI({ method, url, body });
};
export const FetchAdminLessonsListQuery = (payload) => {
  return useQuery(
    ["ADMIN_LESSONS_LIST", payload],
    () => FetchAdminLessonsList(payload),
    {
      enabled: true,
      onSuccess: (response) => {},
      onError: ErrorHandler,
    }
  );
};

const NonAssignableCourseClientList = (body) => {
  const method = "GET";
  const url = `api/coach/course/unassign/users?course_id=${body?.id}${
    body?.isAdmin ? "&for=admin" : ""
  }`;
  return useAPI({ method, url });
};

export const NonAssignableCourseUserListQuery = (payload) => {
  return useQuery(
    [`CLIENTS_COURSES_ASSIGN_LIST`, payload],
    () => NonAssignableCourseClientList(payload),
    {
      enabled: Boolean(payload?.id),
      onSuccess: () => null,
      onError: ErrorHandler,
    }
  );
};
const DailyPracticeClientList = (id) => {
  const method = "GET";
  const url = `api/coach/daily-practices/${id}/admin/unassign/users`;
  return useAPI({ method, url });
};

export const DailyPracticeClientListQuery = (payload) => {
  return useQuery(
    [`DAILY_PRACTICES_LIST`, payload],
    () => DailyPracticeClientList(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: ErrorHandler,
    }
  );
};
// const DailyPracticeClientList = (id) => {
//   const method = "GET";
//   const url = `api/coach/daily-practices/${id}/admin/unassign/users`;
//   return useAPI({ method, url });
// };

// export const DailyPracticeClientListQuery = (payload) => {
//   return useQuery(
//     [`DAILY_PRACTICES_LIST`, payload],
//     () => DailyPracticeClientList(payload),
//     {
//       enabled: Boolean(payload),
//       onSuccess: () => null,
//       onError: ErrorHandler,
//     }
//   );
// };

export const FetchAdminCoursesListQuery = (payload) => {
  return useQuery(
    ["ADMIN_COURSES_LIST", payload],
    () => FetchAdminCoursesList(payload),
    {
      enabled: true,
      onSuccess: (response) => {},
      onError: ErrorHandler,
    }
  );
};

const CreateLesson = (body) => {
  // console.log("body", body);
  const method = "POST";
  const url = `api/coach/create/lesson`;
  // const formData = new FormData();
  // formData.append("thumbnail", body?.thumbnail?.file);

  return useAPI({
    method,
    url,
    body,
    // contentType: "multipart/form-data",
  });
};
const UpdateCourseFavouriteStatus = (body) => {
  const method = "POST";
  const url = `api/coach/courses/${body?.courseId}/favourite`;
  delete body.courseId;
  return useAPI({ method, url, body });
};
const FetchGeneralLessons = (body) => {
  const method = "GET";
  let url = `api/coach/courses/general-lessons`;
  if (body?.search) {
    url += `?search=${body.search}`;
  }

  return useAPI({ method, url });
};
const CreateCustomCategory = (body) => {
  const method = "POST";
  const url = `api/coach/category`;
  return useAPI({ method, url, body });
};

const CreateCustomTag = (body) => {
  const method = "POST";
  const url = `api/coach/tags/custom/multiple`;
  return useAPI({ method, url, body });
};
const AssignToCourse = (body) => {
  const method = "POST";
  const url = `api/coach/courses/assign`;
  return useAPI({ method, url, body });
};
export const AssignToCourseQuery = () => {
  const queryClient = useQueryClient();
  const responseToast = useToast();
  return useMutation(AssignToCourse, {
    onSuccess: (response) => {
      responseToast.toastSuccess("Course Assigned Successfully!");
      queryClient.invalidateQueries([`COURSE_BY_ID`]);
      queryClient.invalidateQueries([`CLIENTS_COURSES_ASSIGN_LIST`]);
      queryClient.invalidateQueries([`COURSES_ASSIGN_LIST`]);
      queryClient.invalidateQueries([`CLIENT_COURSES`]);
      queryClient.invalidateQueries(["COACH_COURSES_LIST"]);
      queryClient.invalidateQueries([`ADMIN_COURSES_LIST`]);
    },
    onError: ErrorHandler,
  });
};
const FetchCourseByID = (courseId) => {
  const method = "GET";
  const url = `api/coach/course/${courseId}`;
  return useAPI({ method, url });
};
const FetchLessonByID = (lessonId) => {
  const method = "GET";
  const url = `api/coach/lesson/${lessonId}`;
  return useAPI({ method, url });
};
const UnlinkLesson = (body) => {
  const method = "PUT";
  const url = `api/coach/course/lesson/${body?.id}`;
  return useAPI({ method, url });
};
const DeleteLesson = (body) => {
  const method = "DELETE";
  const url = `api/coach/lesson/${body?.id}`;
  return useAPI({ method, url });
};
const UpdateLessons = (body) => {
  const method = "PUT";
  const url = `api/coach/course/lesson`;
  return useAPI({ method, url, body });
};
export const UpdateLessonQuery = (payload) => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  return useMutation(UpdateLessons, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("COACH_COURSES_LIST", payload);
      queryClient.invalidateQueries("GENERAL_LESSONS", payload);
      queryClient.invalidateQueries(
        `COURSE_BY_ID_${payload?.courseID}`,
        payload
      );
      queryClient.invalidateQueries([`CLIENT_DAILY_PROMPTS`]);
      queryClient.invalidateQueries([`FAMILY_DAILY_PROMPTS`]);
      queryClient.invalidateQueries("LESSON_BY_ID", payload);
      responseToast.toastSuccess("Lesson updated successfully!");
    },
    onError: ErrorHandler,
  });
};

export const CourseImageUploadQuery = () => {
  return useMutation(CourseImageUpload, {
    onSuccess: (response) => {},
    onError: ErrorHandler,
  });
};
export const FetchTagsQuery = () => {
  return useQuery("COACH_TAGS", FetchTags, {
    enabled: true,
    onSuccess: (response) => {},
    onError: ErrorHandler,
  });
};
export const FetchCategoriesQuery = () => {
  return useQuery("COACH_CATEGORIES", FetchCategories, {
    enabled: true,
    onSuccess: (response) => {},
    onError: ErrorHandler,
  });
};
export const FetchListOfClientsQuery = (payload) => {
  return useQuery(["LIST_OF_CLIENTS", payload], FetchListOfClients, {
    enabled: true,
    onSuccess: (response) => {},
    onError: ErrorHandler,
  });
};
export const FetchListOfGroupsQuery = (payload) => {
  return useQuery(["LIST_OF_GROUPS", payload], FetchListOfGroups, {
    enabled: true,
    onSuccess: (response) => {},
    onError: ErrorHandler,
  });
};
export const FetchLessonsByCourseIDQuery = (payload) => {
  return useQuery(["LESSONS_BY_COURSE_ID", payload], FetchLessonsByCourseID, {
    enabled: true,
    onSuccess: (response) => {},
    onError: ErrorHandler,
  });
};
export const CreateCourseQuery = (payload) => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  return useMutation(CreateCourse, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("COACH_COURSES_LIST");

      responseToast.toastSuccess("Course created successfully!");
    },
    onError: ErrorHandler,
  });
};
export const UpdateCourseQuery = (payload) => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  return useMutation(UpdateCourse, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("COURSE_BY_ID");
      queryClient.invalidateQueries(`USER_COURSES_ASSIGN_LIST`);
      queryClient.invalidateQueries([`CLIENTS_COURSES_ASSIGN_LIST`]);
      queryClient.invalidateQueries([`COURSES_ASSIGN_LIST`]);
      responseToast.toastSuccess("Course updated successfully!");
    },
    onError: ErrorHandler,
  });
};
export const FetchCoursesListQuery = (payload) => {
  return useQuery(
    ["COACH_COURSES_LIST", payload],
    () => FetchCoursesList(payload),
    {
      enabled: true,
      onSuccess: (response) => {},
      onError: ErrorHandler,
    }
  );
};
export const CreateLessonQuery = (payload) => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  return useMutation(CreateLesson, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("GENERAL_LESSONS");
      queryClient.invalidateQueries([`COURSE_BY_ID`]);
      queryClient.invalidateQueries([`CLIENT_DAILY_PROMPTS`]);
      queryClient.invalidateQueries([`FAMILY_DAILY_PROMPTS`]);
      responseToast.toastSuccess("Lesson created successfully!");
    },
    onError: ErrorHandler,
  });
};
export const UpdateCourseFavouriteStatusQuery = (payload) => {
  const queryClient = useQueryClient();
  return useMutation(UpdateCourseFavouriteStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries("COACH_COURSES_LIST");
    },
    onError: ErrorHandler,
  });
};
export const FetchGeneralLessonsQuery = (payload) => {
  return useQuery(
    ["GENERAL_LESSONS", payload],
    () => FetchGeneralLessons(payload),
    {
      enabled: true,
      onSuccess: (response) => {},
      onError: ErrorHandler,
    }
  );
};
export const CreateCustomCategoryQuery = (payload) => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  return useMutation(CreateCustomCategory, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("COACH_CATEGORIES");
      responseToast.toastSuccess("Category created successfully!");
    },
    onError: ErrorHandler,
  });
};
export const CreateCustomTagQuery = (payload) => {
  const queryClient = useQueryClient();
  return useMutation(CreateCustomTag, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("COACH_TAGS");
    },
    onError: ErrorHandler,
  });
};
export const FetchCourseByIDQuery = (payload) => {
  return useQuery(
    [`COURSE_BY_ID`, payload],
    () => FetchCourseByID(payload?.courseID),
    {
      enabled: payload?.courseID ? true : false,
      onSuccess: (response) => {},
      onError: ErrorHandler,
    }
  );
};
export const FetchLessonByIDQuery = (payload) => {
  return useQuery(
    ["LESSON_BY_ID", payload],
    () => FetchLessonByID(payload?.lessonID),
    {
      enabled: payload?.lessonID ? true : false,
      onSuccess: (response) => {},
      onError: ErrorHandler,
    }
  );
};
export const UnlinkLessonQuery = (payload) => {
  const queryClient = useQueryClient();
  return useMutation(UnlinkLesson, {
    onSuccess: (response) => {
      queryClient.invalidateQueries([`COURSE_BY_ID`]);
    },
    onError: ErrorHandler,
  });
};
export const DeleteLessonQuery = (payload) => {
  return useMutation(DeleteLesson, {
    onError: ErrorHandler,
  });
};

const ThumbnailImageUpload = (body) => {
  const method = "POST";
  const url = `api/coach/upload/lesson/image`;
  const formData = new FormData();
  formData.append("image", body?.file);
  return useAPI({
    method,
    url,
    body: formData,
    contentType: "multipart/form-data",
  });
};
export const ThumbnailImageUploadQuery = () => {
  return useMutation(ThumbnailImageUpload, {
    onSuccess: (response) => {},
    onError: ErrorHandler,
  });
};
const VideoUpload = (body) => {
  const method = "POST";
  const url = `api/coach/courses/video`;
  const formData = new FormData();
  formData.append("video", body?.file);
  return useAPI({
    method,
    url,
    body: formData,
    contentType: "multipart/form-data",
  });
};
export const VideoUploadQuery = () => {
  const responseToast = useToast();
  return useMutation(VideoUpload, {
    onSuccess: (response) => {
      responseToast.toastSuccess("Video Upload successfully!");
    },
    onError: ErrorHandler,
  });
};

const DeleteCourse = (body) => {
  const method = "DELETE";
  const url = `api/coach/course/${body?.id}/archive`;
  return useAPI({ method, url });
};
export const DeleteCourseQuery = (payload) => {
  return useMutation(DeleteCourse, {
    onError: ErrorHandler,
  });
};

const UpdateLessonsOrder = (payload) => {
  return useAPI({
    method: "POSt",
    url: `api/coach/course/${payload?.courseID}/lesson`,
    body: { lessons: payload?.lessons },
  });
};

export const UpdateLessonsOrderQuery = () => {
  return useMutation(UpdateLessonsOrder, {
    onSuccess: (response) => {},
    onError: ErrorHandler,
  });
};

const AddDailyPractice = (body) => {
  return useAPI({ method: "POST", url: "api/coach/daily-practice", body });
};
export const AddDailyPracticeQuery = (payload) => {
  const responseToast = useToast();
  return useMutation(AddDailyPractice, {
    onSuccess: (response) => {
      responseToast.toastSuccess("Daily Practice added successfully!");
    },
    onError: ErrorHandler,
  });
};

const ErrorHandler = (error) => {
  const responseToast = useToast();
  Sentry.captureException(error);
  if (error?.errors) {
    responseToast.toastError("Something went wrong!", error.errors);
  } else if (error?.message) {
    responseToast.toastError(error.message);
  }
};
