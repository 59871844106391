import { useState } from "react";
import { Button, Grid, Paper, useMediaQuery } from "@mui/material";
import GroupLogo from "assets/Images/GroupLogo.svg";
import CareBetterLogo from "assets/Images/CareBetterWordmark.png";
import LoginForm from "./LoginForm";
import { useHistory } from "react-router-dom";
import RouteConstants from "Routes/RouteConstants";
import ForgotPasswordModal from "./ForgotPasswordModal";

const LoginPage = () => {
  const history = useHistory();
  const isSmallScreen = useMediaQuery("(max-width:650px)");

  /* States */
  const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState(false);

  /* Handlers */
  const handleClickOpenForgotPasswordModal = () => {
    localStorage.setItem("userEmail-forgotPassword", "");
    setOpenForgotPasswordModal(true);
  };
  const handleCloseForgotPasswordModal = () => {
    setOpenForgotPasswordModal(false);
  };

  const accessToken = localStorage.getItem("token");

  if (accessToken && accessToken !== "null") {
    history.push(RouteConstants.PRACTICE);
  }

  return (
    <div className="flex-center h-screen w-screen">
      <ForgotPasswordModal
        open={openForgotPasswordModal}
        handleClose={handleCloseForgotPasswordModal}
      />
      <Grid container className="flex items-center">
        {isSmallScreen || (
          <Grid item xs={6}>
            <div className="flex-center">
              <img src={GroupLogo} alt="group-logo" />
            </div>
          </Grid>
        )}
        <Grid item xs={isSmallScreen ? 12 : 6}>
          <div className="flex-center">
            <Paper variant="outlined" className="w-3/4">
              <div className="flex-center my-8">
                <img
                  src={CareBetterLogo}
                  alt="carebetter_logo"
                  className="w-64"
                />
              </div>
              <div className="mx-8 lg:mx-16">
                <LoginForm />
                <div className="float-right">
                  <Button
                    sx={{ color: "#FF6C44" }}
                    onClick={handleClickOpenForgotPasswordModal}
                  >
                    Forgot Password?
                  </Button>
                </div>
                <div className="mb-4 mt-16 flex w-full justify-center">
                  <Button
                    form="login-form"
                    type="submit"
                    className="w-full"
                    variant="contained"
                    sx={{ background: "#FF6C44", color: "white" }}
                  >
                    Log In
                  </Button>
                </div>
                <div className="my-6 flex items-center justify-center">
                  <p className="text-sm text-hb_blue-800">
                    Don't have an account?
                  </p>
                  <Button
                    sx={{ color: "#FF6C44" }}
                    className="text-sm"
                    onClick={() => history.push(RouteConstants.SIGNUP)}
                  >
                    Sign Up
                  </Button>
                </div>
              </div>
            </Paper>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default LoginPage;
