import * as React from "react";
import {
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  Checkbox,
} from "@mui/material";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "components";

const CustomSelectField = (props) => {
  const {
    label,
    labelColor,
    variant,
    error,
    helperText,
    className,
    required,
    disabled,
    value,
    options,
    onChange,
    fullWidth,
    multiple,
    disableUnderline,
    multipleType,
    defaultValue,
    ...restProps
  } = props;

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => window.location.reload()}
    >
      <FormControl
        className={`${className} ${fullWidth ? "w-full" : ""}`}
        variant={variant}
        margin="dense"
        error={Boolean(error)}
      >
        {label && (
          <InputLabel
            required={required}
            disabled={disabled}
            color={labelColor}
            // shrink={Boolean(value)}
            className="-mt-2 text-base text-hb_blue-350"
          >
            {label}
          </InputLabel>
        )}

        <Select
          value={value}
          onChange={onChange}
          disabled={disabled}
          label={label}
          renderValue={(selectedOption) => {
            if (Array.isArray(selectedOption)) {
              return selectedOption?.map((opt) => opt?.label).join(", ");
            } else {
              return selectedOption.label;
            }
          }}
          disableUnderline={disableUnderline}
          multiple={multiple}
          IconComponent={KeyboardArrowDownIcon}
          sx={{
            "& .MuiSelect-icon": {
              marginTop: disableUnderline ? "" : "-4px",
              color: "#264265B2",
            },
            "& .MuiSelect-select": {
              fontWeight: "400 !important",
              paddingRight: "2rem !important",
            },
          }}
          className="text-base font-normal"
          {...(defaultValue && { defaultValue })}
          {...restProps}
        >
          {options?.map((option) => (
            <MenuItem
              value={option}
              key={option.id}
              className="text-base font-normal"
            >
              {multipleType === "checkbox" && (
                <Checkbox checked={value?.indexOf(option) > -1} />
              )}
              <div className="flex justify-between w-full">
              <div>  {option?.label}</div>
              <div>  {option?.icon}</div>
            
            

              </div>

            </MenuItem>
          ))}
        </Select>
        <FormHelperText error={Boolean(error)}>{helperText}</FormHelperText>
      </FormControl>
    </ErrorBoundary>
  );
};

CustomSelectField.defaultProps = {
  label: "",
  labelColor: "componentTitle",
  variant: "standard",
  error: false,
  helperText: "",
  className: null,
  options: [],
  required: false,
  disableUnderline: false,
  multiple: false,
};

export default CustomSelectField;
