import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useAPI, useToast } from "services";
import RouteConstants from "Routes/RouteConstants";
import { uuid } from "uuidv4";
import * as Sentry from "@sentry/react";

const LogoutUser = () => {
  const method = "POST";
  const url = `api/coach/logout`;
  return useAPI({ method, url });
};

const LoginUser = (body) => {
  const method = "POST";
  const url = `api/coach/login`;
  const payload = {
    ...body,
    device_token: uuid(),
  };
  return useAPI({ method, url, body: { ...payload } });
};
const ForgotPassword = (body) => {
  const method = "POST";
  const url = `api/coach/forgot-password`;
  return useAPI({ method, url, body });
};

const VerifyOTP = (body) => {
  const method = "POST";
  const url = `api/coach/verify-otp`;
  return useAPI({ method, url, body });
};
const ResetPassword = (body) => {
  const method = "POST";
  const url = `api/coach/reset-password`;
  return useAPI({ method, url, body });
};

export const LoginUserQuery = () => {
  const history = useHistory();
  const responseToast = useToast();
  const queryClient = useQueryClient();
  return useMutation(LoginUser, {
    onSuccess: (response) => {
      localStorage.clear();
      queryClient.invalidateQueries();
      localStorage.setItem("token", response.access_token);
      responseToast.toastSuccess(
        response.description || "Logged In Successfully"
      );
      const email = response?.data?.email;
      const user_uuid = response?.data?.uuid;
      if (email || user_uuid) {
        Sentry.setUser({ id: user_uuid, email });
      }
      history.push(RouteConstants.PRACTICE);
    },
    onError: (error) => console.log(error),
  });
};

export const LogOutUserQuery = () => {
  const history = useHistory();
  const responseToast = useToast();
  return useMutation(LogoutUser, {
    onSuccess: (response) => {
      responseToast.toastSuccess(
        response?.message || "Logged Out Successfully"
      );
      localStorage.setItem("token", null);
      localStorage.clear();
      Sentry.setUser(null);
      history.push(RouteConstants.LOGIN);
    },
    onError: ErrorHandler,
  });
};

export const ForgotPasswordQuery = () => {
  return useMutation(ForgotPassword, {
    onSuccess: (response) => {},
    onError: ErrorHandler,
  });
};
export const VerifyOTPQuery = () => {
  return useMutation(VerifyOTP, {
    onSuccess: (response) => {},
    onError: ErrorHandler,
  });
};
export const ResetPasswordQuery = () => {
  return useMutation(ResetPassword, {
    onSuccess: (response) => {},
    onError: ErrorHandler,
  });
};

const ErrorHandler = (error) => {
  const responseToast = useToast();
  Sentry.captureException(error);
  if (error?.errors) {
    responseToast.toastError("Something went wrong!", error.errors);
  } else if (error?.message) {
    responseToast.toastError(error.message);
  }
};
const GetNotifications = () => {
  const method = "GET";
  const url = `api/coach/notification?page=${1}&perpage=${200}`;
  return useAPI({ method, url });
};

export const GetNotificationsQuery = () => {
  return useQuery("GET_NOTIFICATIONS", GetNotifications, {
    enabled: true,
  });
};
const ReadNotification = (body) => {
  const method = "PUT";
  const url = `api/coach/notification`;
  return useAPI({
    method,
    url,
    body,
  });
};
export const ReadNotificationQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(ReadNotification, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("GET_NOTIFICATIONS");
    },
    onError: ErrorHandler,
  });
};
