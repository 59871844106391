import { createContext, useState } from "react";

export const CoachSubscriptionContext = createContext(null);

export const CoachSubscriptionContextProvider = ({ children }) => {
  return (
    <CoachSubscriptionContext.Provider value={useState(null)}>
      {children}
    </CoachSubscriptionContext.Provider>
  );
};
