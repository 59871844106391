import { useState, useEffect } from "react";
import {
  useForm,
  Controller,
  FormProvider,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  ResendOtpQuery,
  SignupUserQuery,
  VerifyOtpQuery,
} from "./SignupQueries";
import {
  Grid,
  Button,
  useMediaQuery,
  Tooltip,
  IconButton,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { HiInformationCircle } from "react-icons/hi";
import CustomTextField from "components/FormComponents/CustomTextField";
import MuiPhoneNumber from "material-ui-phone-number";
import RouteConstants from "Routes/RouteConstants";
import { useHistory, useLocation } from "react-router-dom";
import AddressAutocompleteTextfield from "components/AddressAutocompleteTextfield";
import OtpInput from "react-otp-input";
import { useLocalStorage } from "utils/CustomHooks/useLocalStorage";
import { useInterval } from "utils/CustomHooks/useInterval";
import { intervalToDuration } from "date-fns";
import { PasswordField } from "components";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { format, endOfMonth } from 'date-fns';

const zeroPad = (num, places) => String(num).padStart(places, "0");

const formattedTime = (seconds) => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
  return `${zeroPad(duration.minutes, 2)}:${zeroPad(duration.seconds, 2)}`;
};

const SignupFormSchema = yup.object().shape({
  first_name: yup
    .string()
    .required("Name is Required")
    .min(4, "Name must be at least 3 characters long")
    .max(255, "Name must be less than 30 characters long"),
    last_name: yup
    .string()
    .required("Name is Required")
    .min(4, "Name must be at least 3 characters long")
    .max(255, "Name must be less than 30 characters long"),
  email: yup
    .string()
    .required("Email is  Required")
    .email("Please enter a valid email"),
  username: yup
    .string()
    .required("Username is a required field")
    .min(4, "Username must be at least 4 characters long")
    .max(255, "Username must be less than 255 characters long"),
  password: yup
    .string()
    .required("Please Enter your password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  phone: yup
    .string()

    .required("Phone is Required"),

  street: yup.string().required("Street is Required").nullable(),
  occupation : yup.string().required("Occupation is Required"),
  title : yup.string().nullable(),
  city: yup.string().required("City is Required").nullable(),
  state: yup.string().required("State is Required").nullable(),
  zip_code: yup.string().required("Zip Code is required").nullable(),
  license_type: yup.string().when('occupation', {
    is: (occupation) => occupation !== "Coach/Advisor",
    then: yup.string().required("license Type is Required"),
    otherwise: yup.string().nullable(),
  }),
  license_number: yup.string().when('occupation', {
    is: (occupation) => occupation !== "Coach/Advisor",
    then: yup.string().required("license Number is Required"),
    otherwise: yup.string().nullable(),
  }),
  license_state : yup.string().nullable(),
  license_expiration : yup.string().nullable(),
});

const OtpFormSchema = yup.object().shape({
  otp: yup
    .string()
    .min(4, "One Time Password should be atleast 4 digits")
    .required("One Time Password is required"),
});

const SignupForm = (props) => {
  const validationSchema = [SignupFormSchema, OtpFormSchema];
  const { search } = useLocation();
  const paramEmaill = search.split("=")[1];

  //states
  const [formStep, setFormStep] = useLocalStorage("signupStep", 0);

  // react-hook-form
  const currentValidationSchema = validationSchema[formStep];

  const methods = useForm({
    resolver: yupResolver(currentValidationSchema),
  });
  const { handleSubmit, watch: signupFormCredentials } = methods;

  //queries
  const { mutate: SignupUser } = SignupUserQuery(signupFormCredentials);
  const { mutateAsync: VerifyOtp } = VerifyOtpQuery();

  //handlers
  const submitSignupForm = (formData) => {
    if (formStep === 0) {
      formData.phone_no = removeSpecialCharacters(formData.phone);
      delete formData.phone;
      if (!formData.street) {
        delete formData.street;
      }
      SignupUser(formData, {
        onSuccess: () => {
          localStorage.setItem("userEmail-forgotPassword", formData.email);
          setFormStep(1);
        },
      });
    } else if (formStep === 1) {
      VerifyOtp(
        { email: formData.email || paramEmaill, otp: formData?.otp },
        {
          onSuccess: () => {},
        }
      );
    }
  };
  const removeSpecialCharacters = (text) => {
    return text?.replace(/[^A-Z0-9]+/gi, "");
  };

  const getUiForCurrentStep = (step) => {
    switch (step) {
      case 0:
        return <SignupFormMain />;
      case 1:
        return <VerifyOtpForm />;
      default:
        return;
    }
  };
  useEffect(() => {
    return () => setFormStep(0);
  }, []);

  return (
    <FormProvider {...methods}>
      <form
        id="signup-form"
        noValidate
        onSubmit={handleSubmit(submitSignupForm)}
      >
        {getUiForCurrentStep(formStep)}
      </form>
    </FormProvider>
  );
};

/* Step-0 */
const SignupFormMain = (props) => {
  const { handleNext } = props;
  const history = useHistory();
  const [open, setOpen] = useState(false); 

  const {
    register,
    formState: { errors },
    control,
    setFocus,
    setValue,
    watch,
  } = useFormContext();

  const { zip_code, street, city, state, occupation } = watch();

  //useEffects
  useEffect(() => {
    setFocus("first_name");
  }, [setFocus]);

  useEffect(() => {
    localStorage.setItem("otp-timer-signup", 60);
  }, []);

  const { ref: refRegisterFirstName, ...RegisterSignupFirstName } = register("first_name");
  const { ref: refRegisterLastName, ...RegisterSignupLastName } = register("last_name");
  
  const { ref: refRegisterTitle, ...RegisterSignupTitle } = register("title");


  const { ref: refRegisterlicenseType, ...RegisterlicenseType } = register("license_type");
  const { ref: refRegisterlicenseNumber, ...RegisterlicenseNumber } = register("license_number");
  const { ref: refRegisterlicenseState, ...RegisterlicenseState } = register("license_state");
  

  const { ref: refRegisterEmail, ...RegisterSignupEmail } = register("email");
  const { ref: refRegisterUsername, ...RegisterSignupUsername } =
    register("username");
  const { ref: refRegisterPassword, ...RegisterSignupPassword } =
    register("password");
  const { ref: refRegisterCity, ...RegisterSignupCity } = register("city");
  const { ref: refRegisterState, ...RegisterSignupState } = register("state");
  const { ref: refRegisterZipCode, ...RegisterSignupZipCode } =
    register("zip_code");

  const handleGoogleAddress = (address) => {
    setValue("state", address.state);
    setValue("street", address.address);
    setValue("city", address.city);
    setValue("zip_code", address.postal_code);
  };
  const handleManualStreetAddress = (street) => {
    setValue("street", street);
  };

  const handleOccupation = (occupation) => {
    setValue("occupation", occupation);
  };

  const expirationDate = useWatch({
    control,
    name: "license_expiration",
  });

  return (
    <>
      <Grid container>
        <Grid item xs={12} className="my-2">
          <CustomTextField
            required
            label="First Name *"
            type="text"
            inputRef={refRegisterFirstName}
            {...RegisterSignupFirstName}
            error={Boolean(errors.first_name)}
            helperText={errors.first_name && errors.first_name.message}
            autoComplete="first_name"
          />
        </Grid>
        <Grid item xs={12} className="my-2">
          <CustomTextField
            required
            label="Last Name *"
            type="text"
            inputRef={refRegisterLastName}
            {...RegisterSignupLastName}
            error={Boolean(errors.last_name)}
            helperText={errors.last_name && errors.last_name.message}
            autoComplete="last_name"
          />
        </Grid>
        <Grid item xs={12} className="my-4">
          <CustomTextField
            required
            label="Email *"
            type="email"
            inputRef={refRegisterEmail}
            {...RegisterSignupEmail}
            error={Boolean(errors.email)}
            helperText={errors.email && errors.email.message}
            autoComplete="email"
          />
        </Grid>
        <Grid item xs={12} className="my-4">
          <CustomTextField
            required
            label="Username *"
            type="text"
            inputRef={refRegisterUsername}
            {...RegisterSignupUsername}
            error={Boolean(errors.username)}
            helperText={errors.username && errors.username.message}
            autoComplete="username"
          />
        </Grid>
        <Grid item xs={12} className="my-4">
          <PasswordField
            required
            label="Password *"
            type="password"
            variant="standard"
            endAdornment
            inputRef={refRegisterPassword}
            {...RegisterSignupPassword}
            error={Boolean(errors.password)}
            helperText={errors.password && errors.password.message}
            autoComplete="password"
          />
        </Grid>
        <Grid item xs={12} className="my-4">
          <Controller
            control={control}
            name="phone"
            render={({ field, fieldState }) => (
              <MuiPhoneNumber
                disableAreaCodes
                defaultCountry={"us"}
                value={field.value}
                onChange={field.onChange}
                sx={{
                  width: "100%",
                  input: { color: "#264265", fontSize: "1rem" },
                  "& .MuiInputLabel-root": {
                    color: "#264265B2",
                    fontSize: "1rem",
                    marginTop: "-.4rem",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#000000CC",
                  },
                }}
                onBlur={field.onBlur}
                name={field.name}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error && fieldState.error.message}
                label="Phone *"
              />
            )}
          />
        </Grid>
        <Grid item container xs={12} className="mt-4">
          <Grid conatiner className="flex justify-between">
            <Grid item>
              <Typography className="font-semibold">
                Address Information
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip title="This is used to connect billing and other practice documents to your correct business/practice address. For example Banking,and invoices to clients">
                <IconButton className="-mt-1">
                  <HiInformationCircle className=" -mt-2 w-4 text-hb_orange-dark" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="my-1">
          {/* <CustomTextField
            label="Street"
            type="text"
            inputRef={refRegisterStreet}
            {...RegisterSignupStreet}
            error={Boolean(errors.street)}
            helperText={errors.street && errors.street.message}
            autoComplete="street"
          /> */}

          <AddressAutocompleteTextfield
            label="Street *"
            placeholder="Street"
            labelColor="componentTitle"
            defaultValue={street}
            InputLabelProps={{
              shrink: Boolean(street),
            }}
            handleGoogleAddress={handleGoogleAddress}
            error={Boolean(errors.street)}
            helperText={errors.street && errors.street.message}
            handleManualStreetAddress={handleManualStreetAddress}
          />
        </Grid>
        <Grid item xs={12} className="my-4">
          <CustomTextField
            label="City *"
            inputRef={refRegisterCity}
            {...RegisterSignupCity}
            autoComplete="city"
            InputLabelProps={{
              shrink: Boolean(city),
            }}
            fullWidth
            error={Boolean(errors.city)}
            helperText={errors.city && errors.city.message}
          />
        </Grid>
        <Grid item xs={12} className="my-4">
          <CustomTextField
            label="State *"
            inputRef={refRegisterState}
            {...RegisterSignupState}
            disableClearable
            error={Boolean(errors.state)}
            helperText={errors.state && errors.state.message}
            autoComplete="state"
            InputLabelProps={{
              shrink: Boolean(state),
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} className="my-4">
          <CustomTextField
            label="Zip code / Postal code *"
            InputLabelProps={{
              shrink: Boolean(zip_code),
            }}
            type="number"
            inputRef={refRegisterZipCode}
            {...RegisterSignupZipCode}
            error={Boolean(errors.zip_code)}
            helperText={errors.zip_code && errors.zip_code.message}
            autoComplete="zip_code"
            fullWidth
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 6);
            }}
          />
        </Grid>
      </Grid>
      <Grid
                          item
                          xs={12}
                          className='my-4'>
                          <FormControl fullWidth>
                            <InputLabel
                                  sx={{
                                    position: 'relative', // Default is 'absolute', change it to 'relative' to ignore transform
                                    transform: 'none', // Remove the transform property
                                    marginTop: '0px', // You may adjust this as needed for alignment
                                    marginLeft: '0px', // You may adjust this as needed for alignment
                                    '&.MuiInputLabel-shrink': {
                                      transform: 'none',
                                    }
                                  }}
                                  error={Boolean(errors.occupation)}
                            >Occupation *</InputLabel>
                            <Select
                              value={occupation}
                              variant="standard"
                              onChange={(e) => {
                                handleOccupation(
                                  e.target.value
                                );
                              }}
                              error={Boolean(errors.occupation)}
                              sx={{
                                // "& .MuiSelect-select": {
                                //   backgroundColor: "white !important",
                                // },

                                // "& .MuiSelect-iconOpen": {
                                //   transform: "rotate(0deg) !important",
                                //   WebkitTransform: "rotate(0deg) !important",
                                // },
                                // "& .custom-input-label" : {
                                //     transform: "none !important",                                }
                                
                              }}>
                              <MenuItem value={"Therapist/Counselor"}>Therapist/Counselor</MenuItem>
                              <MenuItem value={"Accredited Coach/Advisor"}>Accredited Coach/Advisor</MenuItem>
                              <MenuItem value={"Coach/Advisor"}>Coach/Advisor</MenuItem>
                              <MenuItem value={"Psychologist"}>Psychologist</MenuItem>
                            </Select>
                              <FormHelperText
                              className="ml-0"
                                error={
                                  errors?.occupation
                                }>
                                {
                                  errors?.occupation?.message
                                }
                              </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} className="my-2">
          <CustomTextField
            required
            label="Title"
            type="text"
            inputRef={refRegisterTitle}
            {...RegisterSignupTitle}
            error={Boolean(errors.title)}
            helperText={errors.title && errors.title.message}
            autoComplete="title"
          />
        </Grid>


{occupation && occupation !== "Coach/Advisor" && <>

<Grid item xs={12} className="my-2">
          <CustomTextField
            required
            label="License Type *"
            type="text"
            inputRef={refRegisterlicenseType}
            {...RegisterlicenseType}
            error={Boolean(errors.license_type)}
            helperText={errors.license_type && errors.license_type.message}
            autoComplete="license_type"
          />
        </Grid>
        <Grid item xs={12} className="my-2">
          <CustomTextField
            required
            label="License Number *"
            type="text"
            inputRef={refRegisterlicenseNumber}
            {...RegisterlicenseNumber}
            error={Boolean(errors.license_number)}
            helperText={errors.license_number && errors.license_number.message}
            autoComplete="license_number"
          />
        </Grid>
        <Grid item xs={12} className="my-2">
          <CustomTextField
            required
            label="License State"
            type="text"
            inputRef={refRegisterlicenseState}
            {...RegisterlicenseState}
            error={Boolean(errors.license_state)}
            helperText={errors.license_state && errors.license_state.message}
            autoComplete="License_state"
          />
        </Grid>
        <Grid item xs={12} className="my-2">

<LocalizationProvider dateAdapter={AdapterDateFns}>
  <DesktopDatePicker
    PopperProps={{
      style: { zIndex: props.customZIndex },
    }}
    key={Math.floor(Math.random() * 100)}
    value={expirationDate ?? null}
    onChange={(newValue) => {
      const formattedDate = newValue ? format(newValue, 'yyyy-MM-dd') : '';
      setValue("license_expiration", formattedDate);
    }}
    minDate={new Date()}
    open={open} // Control the open state
    onOpen={() => setOpen(true)} // Handle opening
    onClose={() => setOpen(false)}
    renderInput={({ inputRef, inputProps, InputProps }) => (
      <CustomTextField
        label="License Expiration"
        type="text"
        inputRef={inputRef}
        onClick={() => setOpen(true)}
        {...inputProps}
        error={Boolean(errors.license_expiration)}
        helperText={errors.license_expiration && errors.license_expiration.message}
        autoComplete="license_expiration"
        endAdornment={InputProps?.endAdornment}
        placeholder="MM/YYYY"
        sx={{
          "& .MuiInputAdornment-root": {
            marginRight: "12px"
          },
         
        }}
      />
    )}
  />
</LocalizationProvider>

        </Grid>
</>}
<Grid item xs={12} className="mt-4">
<p className="text-xs text-hb_blue-800">
If you do not have a license credential to enter please select Coach/Advisor for you occupation
        </p>

</Grid>

      <div className="mb-4 mt-16 flex w-full justify-center">
        <Button
          form="signup-form"
          type="submit"
          className="w-full"
          variant="contained"
          sx={{ background: "#FF6C44", color: "white" }}
          onClick={handleNext}
        >
          Sign Up
        </Button>
      </div>
      <div className="my-6 flex items-center justify-center">
        <p className="text-sm text-hb_blue-800">Already have an account?</p>
        <Button
          sx={{ color: "#FF6C44" }}
          className="text-sm"
          onClick={() => history.push(RouteConstants.LOGIN)}
        >
          Log In
        </Button>
      </div>
      <div className="mb-12">
        <p className="text-center text-sm text-hb_blue-800">
        By continuing, you agree to CareBetter's{" "}
          <span className="cursor-pointer text-hb_orange-dark">
            <a href="https://habitbetter.com/terms-of-use/" target="_/blank">
              {" "}
              Terms & Conditions{" "}
            </a>
          </span>{" "}
          and acknowledge the CareBetter{" "}
          <span className="cursor-pointer text-hb_orange-dark">
            <a href="https://habitbetter.com/privacy-policy/" target="_/blank">
              {" "}
              Privacy Policy{" "}
            </a>
          </span>
        </p>
      </div>
    </>
  );
};

/* Step-1 */
const VerifyOtpForm = (props) => {
  //states
  const [otpTimer, setOtpTimer] = useLocalStorage("otp-timer-signup", 60);
  const isSmallScreen = useMediaQuery("(max-width:950px)");
  const { search } = useLocation();
  const paramEmaill = search.split("=")[1];

  const [isTimerPlaying, setIsTimerPlaying] = useState(false);

  //useEffects
  useEffect(() => {
    if (otpTimer > 0) {
      setIsTimerPlaying(true);
    }
  }, []);

  //customHooks
  useInterval(
    () => {
      if (otpTimer > 0) {
        setOtpTimer((prevOtpTimer) => prevOtpTimer - 1);
      } else {
        setIsTimerPlaying(false);
      }
    },
    isTimerPlaying ? 1000 : null
  );

  //queries
  const { mutate: ResendOtp } = ResendOtpQuery();

  //handlers
  const handleResendOTP = () => {
    setOtpTimer(60);
    setIsTimerPlaying(true);

    ResendOtp({
      email: localStorage.getItem("userEmail-forgotPassword"),
    });
  };
  const handleResendOTPViaLogin = () => {
    setOtpTimer(60);
    setIsTimerPlaying(true);

    ResendOtp({
      email: paramEmaill || "",
    });
  };
  useEffect(() => {
    if (paramEmaill) {
      handleResendOTPViaLogin();
    }
  }, [paramEmaill]);

  const history = useHistory();
  const {
    watch,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext();
  const { otp } = watch();

  return (
    <div>
      <div className="text-center">
        <p className="text-2xl text-hb_blue-400">Verify your email address</p>
      </div>
      <div>
        <p
          className="text-md mt-8 rounded  py-4 px-12 text-center"
          style={{ background: "#d4edda", color: "#1b5e20" }}
        >
          We've sent a verification code to your email -{" "}
          <span className="font-semibold">
            {getValues("email") || paramEmaill}
          </span>
        </p>
      </div>

      <div className="mt-8 flex flex-col items-center justify-center">
        <p className="mb-4 text-lg font-medium text-hb_blue-400">Enter One Time Password</p>
        <OtpInput
          value={otp}
          onChange={(value) => {
            setValue("otp", value);
          }}
          numInputs={4}
          separator={<span>-</span>}
          inputStyle={{
            background: "#F3F6F9",
            borderRadius: "4px",
            margin: ".5rem",
            color: "black",
            padding: ".5rem .2rem",
            width: isSmallScreen ? "2rem" : "4rem",
          }}
          hasErrored={Boolean(errors.otp)}
          errorStyle={{ border: "1px solid red" }}
          focusStyle={{ outline: "none" }}
          isInputNum
        />
        <div className="flex w-full justify-center p-3">
          <p className="text-xs" style={{ color: "red" }}>
            {errors && errors.otp && `${errors.otp.message}`}
          </p>
        </div>
      </div>

      <div className="mb-6 flex flex-col items-end">
        <Button disabled={isTimerPlaying} onClick={handleResendOTP}>
          Resend One Time Password
        </Button>
        <p className="relative -left-3 text-sm font-medium">
          Time until One Time Password expires: {formattedTime(otpTimer)}
        </p>
      </div>

      <Button
        form="signup-form"
        type="submit"
        className="mb-4 w-full"
        variant="contained"
        sx={{ background: "#FF6C44", color: "white" }}
      >
        Verify Email
      </Button>

      <div className="mb-6 flex items-center justify-center">
        <p className="mx-4 text-sm text-hb_blue-800">
          Already have an account?{""}
        </p>

        <Button
          sx={{ color: "#FF6C44" }}
          className="text-sm"
          onClick={() => history.push(RouteConstants.LOGIN)}
        >
          Log In
        </Button>
      </div>
    </div>
  );
};

export default SignupForm;
