import {
  Autocomplete,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import CustomSelectField from "components/FormComponents/CustomSelectField";
import CustomTextField from "components/FormComponents/CustomTextField";
import { FetchDiagnosisDataQuery } from "Pages/Clients/ClientQueries";
import React from "react";
import { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { HiInformationCircle } from "react-icons/hi";

const interventionData = [
  {
    id: 1,
    label: "Acceptance and Commitment Therapy ",
    value: "Acceptance and Commitment Therapy ",
  },
  {
    id: 2,
    label: "Cognitive Behavioral Therapy (CBT)",
    value: "Cognitive Behavioral Therapy (CBT)",
  },
  {
    id: 3,
    label: "Cognitive Challenging",
    value: "Cognitive Challenging",
  },
  {
    id: 4,
    label: "Cognitive Refocusing",
    value: "Cognitive Refocusing",
  },
  {
    id: 5,
    label: "Cognitive Reframing",
    value: "Cognitive Reframing",
  },
  {
    id: 6,
    label: "Communication Skills",
    value: "Communication Skills",
  },
  {
    id: 7,
    label: "Dialectical Behavioral Therapy(DBT)",
    value: "Dialectical Behavioral Therapy(DBT)",
  },
  {
    id: 8,
    label: "Emotion Focused Therapy(EFT)",
    value: "Emotion Focused Therapy(EFT)",
  },
  {
    id: 9,
    label: "EMDR",
    value: "EMDR",
  },
  {
    id: 10,
    label: "Evaluation",
    value: "Evaluation",
  },
  {
    id: 11,
    label: "Exploration of Coping Patterns",
    value: "Exploration of Coping Patterns",
  },
  {
    id: 12,
    label: "Exploration of Emotions",
    value: "Exploration of Emotions",
  },
  {
    id: 13,
    label: "Exploration of Relationship Patterns",
    value: "Exploration of Relationship Patterns",
  },
  {
    id: 14,
    label: "Exposure Therap",
    value: "Exposure Therap",
  },
  {
    id: 15,
    label: "Gottman",
    value: "Gottman",
  },
  {
    id: 16,
    label: "Guided Imagery",
    value: "Guided Imagery",
  },
  {
    id: 17,
    label: "Insight",
    value: "Insight",
  },
  {
    id: 18,
    label: "Interactive Feedback",
    value: "Interactive Feedback",
  },
  {
    id: 19,
    label: "Interpersonal Resolutions",
    value: "Interpersonal Resolutions",
  },
  {
    id: 20,
    label: "Mentalization",
    value: "Mentalization",
  },
  {
    id: 21,
    label: "Mindfulness-Based Cognitive Therapy (MBCT)",
    value: "Mindfulness-Based Cognitive Therapy (MBCT)",
  },
  {
    id: 22,
    label: "Mindfulness Training",
    value: "Mindfulness Training",
  },
  {
    id: 23,
    label: "Preventative Services",
    value: "Preventative Services",
  },
  {
    id: 24,
    label: "Problem Solving",
    value: "Problem Solving",
  },
  {
    id: 25,
    label: "Psychodynamic",
    value: "Psychodynamic",
  },
  {
    id: 26,
    label: "Psychoeducation",
    value: "Psychoeducation",
  },
  {
    id: 27,
    label: "Reflection",
    value: "Reflection",
  },
  {
    id: 28,
    label: "Socratic Questions",
    value: "Socratic Questions",
  },
  {
    id: 29,
    label: "Solution Focused",
    value: "Solution Focused",
  },
  {
    id: 30,
    label: "Taught Coping Skills",
    value: "Taught Coping Skills",
  },
  {
    id: 31,
    label: "Validation",
    value: "Validation",
  },
  {
    id: 32,
    label: "Other",
    value: "Other",
  },
];

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "50vh",
      //   width: 250,
    },
  },
};

const StandardProgrssNote = ({ disabled }) => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const presenting_data = useWatch({
    control,
    name: "presenting_data",
  });
  //  queries
  const { data: diagnosisData } = FetchDiagnosisDataQuery();

  const { ref: overall_notesRef, ...overall_notesReg } =
    register("overall_notes");

  const { ref: cognitive_functioningRef, ...cognitive_functioningReg } =
    register("cognitive_functioning");

  const { ref: affectRef, ...affectReg } = register("affect");
  const { ref: moodRef, ...moodReg } = register("mood");
  const { ref: interpersonalRef, ...interpersonalReg } =
    register("interpersonal");
  const { ref: functional_statusRef, ...functional_statusReg } =
    register("functional_status");

  const { ref: risk_factorsRef, ...risk_factorsReg } = register("risk_factors");
  const { ref: symptomsRef, ...symptomsReg } = register("symptoms");
  const { ref: session_contentRef, ...session_contentReg } =
    register("session_content");

  const { ref: progressRef, ...progressReg } = register("progress");
  const { ref: planRef, ...planReg } = register("plan");

  const current_diagnosis = useWatch({
    control,
    name: "current_diagnosis",
  });

  const intervention = useWatch({
    control,
    name: "intervention",
  });

  const nextStep = useWatch({
    control,
    name: "next_step",
  });

  const handleSetValue = (key, val) => {
    setValue(key, val, { shouldValidate: true });
  };

  return (
    <div className="my-8 space-y-4">
      <div>
        <Typography
          variant="body2"
          fontWeight={500}
          marginTop="2rem !important"
          marginBottom={1}
        >
          Diagnosis:
        </Typography>

        <Autocomplete
          value={presenting_data || []}
          onChange={(event, value) => {
            let ids = value?.map((val) => val.id);

            if (ids) setValue("presenting_data", value);
          }}
          options={
            diagnosisData?.data?.map((item) => {
              return {
                id: item.id,
                name: `${item.code} - ${item.name}`,
              };
            }) || []
          }
          disableCloseOnSelect
          disabled={disabled}
          getOptionLabel={(option) => option.name}
          renderOption={(prop, option) => <li {...prop}>{option?.name}</li>}
          isOptionEqualToValue={(option, value) => {
            return Boolean(option.id === value.id);
          }}
          multiple
          renderInput={(params) => (
            <TextField
              className="borderless-select"
              {...params}
              variant="standard"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={disabled}
                      onClick={() =>
                        openInNewTab("https://habitbetter.com/diagnosticcodes/")
                      }
                    >
                      <HiInformationCircle className="w-6 text-hb_orange-dark" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </div>
      <Typography variant="body2" fontWeight={500}>
        OVERALL NOTES
      </Typography>
      <CustomTextField
        variant="standard"
        // label="OVERALL NOTES"
        multiline
        rows={4}
        inputRef={overall_notesRef}
        {...overall_notesReg}
        disabled={disabled}
        error={errors.overall_notes}
        helperText={errors.overall_notes && errors.overall_notes.message}
      />
      <Typography variant="body2" fontWeight={500} marginTop="2rem !important">
        MENTAL STATUS
      </Typography>
      <CustomTextField
        variant="standard"
        label="Cognitive Functioning"
        multiline
        rows={4}
        inputRef={cognitive_functioningRef}
        {...cognitive_functioningReg}
        disabled={disabled}
        error={errors.cognitive_functioning}
        helperText={
          errors.cognitive_functioning && errors.cognitive_functioning.message
        }
      />
      <CustomTextField
        variant="standard"
        label="Affect"
        multiline
        rows={4}
        inputRef={affectRef}
        {...affectReg}
        disabled={disabled}
        error={errors.affect}
        helperText={errors.affect && errors.affect.message}
      />
      <CustomTextField
        variant="standard"
        label="Mood"
        multiline
        rows={4}
        inputRef={moodRef}
        {...moodReg}
        disabled={disabled}
        error={errors.mood}
        helperText={errors.mood && errors.mood.message}
      />
      <CustomTextField
        variant="standard"
        label="Interpersonal"
        multiline
        rows={4}
        inputRef={interpersonalRef}
        {...interpersonalReg}
        disabled={disabled}
        error={errors.interpersonal}
        helperText={errors.interpersonal && errors.interpersonal.message}
      />
      <CustomTextField
        variant="standard"
        label="Functional Status"
        multiline
        rows={4}
        inputRef={functional_statusRef}
        {...functional_statusReg}
        disabled={disabled}
        error={errors.functional_status}
        helperText={
          errors.functional_status && errors.functional_status.message
        }
      />
      <Typography variant="body2" fontWeight={500} marginTop="2rem !important">
        RISK FACTORS
      </Typography>
      <CustomTextField
        variant="standard"
        multiline
        rows={4}
        inputRef={risk_factorsRef}
        {...risk_factorsReg}
        disabled={disabled}
        error={errors.risk_factors}
        helperText={errors.risk_factors && errors.risk_factors.message}
      />
      <Typography variant="body2" fontWeight={500} marginTop="2rem !important">
        CURRENT FUNCTIONING, SYMPTOMS AND IMPAIRMENTS
      </Typography>
      <CustomTextField
        variant="standard"
        multiline
        rows={4}
        inputRef={symptomsRef}
        {...symptomsReg}
        disabled={disabled}
        error={errors.symptoms}
        helperText={errors.symptoms && errors.symptoms.message}
      />
      <Typography variant="body2" fontWeight={500} marginTop="2rem !important">
        CURRENT DIAGNOSIS ADDRESSED IN SESSION
      </Typography>

      <Autocomplete
        value={current_diagnosis || []}
        onChange={(event, value) => {
          let ids = value?.map((val) => val.id);

          if (ids) setValue("current_diagnosis", value);
        }}
        options={
          diagnosisData?.data?.map((item) => {
            return {
              id: item.id,
              name: `${item.code} - ${item.name}`,
            };
          }) || []
        }
        disabled={disabled}
        getOptionLabel={(option) => option.name}
        renderOption={(prop, option) => <li {...prop}>{option?.name}</li>}
        isOptionEqualToValue={(option, value) => {
          return Boolean(option.id === value.id);
        }}
        multiple
        renderInput={(params) => (
          <TextField
            className="borderless-select"
            {...params}
            variant="standard"
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
      />
      <Typography variant="body2" fontWeight={500} marginTop="2rem !important">
        CONTENT OF SESSION
      </Typography>
      <CustomTextField
        variant="standard"
        multiline
        rows={4}
        inputRef={session_contentRef}
        {...session_contentReg}
        disabled={disabled}
        error={errors.session_content}
        helperText={errors.session_content && errors.session_content.message}
      />
      <Typography variant="body2" fontWeight={500} marginTop="2rem !important">
        INTERVENTION OPTIONS
      </Typography>
      <CustomSelectField
        fullWidth
        variant="standard"
        options={interventionData || []}
        value={intervention}
        onChange={(e) => handleSetValue("intervention", e.target.value)}
        MenuProps={MenuProps}
        disabled={disabled}
        error={errors.intervention}
        helperText={errors.intervention && errors.intervention.message}
      />
      <Typography variant="body2" fontWeight={500} marginTop="2rem !important">
        INTERVENTION DESCRIPTION
      </Typography>

      <textArea
        type="text"
        className="mr-3 mt-5 border-b-2 sm:w-80 md:w-full"
        disabled={disabled}
        {...register("text_intervention_field")}
      ></textArea>

      <Typography variant="body2" fontWeight={500} marginTop="2rem !important">
        PROGRESS TOWARDS GOALS
      </Typography>
      <CustomTextField
        variant="standard"
        multiline
        rows={4}
        inputRef={progressRef}
        {...progressReg}
        disabled={disabled}
        error={errors.progress}
        helperText={errors.progress && errors.progress.message}
      />
      <Typography variant="body2" fontWeight={500} marginTop="2rem !important">
        PLAN
      </Typography>
      <CustomTextField
        variant="standard"
        multiline
        rows={4}
        inputRef={planRef}
        {...planReg}
        disabled={disabled}
        error={errors.plan}
        helperText={errors.plan && errors.plan.message}
      />
      <Typography variant="body2" fontWeight={500} marginTop="2rem !important">
        NEXT STEPS
      </Typography>
      <CustomSelectField
        fullWidth
        variant="standard"
        options={[
          {
            id: 1,
            label: "Continue Current Therapeutic Focus",
            value: "Continue Current Therapeutic Focus",
          },
          {
            id: 2,
            label: "Change Treatment Goals or Objectives",
            value: "Change Treatment Goals or Objectives",
          },

          { id: 3, label: "Terminate Treatment", value: "Terminate Treatment" },
        ]}
        value={nextStep}
        onChange={(e) => handleSetValue("next_step", e.target.value)}
        disabled={disabled}
        error={errors.next_step}
        helperText={errors.next_step && errors.next_step.message}
      />
    </div>
  );
};

export default StandardProgrssNote;
