import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import RouteConstants from "Routes/RouteConstants";
import { useAPI, useToast } from "services";
import * as Sentry from "@sentry/react";

const SignupUser = (body) => {
  const method = "POST";
  const url = `api/coach/register`;
  const payload = {
    ...body,
  };
  return useAPI({ method, url, body: { ...payload } });
};
const VerifyOtp = (body) => {
  const method = "POST";
  const url = `api/coach/verify-register-otp`;
  return useAPI({ method, url, body });
};
const ResendOtp = (body) => {
  const method = "POST";
  const url = `api/coach/resend-register-otp`;
  return useAPI({ method, url, body });
};

export const SignupUserQuery = () => {
  return useMutation(SignupUser, {
    onSuccess: (response) => {},
    onError: ErrorHandler,
  });
};
export const VerifyOtpQuery = () => {
  const history = useHistory();
  const responseToast = useToast();
  const queryClient = useQueryClient();

  return useMutation(VerifyOtp, {
    onSuccess: (response) => {
      localStorage.clear();
      queryClient.invalidateQueries();
      localStorage.setItem("token", response.token);

      responseToast.toastSuccess(
        response.description || "Logged In Successfully"
      );
      history.push(`${RouteConstants.PRACTICE}?tutorial=true`);
    },
    onError: ErrorHandler,
  });
};
export const ResendOtpQuery = () => {
  return useMutation(ResendOtp, {
    onSuccess: (response) => {},
    onError: ErrorHandler,
  });
};

const ErrorHandler = (error) => {
  const responseToast = useToast();
  Sentry.captureException(error);

  if (error?.errors) {
    responseToast.toastError("Something went wrong!", error.errors);
  } else if (error?.message) {
    responseToast.toastError(error.message);
  }
};
