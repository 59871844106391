const RouteConstants = {
  REDIRECTOR: "/",
  SIGNUP: "/sign-up",
  LOGIN: "/login",
  CLIENTS: "/clients",
  COURSES: "/courses",
  LESSONS: "/lessons",
  HABITS: "/habits",
  PRACTICE: "/practice",
  PROFILE: "/profile",
  CHECKINS: "/checkins",
};

export default RouteConstants;
