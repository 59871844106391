import { useContext, useEffect, useState } from "react";
import {
  Chat,
  Channel,
  LoadingIndicator,
  MessageInput,
  MessageList,
  Thread,
  Window,
} from "stream-chat-react";
import { CustomChannelHeader } from "./CustomHeader";

import { StreamChat } from "stream-chat";
import { FetchCoachTokenQuery } from "../../ClientQueries";
import { FetchProfileDetailsQuery } from "Pages/Profile/ProfileQueries";
import "stream-chat-react/dist/css/index.css";
import { CustomMessageInput } from "./CustomInput";
import { ClientContext } from "utils/Context/ClientContext";
import "./ChatBox.scss";

const ChatBoxModal = (props) => {
  const { memberId, isAdd, memberEmail, handleClose } = props;
  const { data: coachChatToken } = FetchCoachTokenQuery();
  const { data: profileDetails } = FetchProfileDetailsQuery();
  const [coachDetails, setCoachDetails] = useState({
    id: "",
  });
  const {currentClient, currentFamily} = useContext(ClientContext);
  const [chatConnected, setChatConnected] = useState(false);
  const apiKey = process.env.REACT_APP_GET_STREAM_API_KEY;
  const client = StreamChat.getInstance(apiKey);

  const [channel, setChannel] = useState([]);
  const [filter, setFilter] = useState({});
  const [member, setMember] = useState("");
  const sort = { last_message_at: -1 };

  useEffect(() => {
    const setupClient = async () => {
      try {
        await client.connectUser(
          {
            id: coachDetails.id,
          },
          coachChatToken?.token
        );
        setChatConnected(true);
        const channels = await client.queryChannels(filter, sort, {
          watch: true, // this is the default
          state: true,
          limit: 30,
        });

        const singleChannel = channels?.filter((item) => {
          if (Object.keys(item.state.members).includes(member)) {
            return item;
          }
        });
        setChannel(singleChannel);
      } catch (err) {}
    };

    if (Boolean(coachDetails?.id) && coachChatToken?.token && member && currentClient) {
      setupClient();
    }
  }, [coachDetails, coachChatToken, filter, member]);


  useEffect(() => {
    const setupClient = async () => {
      try {
        await client.connectUser(
          {
            id: coachDetails.id,
          },
          coachChatToken?.token
        );
        setChatConnected(true);

        // Directly fetch the channel by channelId
        const channel = client.channel('messaging', currentFamily?.channel_id);
        await channel.watch();

        setChannel([channel]); // Note that now we're setting a single channel object instead of an array
      } catch (err) {
        console.log('Error setting up the chat client:', err);
      }
    };
    if (Boolean(coachDetails?.id) && coachChatToken?.token && currentFamily?.channel_id && !currentClient) {
      setupClient();
    }
  }, [coachDetails, coachChatToken, currentFamily]);

  // useEffects
  useEffect(() => {
    if (profileDetails?.data) {
      setCoachDetails({
        id: profileDetails?.data?.uuid,
      });
    }
  }, [profileDetails]);

  useEffect(() => {
    if (memberId || currentClient?.uuid) {
      setMember(memberId ? memberId : currentClient?.uuid);
    }
    setFilter({
      type: "messaging",
      members: {
        $in: [coachDetails?.id],
      },
    });
  }, [memberId, currentClient, coachDetails]);
  if (!chatConnected) return <LoadingIndicator />;

  return (
    <Chat client={client} theme="messaging light" Input={CustomMessageInput}>
      <Channel channel={channel[0]}>
        <Window>
          <CustomChannelHeader
            handleClose={handleClose}
            isAdd={isAdd}
            memberEmail={memberEmail}
          />
          <MessageList messageActions={["edit", "quote", "react", "delete"]}  />
       
          <MessageInput  additionalTextareaProps={{ placeholder: "Type here..." }}/>
        </Window>
        <Thread />
      </Channel>
    </Chat>
  );
};

export default ChatBoxModal;
