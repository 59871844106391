import { useState, useEffect } from "react";
import { Dialog, Typography, Button, Box } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { intervalToDuration } from "date-fns";
import CareBetterLogo from "assets/Images/CareBetterWordmark.png";
import CustomTextField from "components/FormComponents/CustomTextField";
import {
  ForgotPasswordQuery,
  ResetPasswordQuery,
  VerifyOTPQuery,
} from "./LoginQueries";
import { useLocalStorage } from "utils/CustomHooks/useLocalStorage";
import { useInterval } from "utils/CustomHooks/useInterval";
import OtpInput from "react-otp-input";
import { PasswordField } from "components";

const zeroPad = (num, places) => String(num).padStart(places, "0");

const formattedTime = (seconds) => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });

  return `${zeroPad(duration.minutes, 2)}:${zeroPad(duration.seconds, 2)}`;
};

const ForgotPasswordModal = (props) => {
  const { handleClose, open } = props;
  const history = useHistory();

  //states
  const [activeStep, setActiveStep] = useState(0);

  //handlers
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const getUiForCurrentStep = (step) => {
    switch (step) {
      case 0:
        return <SendOtp handleNext={handleNext} />;
      case 1:
        return <VerifyOtp handleNext={handleNext} />;
      case 2:
        return <ChangePassword handleNext={handleNext} />;
      case 3:
        return <PasswordResetSuccess handleClose={handleClose} />;
      default:
        return <></>;
    }
  };

  //useEffects
  useEffect(() => {
    if (open) setActiveStep(0);
  }, [open]);

  const PasswordResetSuccess = () => {
    return (
      <div className="flex flex-col items-center">
        <Typography variant="body1" className="mb-12 text-center font-semibold">
          Your password has been reset successfully. Please login with your
          newly created password.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClose}
          style={{ marginTop: "1rem" }}
        >
          Login
        </Button>
      </div>
    );
  };

  return (
    <div>
      <Dialog onClose={handleClose} open={open}>
        <div className="p-8">
          <div className="flex-center my-8">
            <img
              src={CareBetterLogo}
              alt="carebetter_logo"
              className="w-64"
            ></img>
          </div>
          <Box p={3}>{getUiForCurrentStep(activeStep)}</Box>
        </div>
      </Dialog>
    </div>
  );
};

/*************** Step1 ***************/
const SendOtp = (props) => {
  const { handleNext } = props;

  //Yup schema definition
  const emailValidSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
  });

  //states
  const [userEmail, setUserEmail] = useLocalStorage(
    "userEmail-forgotPassword",
    ""
  );

  //react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
  } = useForm({
    resolver: yupResolver(emailValidSchema),
    defaultValues: {
      email: JSON.parse(
        localStorage.getItem("userEmail-forgotPassword") || '""'
      ),
    },
  });
  const { ref: refEmail, ...RegisterEmail } = register("email");

  //useEffects
  useEffect(() => {
    setFocus("email");
  }, [setFocus]);
  useEffect(() => {
    localStorage.setItem("otp-timer-forgot-password", 60);
  }, []);

  //queries
  const { mutate: ValidateResetEmail } = ForgotPasswordQuery();

  //handlers
  const onSubmit = (data) => {
    setUserEmail(data?.email);
    ValidateResetEmail(data, { onSuccess: handleNext });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="body1" className="mb-12 font-medium">
        Enter your email and we will send you an One Time Password.
      </Typography>
      <CustomTextField
        label="Email *"
        type="email"
        inputRef={refEmail}
        {...RegisterEmail}
        error={Boolean(errors.email)}
        helperText={errors && errors.email && errors.email.message}
        autoComplete="email"
      />
      <div className="mt-12 flex flex-col items-center">
        <Button type="submit" variant="contained" color="primary">
          Send One Time Password
        </Button>
      </div>
    </form>
  );
};

/*************** Step2 ***************/
const VerifyOtp = (props) => {
  const { handleNext } = props;

  //states
  const [otpTimer, setOtpTimer] = useLocalStorage(
    "otp-timer-forgot-password",
    60
  );

  const [isTimerPlaying, setIsTimerPlaying] = useState(false);

  //useEffects
  useEffect(() => {
    if (otpTimer > 0) {
      setIsTimerPlaying(true);
    }
  }, []);

  //customHooks
  useInterval(
    () => {
      if (otpTimer > 0) {
        setOtpTimer((prevOtpTimer) => prevOtpTimer - 1);
      } else {
        setIsTimerPlaying(false);
      }
    },
    isTimerPlaying ? 1000 : null
  );

  //Yup schema definition
  const otpValidationSchema = yup.object().shape({
    otp: yup
      .string()
      .min(4, "One Time Password should be atleast 4 digits")
      .required("One Time Password is required"),
  });

  //react-hook-form
  const {
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({ resolver: yupResolver(otpValidationSchema) });

  const { otp } = watch();
  const handleChangeValue = (field, value) => {
    setValue(field, value, { shouldValidate: true });
  };
  //queries
  const { mutate: ValidateOtp } = VerifyOTPQuery();
  const { mutate: ValidateResetEmail } = ForgotPasswordQuery();

  //handlers
  const onSubmit = (data) => {
    ValidateOtp(
      {
        ...data,
        email: JSON.parse(
          localStorage.getItem("userEmail-forgotPassword") || '""'
        ),
      },
      { onSuccess: handleNext }
    );
  };
  const handleResendOTP = () => {
    setOtpTimer(60);
    setIsTimerPlaying(true);

    ValidateResetEmail({
      email: JSON.parse(
        localStorage.getItem("userEmail-forgotPassword") || '""'
      ),
    });
  };

  // console.log("timer", otpTimer);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="body1" className="mb-12 font-medium">
        Enter the One Time Password sent to your email to change your password.
      </Typography>
      <div className="flex flex-col items-end">
        <div className="flex w-full justify-center">
          <OtpInput
            value={otp}
            onChange={(value) => {
              handleChangeValue("otp", value);
            }}
            numInputs={4}
            separator={<span>-</span>}
            inputStyle={{
              background: "#F3F6F9",
              borderRadius: "4px",
              margin: ".5rem",
              color: "black",
              padding: ".5rem .2rem",
              width: "4rem",
            }}
            hasErrored={Boolean(errors.otp)}
            errorStyle={{ border: "1px solid red" }}
            focusStyle={{ outline: "none" }}
            isInputNum
          />
        </div>
        <div className="flex w-full justify-start px-3">
          <p className="text-xs" style={{ color: "red" }}>
            {errors && errors.otp && `*${errors.otp.message}`}
          </p>
        </div>
        <Button
          className="relative right-0"
          disabled={isTimerPlaying}
          onClick={handleResendOTP}
        >
          Resend One Time Password
        </Button>
        <p className="relative -left-3 text-sm font-medium">
          Time until One Time Password expires: {formattedTime(otpTimer)}
        </p>
      </div>

      <div className="mt-8 flex flex-col items-center">
        <Button type="submit" variant="contained" color="primary">
          Verify One Time Password
        </Button>
      </div>
    </form>
  );
};

/*************** Step3 ***************/
const ChangePassword = (props) => {
  const { handleNext } = props;

  //Yup schema definition
  const passwordValidationSchema = yup.object().shape({
    password: yup
      .string()
      .required("Please Enter your password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),

    confirm_password: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  //react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(passwordValidationSchema) });
  const { ref: refPassword, ...RegisterPassword } = register("password");
  const { ref: refConfirmPassword, ...RegisterConfirmPassword } =
    register("confirm_password");

  //queries
  const { mutate: ValidatePassword } = ResetPasswordQuery();

  //handlers
  const onSubmit = (data) => {
    ValidatePassword(
      {
        ...data,
        email: JSON.parse(
          localStorage.getItem("userEmail-forgotPassword") || '""'
        ),
      },
      { onSuccess: handleNext }
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="body1" className="mb-8 text-center font-medium">
        Enter your new password.
      </Typography>
      <PasswordField
        variant="standard"
        label="Password *"
        type="password"
        inputRef={refPassword}
        {...RegisterPassword}
        error={Boolean(errors.password)}
        helperText={errors && errors.password && errors.password.message}
        autoComplete="password"
      />
      <PasswordField
        variant="standard"
        label="Confirm Password *"
        type="password"
        inputRef={refConfirmPassword}
        {...RegisterConfirmPassword}
        error={Boolean(errors.confirm_password)}
        helperText={
          errors && errors.confirm_password && errors.confirm_password.message
        }
        autoComplete="confirm_password"
      />
      <div className="mt-8 flex flex-col  items-center">
        <Button type="submit" variant="contained" color="primary">
          Change Password
        </Button>
      </div>
    </form>
  );
};

export default ForgotPasswordModal;
