import {
  Autocomplete,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { FetchDiagnosisDataQuery } from "Pages/Clients/ClientQueries";
import CustomTextField from "components/FormComponents/CustomTextField";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { HiInformationCircle } from "react-icons/hi";

const Soap = ({ disabled }) => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const presenting_data = useWatch({
    control,
    name: "presenting_data",
  });
  //  queries
  const { data: diagnosisData } = FetchDiagnosisDataQuery();
  const { ref: SubjectiveComplaintRef, ...SubjectiveComplaintReg } = register(
    "Subjective_Complaint"
  );

  const { ref: ObjectiveFindingsRef, ...ObjectiveFindingsReg } =
    register("Objective_Findings");
  const { ref: AssessmentOfProgressRef, ...AssessmentOfProgressReg } = register(
    "Assessment_of_Progress"
  );
  const { ref: NextSessionPlansRef, ...NextSessionPlansReg } = register(
    "Plans_for_Next_Session"
  );

  return (
    <div className=" mx-auto my-8 flex  max-w-5xl flex-col gap-8 ">
      <div>
        <Typography
          variant="body2"
          fontWeight={500}
          marginTop="2rem !important"
          marginBottom={1}
        >
          Diagnosis:
        </Typography>

        <Autocomplete
          value={presenting_data || []}
          onChange={(event, value) => {
            let ids = value?.map((val) => val.id);

            if (ids) setValue("presenting_data", value);
          }}
          options={
            diagnosisData?.data?.map((item) => {
              return {
                id: item.id,
                name: `${item.code} - ${item.name}`,
              };
            }) || []
          }
          disableCloseOnSelect
          disabled={disabled}
          getOptionLabel={(option) => option.name}
          renderOption={(prop, option) => <li {...prop}>{option?.name}</li>}
          isOptionEqualToValue={(option, value) => {
            return Boolean(option.id === value.id);
          }}
          multiple
          renderInput={(params) => (
            <TextField
              className="borderless-select"
              {...params}
              variant="standard"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={disabled}
                      onClick={() =>
                        openInNewTab("https://habitbetter.com/diagnosticcodes/")
                      }
                    >
                      <HiInformationCircle className="w-6 text-hb_orange-dark" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </div>
      <CustomTextField
        variant="standard"
        label="Subjective Complaint"
        multiline
        rows={4}
        placeholder="Patients thoughts, feelings, quotes"
        inputRef={SubjectiveComplaintRef}
        {...SubjectiveComplaintReg}
        error={Boolean(errors.Subjective_Complaint)}
        helperText={
          errors.Subjective_Complaint && errors.Subjective_Complaint.message
        }
        disabled={disabled}
      />
      <CustomTextField
        variant="standard"
        label="Objective Findings"
        multiline
        rows={4}
        placeholder="mental or general health status; details from therapy appointment"
        inputRef={ObjectiveFindingsRef}
        {...ObjectiveFindingsReg}
        error={Boolean(errors.Objective_Findings)}
        helperText={
          errors.Objective_Findings && errors.Objective_Findings.message
        }
        disabled={disabled}
      />
      <CustomTextField
        variant="standard"
        label="Assessment of Progress"
        multiline
        rows={4}
        placeholder="your professional interpretation of how things are going"
        inputRef={AssessmentOfProgressRef}
        {...AssessmentOfProgressReg}
        error={Boolean(errors.Assessment_of_Progress)}
        helperText={
          errors.Assessment_of_Progress && errors.Assessment_of_Progress.message
        }
        disabled={disabled}
      />
      <CustomTextField
        variant="standard"
        label="Plans for Next Session"
        multiline
        rows={4}
        placeholder="adjustments and/or next steps"
        inputRef={NextSessionPlansRef}
        {...NextSessionPlansReg}
        error={Boolean(errors.Plans_for_Next_Session)}
        helperText={
          errors.Plans_for_Next_Session && errors.Plans_for_Next_Session.message
        }
        disabled={disabled}
      />
    </div>
  );
};

export default Soap;
