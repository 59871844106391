import { useEffect, useState } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  IconButton,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import {
  FetchCoachSubscriptionPlansQuery,
  FetchSavedCardsQuery,
  AddNewCardQuery,
  CreateSubscriptionQuery,
  UpdateSubscriptionQuery,
} from "../ProfileQueries";
import StripeCardComponent from "utils/StripeCardComponent";
import { useQueryClient } from "react-query";
import EmptyCardsPlaceholder from "assets/Images/EmptyCards.svg";
import { RiVisaLine, RiMastercardLine } from "react-icons/ri";
import { IoCardSharp } from "react-icons/io5";
import Lottie from "react-lottie-player";
import checkIconJson from "assets/Icons/check-solid.json";
import PromotionCodeModal from "./PromotionCodeModal";

const SubscriptionModal = (props) => {
  const {
    open,
    handleClose,
    currentSubscription,
    currentStep,
    setCurrentStep,
  } = props;
  //states
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [plans, setPlans] = useState([]);
  const [pricingSelected, setPricingSelected] = useState(null);

  //queries
  const queryClient = useQueryClient();
  const { data: subscriptionPlans } = FetchCoachSubscriptionPlansQuery();
  const { mutateAsync: AddNewCard } = AddNewCardQuery();
  const { mutateAsync: CreateSubscription } = CreateSubscriptionQuery();
  const { mutateAsync: UpdateSubscription } = UpdateSubscriptionQuery();

  //handlers
  const handlePlanClick = (id) => {
    setSelectedPlan(id);
  };

  const handlePriceSelected = (price) => {
    setPricingSelected(price);
    setCurrentStep(2);
  };

  const SelectedPlanPricing = () => {
    const plan = plans.find((item) => item.id === selectedPlan);

    return (
      <div className="mt-4 flex  items-center justify-between space-x-4">
        {plan?.prices?.map((item, idx) => (
          <div className="flex justify-center">
            <Button
              disabled={currentSubscription?.data?.price?.name === item?.name}
              className="capitalize"
              color="orange"
              variant="contained"
              onClick={() => {
                handlePriceSelected(item);
              }}
            >
              {item?.name} - ${item?.unit_amount}
            </Button>
          </div>
        ))}
      </div>
    );
  };
  function removeNullProperties(obj) {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      const hasProperties = value && Object.keys(value).length > 0;
      if (value === null || value === undefined || value === "") {
        delete obj[key];
      } else if (typeof value !== "string" && hasProperties) {
        removeNullProperties(value);
      }
    });
    return obj;
  }
  const handleStepChange = (step, paymentMethodId) => {
    if (paymentMethodId) {
      const payload = {
        paymentMethod: paymentMethodId,
        primary: 1,
      };
      AddNewCard(payload).then((res) => {
        queryClient.invalidateQueries("GET_SAVED_CARDS");
        setCurrentStep(step);
      });
    } else {
      setCurrentStep(step);
    }
  };
  const handleCreateSubscription = (paymentMethod, selectedPromoCode) => {
    const product = plans.find((item) => item.id === selectedPlan);
    if (currentSubscription?.data) {
      const payload = {
        paymentMethod,
        plan: pricingSelected?.stripe_price_id,
        oldPlan: currentSubscription?.data?.stripe_price,
      };
      UpdateSubscription(payload).then((res) => handleStepChange(4));
    } else {
      const payload = {
        paymentMethod,
        plan: pricingSelected?.stripe_price_id,
        product: product?.name,
        product_id: product?.stripe_product_id,
        interval: pricingSelected?.interval,
        code: selectedPromoCode,
      };
      CreateSubscription(removeNullProperties(payload)).then((res) =>
        handleStepChange(4)
      );
    }
  };

  //useEffects
  useEffect(() => {
    if (subscriptionPlans?.data) {
      setPlans(subscriptionPlans?.data);
    }
  }, [subscriptionPlans]);

  useEffect(() => {
    if (currentSubscription?.data?.price?.product?.id) {
      setSelectedPlan(currentSubscription?.data?.price?.product?.id);
    }
  }, [currentSubscription, open]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      maxWidth={currentStep === 4 ? "md" : "md"}
    >
      {currentStep === 1 && (
        <>
          <DialogTitle className="font-semibold" color="primary">
            <Grid
              container
              xs={12}
              direction="row"
              className="justify-between"
              alignItems="center"
            >
              SUBSCRIPTION PLAN
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <div className="my-4">
              <div className="-mt-2 mb-4">
                <p className="text-lg text-hb_blue-700">
                  In subscription plan you will get access to:
                </p>
              </div>
              <div
                className="flex justify-center space-x-4 "
                style={{
                  height: "404px",
                }}
              >
                {plans?.map((plan) => (
                  <>
                    <div
                      className="flex cursor-pointer justify-center "
                      onClick={() => {
                        handlePlanClick(plan.id);
                      }}
                    >
                      <div
                        className="rounded-lg "
                        style={
                          selectedPlan === plan.id
                            ? {
                                border: "1px solid #FF6C44",
                                backgroundColor: "#6089FF0F",
                              }
                            : { backgroundColor: "#6089FF0F" }
                        }
                      >
                        <div
                          className="flex justify-center  rounded-lg p-2"
                          style={
                            selectedPlan === plan.id
                              ? { background: "#FF6C44" }
                              : { border: "1px solid #00000099" }
                          }
                        >
                          <p
                            className="text-sm font-bold"
                            style={
                              selectedPlan === plan.id
                                ? { color: "white" }
                                : { color: "#FF6C44" }
                            }
                          >
                            {plan.name}
                          </p>
                        </div>
                        <div
                          className="flex flex-col space-y-4 overflow-y-auto p-5"
                          style={{
                            height: "363px",
                          }}
                        >
                          <div>
                            <p className="text-base text-hb_blue-800">
                              Get Access To :
                            </p>
                          </div>
                          <div>
                            <div className="flex flex-col  space-x-2  ">
                              <ul>
                                {Object.entries({
                                  ...plan?.metadata,
                                })?.map(([el, it], idx) => {
                                  return (
                                    <p
                                      style={{
                                        color: "#0000004D",
                                        fontSize: "14.5px",
                                      }}
                                    >
                                      <li className="list-disc ">{it}</li>
                                    </p>
                                  );
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
              <div className="flex justify-center">
                <SelectedPlanPricing />
              </div>
            </div>
          </DialogContent>
        </>
      )}
      {currentStep === 2 && (
        <CardsView
          handleStepChange={handleStepChange}
          handleCreateSubscription={handleCreateSubscription}
          selectedPlan={selectedPlan}
          plans={plans}
          currentData={currentSubscription?.data}
        />
      )}
      {currentStep === 3 && <AddCard handleStepChange={handleStepChange} />}
      {currentStep === 4 && (
        <>
          {/* <DialogTitle>Success</DialogTitle> */}
          <DialogContent dividers>
            <div className="flex flex-col items-center justify-center space-y-8">
              <div className="space-y-2 text-center">
                {/* <CheckCircleIcon  />
                 */}
                {/* <img
                  src={checkIcon}
                  className="mx-auto h-20 w-20 "
                  alt="sucess icon"
                /> */}
                <Lottie
                  loop
                  animationData={checkIconJson}
                  play
                  style={{
                    width: "182px",
                    height: "182px",
                    // marginLeft: "3rem",
                    margin: "auto",
                  }}
                />
                <p
                  className="px-8 text-hb_blue-800"
                  style={{ fontSize: "28px" }}
                >
                  You are successfully subscribed
                </p>
              </div>
              <div>
                <Button
                  color="orange"
                  variant="contained"
                  style={{ paddingInline: "2rem", paddingBlock: ".5rem" }}
                  onClick={handleClose}
                >
                  Continue
                </Button>
              </div>
            </div>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

const CardsView = (props) => {
  const {
    handleStepChange,
    handleCreateSubscription,
    selectedPlan,
    plans,
    currentData,
  } = props;
  const { data: savedCards } = FetchSavedCardsQuery();
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedPromoCode, setSelectedPromoCode] = useState(null);

  const [selectedPromotionCard, SetselectedPromotionCard] = useState(false);
  //handlers
  const handlePromotionCodeModalOpen = () => {
    SetselectedPromotionCard(true);
  };
  const handlePromotionCodeModalClose = () => {
    SetselectedPromotionCard(false);
  };
  // const hanldeDeletePromoCode = () => {
  //   localStorage.removeItem("CODE_APPLIED");
  // };
  const hanldeDeletePromoCode = () => {
    setSelectedPromoCode(null);
  };
  const product = plans.find((item) => item.id === selectedPlan);

  //useEffect
  useEffect(() => {
    if (
      Array.isArray(savedCards?.data?.data) &&
      savedCards?.data?.data?.length > 0
    ) {
    } else {
    }
  }, [savedCards]);

  const CardDetail = (item) => {
    return (
      <div className="my-4 flex w-full">
        <PromotionCodeModal
          open={selectedPromotionCard}
          handleClose={handlePromotionCodeModalClose}
          product={product}
          setSelectedPromoCode={setSelectedPromoCode}
        />
        <div className="w-full p-4" style={{ background: "#E6EFF2" }}>
          {item?.item?.card?.brand === "visa" ? (
            <RiVisaLine style={{ fontSize: "2rem" }} />
          ) : item?.item?.card?.brand === "master" ? (
            <RiMastercardLine />
          ) : (
            <IoCardSharp />
          )}
          <div className="mt-6">
            <div className="flex justify-between">
              <p>XXXX {item?.item?.card?.last4}</p>
              <p>
                Exp: {item?.item?.card?.exp_month} /{" "}
                {item?.item?.card?.exp_year}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <DialogTitle className="text-hb_blue-800">
        <div className="flex justify-between">
          <p>Payment Method</p>
          {Array.isArray(savedCards?.data?.data) &&
            savedCards?.data?.data?.length > 0 && (
              <Button
                onClick={() => handleStepChange(3)}
                color="orange"
                variant="contained"
                style={{ paddingBlock: ".5rem", paddingInline: "2rem" }}
              >
                Add New Card
              </Button>
            )}
        </div>
      </DialogTitle>

      <DialogContent>
        {Array.isArray(savedCards?.data?.data) &&
        savedCards?.data?.data?.length > 0 ? (
          <div>
            <div className="flex flex-col justify-center space-y-4">
              <RadioGroup
                name="cards-radio-btns-group"
                value={selectedCard || ""}
                onChange={(e) => setSelectedCard(e.target.value)}
                // defaultValue={selectedCard}
              >
                {savedCards?.data?.data?.map((item) => (
                  <FormControlLabel
                    value={item?.id}
                    control={<Radio />}
                    label={<CardDetail item={item} />}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        width: "100%",
                      },
                    }}
                  />
                ))}
              </RadioGroup>
              {!currentData && (
                <div className="flex cursor-pointer items-center justify-center">
                  {selectedPromoCode ? (
                    <div
                      className="flex items-center justify-center font-semibold"
                      style={{ color: "#097969" }}
                    >
                      <p>{`${selectedPromoCode} Coupon Applied`}</p>
                      <IconButton
                        aria-label="close"
                        onClick={hanldeDeletePromoCode}
                        // onDelete={hanldeDeletePromoCode}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  ) : (
                    <p
                      className="text-hb_blue-800"
                      onClick={() => handlePromotionCodeModalOpen(savedCards)}
                    >
                      Have a Promocode?
                    </p>
                  )}
                </div>
              )}
            </div>
            <div className="my-4 flex justify-center">
              <Button
                onClick={() =>
                  handleCreateSubscription(selectedCard, selectedPromoCode)
                }
                color="orange"
                variant="contained"
                style={{ paddingBlock: ".5rem", paddingInline: "2rem" }}
              >
                Continue to Payment
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center space-y-4">
            <div className="text-center">
              <img alt="no-cards" src={EmptyCardsPlaceholder} />
              <p>No cards found</p>
            </div>
            <div>
              <Button
                color="orange"
                variant="contained"
                style={{ paddingBlock: ".5rem", paddingInline: "1rem" }}
                onClick={() => handleStepChange(3)}
              >
                Add new card
              </Button>
            </div>
          </div>
        )}
      </DialogContent>
    </>
  );
};

const AddCard = (props) => {
  const { handleStepChange } = props;
  return (
    <>
      <DialogTitle className="text-hb_blue-800">Payment Method</DialogTitle>
      <DialogContent>
        <StripeCardComponent handleStepChange={handleStepChange} />
      </DialogContent>
    </>
  );
};

export default SubscriptionModal;

/*
 */
