import React, { useState } from "react";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";

// Sample data (an example similar to the one you provided)
const ClientCheckboxes = ({
  familiesList,
  handleSelectClientParent,
  idsConstants,
}) => {
  const [selectedFamilies, setSelectedFamilies] = useState({});

  // Function to handle individual user/client selection
  const handleSelectClient = (familyId, userId, checked, name) => {
    setSelectedFamilies((prev) => ({
      ...prev,
      [familyId]: {
        ...prev[familyId],
        [userId]: checked,
      },
    }));
    handleSelectClientParent(userId, name, checked);
  };

  // Function to handle select/unselect all clients within a family
  const handleSelectAllClients = (familyId, checked) => {
    const updatedUsers = {};
    familiesList.forEach((family) => {
      if (family?.[`${idsConstants["parentId"]}`] === familyId) {
        family.users.forEach((user) => {
          updatedUsers[user.id] = checked;
          handleSelectClientParent(user.id, user.name, checked);
        });
      }
    });

    setSelectedFamilies((prev) => ({
      ...prev,
      [familyId]: updatedUsers,
    }));
  };

  return (
    <FormGroup>
      {Array.isArray(familiesList) && familiesList.length > 0 ? (
        <>
          {familiesList.map((family) => {
            const allSelected = family.users.every(
              (user) =>
                selectedFamilies[family?.[`${idsConstants["parentId"]}`]]?.[
                  user.id
                ]
            );
            const indeterminate =
              family.users.some(
                (user) =>
                  selectedFamilies[family?.[`${idsConstants["parentId"]}`]]?.[
                    user.id
                  ]
              ) && !allSelected;

            return (
              <React.Fragment key={family?.[`${idsConstants["parentId"]}`]}>
                {/* Header Checkbox */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allSelected}
                      indeterminate={indeterminate}
                      onChange={(e) =>
                        handleSelectAllClients(
                          family?.[`${idsConstants["parentId"]}`],
                          e.target.checked
                        )
                      }
                      sx={{
                        "& .MuiSvgIcon-root": { fontSize: 28 },
                        "&.Mui-checked": {
                          color: "#FF6C44",
                        },
                      }}
                    />
                  }
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: "1.125rem",
                      color: "#264265",
                      fontWeight: "bold",
                    },
                  }}
                  label={
                    family?.[`${idsConstants["parentName"]}`] ||
                    family?.family?.[`${idsConstants["parentName"]}`]
                  }
                />

                {/* Individual User/Client Checkboxes */}
                {family.users.map((user) => (
                  <FormControlLabel
                    className="ml-6"
                    key={user.id}
                    control={
                      <Checkbox
                        checked={
                          !!selectedFamilies[
                            family?.[`${idsConstants["parentId"]}`]
                          ]?.[user.id]
                        }
                        onChange={(e) =>
                          handleSelectClient(
                            family?.[`${idsConstants["parentId"]}`],
                            user.id,
                            e.target.checked,
                            user.name
                          )
                        }
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 28 },
                          "&.Mui-checked": {
                            color: "#FF6C44",
                          },
                        }}
                      />
                    }
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "1.125rem",
                        color: "#264265",
                      },
                    }}
                    label={user.name}
                  />
                ))}
              </React.Fragment>
            );
          })}
        </>
      ) : (
        <div className="mt-4 mb-6 text-center">
          <p className="text-2xl font-medium text-hb_gray-700">
            No clients found!
          </p>
        </div>
      )}
    </FormGroup>
  );
};

// Usage of the component
const CustomHeaderCheckBox = ({
  familiesList,
  handleSelectClient,
  idsConstants,
}) => {
  // Assuming fetchedClientsList.data is passed down as props
  return (
    <div>
      <ClientCheckboxes
        familiesList={familiesList}
        handleSelectClientParent={handleSelectClient}
        idsConstants={idsConstants}
      />
    </div>
  );
};

export default CustomHeaderCheckBox;
