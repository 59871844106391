import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { HiInformationCircle } from "react-icons/hi";
import React, { useContext, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FetchDiagnosisDataQuery } from "Pages/Clients/ClientQueries";
import { ClientContext } from "utils/Context";

const TreatmentPlan = ({ disabled, isEdit,  selectedMembers, setSelectedMembers }) => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  //  queries
  const { data: diagnosisData } = FetchDiagnosisDataQuery();
  const {
    register,
    control,
    setValue,
    watch: formDataValues,
  } = useFormContext();

  const presenting_data = useWatch({
    control,
    name: "presenting_data",
  });

  const { session_frequencies_other, outside_system_other } = formDataValues();
  const {currentClient, currentFamily} = useContext(ClientContext);

  const allUsersChecked = () =>
    currentFamily?.users?.length > 0 &&
    selectedMembers.length === currentFamily.users.length;
  const indeterminate = () =>
    selectedMembers.length > 0 &&
    selectedMembers.length < currentFamily.users.length;

  const handleSelectAllClients = (checked) => {
    if (checked) {
      setSelectedMembers(currentFamily.users.map((user) => user.id));
    } else {
      setSelectedMembers([]);
    }
  };

  const handleMemberChange = (id) => {
    setSelectedMembers((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((memberId) => memberId !== id)
        : [...prevSelected, id]
    );
  };

  useEffect(() => {
    if(currentClient){
      setValue("type_session1", "type_session1")
    }
    else if(!currentClient && currentFamily){
      if(currentFamily?.users?.[0]?.invite?.client_type === "family") {
        setValue("type_session2", "type_session2")
      }
      else if(currentFamily?.users?.[0]?.invite?.client_type === "couple") {
        setValue("type_session3", "type_session3")
      }
    }
    else {
      setValue("type_session4", "type_session4")
    }

  },[currentClient, currentFamily])

  return (
    <div className="my-8 space-y-8">
      <div>
        <Typography
          variant="body2"
          fontWeight={500}
          marginTop="2rem !important"
          marginBottom={1}
        >
          Type of Session:
        </Typography>
        <input
          type="checkbox"
          className="form-check-input mt-1 mr-2 h-4 w-4 cursor-pointer rounded-sm border border-gray-300 bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-blue-600 checked:bg-blue-600"
          id="Session1"
          disabled={true}
          {...register("type_session1")}
        />
        <label
          className="form-check-label mr-3 text-gray-800"
          htmlFor="Session1"
        >
          Individual
        </label>

        <input
          type="checkbox"
          className="form-check-input mt-1 mr-2 h-4 w-4 cursor-pointer rounded-sm border border-gray-300 bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-blue-600 checked:bg-blue-600"
          id="Session2"
          disabled={true}
          {...register("type_session2")}
        />
        <label
          className="form-check-label mr-3 text-gray-800"
          htmlFor="Session2"
        >
          Family
        </label>

        <input
          type="checkbox"
          className="form-check-input mt-1 mr-2 h-4 w-4 cursor-pointer rounded-sm border border-gray-300 bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-blue-600 checked:bg-blue-600"
          id="Session3"
          disabled={true}
          {...register("type_session3")}
        />
        <label
          className="form-check-label mr-3 text-gray-800"
          htmlFor="Session3"
        >
          Couples
        </label>

        <input
          type="checkbox"
          className="form-check-input mt-1 mr-2 h-4 w-4 cursor-pointer rounded-sm border border-gray-300 bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-blue-600 checked:bg-blue-600"
          id="Session4"
          disabled={true}
          {...register("type_session4")}
        />
        <label
          className="form-check-label mr-3 text-gray-800"
          htmlFor="Session4"
        >
          Group
        </label>

      {!currentClient && currentFamily && <div className="my-2">
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={isEdit}
                      checked={allUsersChecked()}
                      indeterminate={indeterminate()}
                      onChange={(e) => handleSelectAllClients(e.target.checked)}
                      sx={{
                        "& .MuiSvgIcon-root": { fontSize: 20 },
                        "&.Mui-checked": { color: "#FF6C44" },
                      }}
                    />
                  }
                  sx={{
                    "& .MuiFormControlLabel-label": { 
                      fontWeight: 900,
                    },
                  }}
                  label="All members"
                />
                <div className="flex flex-wrap">
                  {currentFamily?.users?.map((user) => (
                    <FormControlLabel
                      key={user.id}
                      disabled={isEdit}
                      control={
                        <Checkbox
                          checked={selectedMembers.includes(user.id)}
                          onChange={() => handleMemberChange(user.id)}
                          sx={{
                            "& .MuiSvgIcon-root": { fontSize: 20 },
                            "&.Mui-checked": { color: "#FF6C44" },
                          }}
                        />
                      }
                      label={user.name}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "1rem",
                          color: "#264265",
                        },
                      }}
                    />
                  ))}
                </div>
              </div>}
      </div>
      <div>
        <Typography
          variant="body2"
          fontWeight={500}
          marginTop="2rem !important"
          marginBottom={1}
        >
          Frequency of Sessions:
        </Typography>
        <input
          type="checkbox"
          className="form-check-input mt-1 mr-2 h-4 w-4 cursor-pointer rounded-sm border border-gray-300 bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-blue-600 checked:bg-blue-600"
          id="checkbox_Session1"
          disabled={disabled}
          {...register("session_frequencies1")}
        />
        <label
          className="form-check-label mr-3 text-gray-800"
          htmlFor="checkbox_Session1"
        >
          Weekly
        </label>
        <input
          type="checkbox"
          className="form-check-input mt-1 mr-2 h-4 w-4 cursor-pointer rounded-sm border border-gray-300 bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-blue-600 checked:bg-blue-600"
          id="checkbox_Session2"
          disabled={disabled}
          {...register("session_frequencies2")}
        />
        <label
          className="form-check-label mr-3 text-gray-800"
          htmlFor="checkbox_Session2"
        >
          bi-weekly
        </label>
        <input
          type="checkbox"
          className="form-check-input mt-1 mr-2 h-4 w-4 cursor-pointer rounded-sm border border-gray-300 bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-blue-600 checked:bg-blue-600"
          id="checkbox_Session3"
          disabled={disabled}
          {...register("session_frequencies3")}
        />
        <label
          className="form-check-label mr-3 text-gray-800"
          htmlFor="checkbox_Session3"
        >
          Monthly
        </label>
        <input
          type="checkbox"
          className="form-check-input mt-1 mr-2 h-4 w-4 cursor-pointer rounded-sm border border-gray-300 bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-blue-600 checked:bg-blue-600"
          id="checkbox_Session4"
          disabled={disabled}
          {...register("session_frequencies_other")}
        />

        <label
          className="form-check-label mr-3 text-gray-800"
          htmlFor="checkbox_Session4"
        >
          Other
        </label>
        {session_frequencies_other === true && (
          <input
            id="text_other_field"
            placeholder="Other Discusion"
            type="text"
            className="mr-3 mt-5 border-b-2 sm:w-80 md:w-full"
            disabled={disabled}
            {...register("text_other_field")}
          />
        )}
      </div>
      <div>
        <Typography
          variant="body2"
          fontWeight={500}
          marginTop="2rem !important"
          marginBottom={1}
        >
          Diagnosis:
        </Typography>

        <Autocomplete
          value={presenting_data || []}
          onChange={(event, value) => {
            let ids = value?.map((val) => val.id);

            if (ids) setValue("presenting_data", value);
          }}
          options={
            diagnosisData?.data?.map((item) => {
              return {
                id: item.id,
                name: `${item.code} - ${item.name}`,
              };
            }) || []
          }
          disableCloseOnSelect
          disabled={disabled}
          getOptionLabel={(option) => option.name}
          renderOption={(prop, option) => <li {...prop}>{option?.name}</li>}
          isOptionEqualToValue={(option, value) => {
            return Boolean(option.id === value.id);
          }}
          multiple
          renderInput={(params) => (
            <TextField
              className="borderless-select"
              {...params}
              variant="standard"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={disabled}
                      onClick={() =>
                        openInNewTab("https://habitbetter.com/diagnosticcodes/")
                      }
                    >
                      <HiInformationCircle className="w-6 text-hb_orange-dark" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </div>
      <div>
        <Typography
          variant="body2"
          fontWeight={800}
          marginTop="2rem !important"
          marginBottom={1}
        >
          Presenting Problem 1
        </Typography>
        <textArea
          type="text"
          className="mr-3 mt-5 border-b-2 sm:w-80 md:w-full"
          disabled={disabled}
          {...register("text_presenting_field")}
        ></textArea>
        <Typography
          variant="body2"
          fontWeight={500}
          marginTop="2rem !important"
          marginBottom={1}
        >
          Goal
        </Typography>

        <textArea
          type="text"
          className="mr-3 mt-5 border-b-2 sm:w-80 md:w-full"
          disabled={disabled}
          {...register("text_goal_field")}
        ></textArea>

        <Typography
          variant="body2"
          fontWeight={500}
          marginTop="2rem !important"
          marginBottom={1}
        >
          Intervention
        </Typography>

        <textArea
          type="text"
          className="mr-3 mt-5 border-b-2 sm:w-80 md:w-full"
          disabled={disabled}
          {...register("text_intervention_field")}
        ></textArea>
        <Typography
          variant="body2"
          fontWeight={500}
          marginTop="2rem !important"
          marginBottom={1}
        >
          Others
        </Typography>

        <textArea
          type="text"
          className="mr-3 mt-5 border-b-2 sm:w-80 md:w-full"
          disabled={disabled}
          {...register("text_others_field")}
        ></textArea>
        <Typography
          variant="body2"
          fontWeight={500}
          marginTop="2rem !important"
          marginBottom={1}
        >
          Target Date
        </Typography>
        <input
          // id="text_other_field2"
          placeholder="Target Date"
          type="date"
          className="mr-3 mt-5 border-b-2 sm:w-80 md:w-full"
          disabled={disabled}
          {...register("text_targetDate_field")}
        />
      </div>
      <div>
        <Typography
          variant="body2"
          fontWeight={800}
          marginTop="2rem !important"
          marginBottom={1}
        >
          Presenting Problem 2
        </Typography>
        <textArea
          type="text"
          className="mr-3 mt-5 border-b-2 sm:w-80 md:w-full"
          disabled={disabled}
          {...register("text_presenting_field2")}
        ></textArea>

        <Typography
          variant="body2"
          fontWeight={500}
          marginTop="2rem !important"
          marginBottom={1}
        >
          Goal
        </Typography>

        <textArea
          type="text"
          className="mr-3 mt-5 border-b-2 sm:w-80 md:w-full"
          disabled={disabled}
          {...register("text_goal_field1")}
        ></textArea>

        <Typography
          variant="body2"
          fontWeight={500}
          marginTop="2rem !important"
          marginBottom={1}
        >
          Intervention
        </Typography>

        <textArea
          type="text"
          className="mr-3 mt-5 border-b-2 sm:w-80 md:w-full"
          disabled={disabled}
          {...register("text_intervention_field1")}
        ></textArea>
        <Typography
          variant="body2"
          fontWeight={500}
          marginTop="2rem !important"
          marginBottom={1}
        >
          Others
        </Typography>

        <textArea
          type="text"
          className="mr-3 mt-5 border-b-2 sm:w-80 md:w-full"
          disabled={disabled}
          {...register("text_others_field1")}
        ></textArea>
        <Typography
          variant="body2"
          fontWeight={500}
          marginTop="2rem !important"
          marginBottom={1}
        >
          Target Date
        </Typography>
        <input
          placeholder="Target Date"
          type="date"
          className="mr-3 mt-5 border-b-2 sm:w-80 md:w-full"
          disabled={disabled}
          {...register("text_targetDate_field1")}
        />
      </div>{" "}
      <div>
        <Typography
          variant="body2"
          fontWeight={800}
          marginTop="2rem !important"
          marginBottom={1}
        >
          Presenting Problem 3
        </Typography>
        <textArea
          type="text"
          className="mr-3 mt-5 border-b-2 sm:w-80 md:w-full"
          disabled={disabled}
          {...register("text_presenting_field3")}
        ></textArea>

        <Typography
          variant="body2"
          fontWeight={500}
          marginTop="2rem !important"
          marginBottom={1}
        >
          Goal
        </Typography>

        <textArea
          type="text"
          className="mr-3 mt-5 border-b-2 sm:w-80 md:w-full"
          disabled={disabled}
          {...register("text_goal_field2")}
        ></textArea>

        <Typography
          variant="body2"
          fontWeight={500}
          marginTop="2rem !important"
          marginBottom={1}
        >
          Intervention
        </Typography>

        <textArea
          type="text"
          className="mr-3 mt-5 border-b-2 sm:w-80 md:w-full"
          disabled={disabled}
          {...register("text_intervention_field2")}
        ></textArea>
        <Typography
          variant="body2"
          fontWeight={500}
          marginTop="2rem !important"
          marginBottom={1}
        >
          Others
        </Typography>

        <textArea
          type="text"
          className="mr-3 mt-5 border-b-2 sm:w-80 md:w-full"
          disabled={disabled}
          {...register("text_others_field2")}
        ></textArea>
        <Typography
          variant="body2"
          fontWeight={500}
          marginTop="2rem !important"
          marginBottom={1}
        >
          Target Date
        </Typography>
        <input
          placeholder="Target Date"
          type="date"
          className="mr-3 mt-5 border-b-2 sm:w-80 md:w-full"
          disabled={disabled}
          {...register("text_targetDate_field2")}
        />
      </div>
      <div>
        <Typography
          variant="body2"
          fontWeight={800}
          marginTop="2rem !important"
          marginBottom={1}
        >
          Presenting Problem 4
        </Typography>
        <textArea
          type="text"
          className="mr-3 mt-5 border-b-2 sm:w-80 md:w-full"
          disabled={disabled}
          {...register("text_presenting_field4")}
        ></textArea>

        <Typography
          variant="body2"
          fontWeight={500}
          marginTop="2rem !important"
          marginBottom={1}
        >
          Goal
        </Typography>

        <textArea
          type="text"
          className="mr-3 mt-5 border-b-2 sm:w-80 md:w-full"
          disabled={disabled}
          {...register("text_goal_field3")}
        ></textArea>

        <Typography
          variant="body2"
          fontWeight={500}
          marginTop="2rem !important"
          marginBottom={1}
        >
          Intervention
        </Typography>

        <textArea
          type="text"
          className="mr-3 mt-5 border-b-2 sm:w-80 md:w-full"
          disabled={disabled}
          {...register("text_intervention_field3")}
        ></textArea>
        <Typography
          variant="body2"
          fontWeight={500}
          marginTop="2rem !important"
          marginBottom={1}
        >
          Others
        </Typography>

        <textArea
          type="text"
          className="mr-3 mt-5 border-b-2 sm:w-80 md:w-full"
          disabled={disabled}
          {...register("text_others_field3")}
        ></textArea>
        <Typography
          variant="body2"
          fontWeight={500}
          marginTop="2rem !important"
          marginBottom={1}
        >
          Target Date
        </Typography>
        <input
          // id="text_other_field2"
          placeholder="Target Date"
          type="date"
          className="mr-3 mt-5 border-b-2 sm:w-80 md:w-full"
          disabled={disabled}
          {...register("text_targetDate_field3")}
        />
      </div>
      <div>
        <Typography
          variant="body2"
          fontWeight={500}
          marginTop="2rem !important"
          marginBottom={1}
        >
          Outside Systems included in treatment plan
        </Typography>
        <input
          type="checkbox"
          className="form-check-input mt-1 mr-2 h-4 w-4 cursor-pointer rounded-sm border border-gray-300 bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-blue-600 checked:bg-blue-600"
          id="Session1"
          disabled={disabled}
          {...register("outside_systems1")}
        />
        <label
          className="form-check-label mr-3 text-gray-800"
          htmlFor="Session1"
        >
          Family/Caregiver
        </label>
        <input
          type="checkbox"
          className="form-check-input mt-1 mr-2 h-4 w-4 cursor-pointer rounded-sm border border-gray-300 bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-blue-600 checked:bg-blue-600"
          id="outside1"
          disabled={disabled}
          {...register("outside_systems1")}
        />
        <label
          className="form-check-label mr-3 text-gray-800"
          htmlFor="outside1"
        >
          Medical/Treatment Center
        </label>
        <input
          type="checkbox"
          className="form-check-input mt-1 mr-2 h-4 w-4 cursor-pointer rounded-sm border border-gray-300 bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-blue-600 checked:bg-blue-600"
          id="outside2"
          disabled={disabled}
          {...register("outside_systems2")}
        />
        <label
          className="form-check-label mr-3 text-gray-800"
          htmlFor="outside2"
        >
          Community Resource
        </label>

        <input
          type="checkbox"
          className="form-check-input mt-1 mr-2 h-4 w-4 cursor-pointer rounded-sm border border-gray-300 bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-blue-600 checked:bg-blue-600"
          id="outside3"
          disabled={disabled}
          {...register("outside_systems3")}
        />
        <label
          className="form-check-label mr-3 text-gray-800"
          htmlFor="outside3"
        >
          Treatment Providers
        </label>

        <input
          type="checkbox"
          className="form-check-input mt-1 mr-2 h-4 w-4 cursor-pointer rounded-sm border border-gray-300 bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-blue-600 checked:bg-blue-600"
          id="outside4"
          disabled={disabled}
          {...register("outside_systems4")}
        />
        <label
          className="form-check-label mr-3 text-gray-800"
          htmlFor="outside4"
        >
          Treatment Providers
        </label>

        <input
          type="checkbox"
          className="form-check-input mt-1 mr-2 h-4 w-4 cursor-pointer rounded-sm border border-gray-300 bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-blue-600 checked:bg-blue-600"
          id="outside5"
          disabled={disabled}
          {...register("outside_system_other")}
        />
        <label
          className="form-check-label mr-3 text-gray-800"
          htmlFor="outside5"
        >
          Other
        </label>

        {outside_system_other && (
          <input
            id="text_other_field2"
            placeholder="Other Field"
            type="text"
            className="mr-3 mt-5 border-b-2 sm:w-80 md:w-full"
            disabled={disabled}
            {...register("text_other_field2")}
          />
        )}
      </div>
    </div>
  );
};

export default TreatmentPlan;
