import { useState, useEffect } from 'react';
import PersonIcon from "@mui/icons-material/Person";
import PeopleIcon from "@mui/icons-material/People";
import GroupsIcon from '@mui/icons-material/Groups';
import { FetchListOfClientsQuery } from "Pages/Courses/CoursesQueries";
import { FetchFamilyClientsQuery } from 'Pages/Clients/ClientQueries';

const useUserOptions = () => {
    const [userOptions, setUserOptions] = useState([]);
  
    const { data: fetchedClientsList } = FetchListOfClientsQuery();
    const { data: familyClientsList } = FetchFamilyClientsQuery({ familyType: "family" });
    const { data: coupleClientsList } = FetchFamilyClientsQuery({ familyType: "couple" });
  
    useEffect(() => {
      const createUserOptions = () => {
        let clientOptions = [];
        let coupleOptions = [];
        let familyOptions = [];
  
        if (fetchedClientsList?.data) {
          clientOptions = fetchedClientsList.data.map(item => ({
            id: item.id,
            label: item.name,
            value: item.name,
            icon: <PersonIcon className="mr-3" />,
          }));
        }
  
        if (coupleClientsList?.data) {
          coupleOptions = coupleClientsList.data.reduce((acc, family) => {
            return acc.concat(family.users);
          }, []);
  
          coupleOptions = coupleOptions.map(item => ({
            id: item.id,
            label: item.name,
            value: item.name,
            icon: <PeopleIcon className="mr-3" />,
          }));
        }
  
        if (familyClientsList?.data) {
          familyOptions = familyClientsList.data.reduce((acc, family) => {
            return acc.concat(family.users);
          }, []);
  
          familyOptions = familyOptions.map(item => ({
            id: item.id,
            label: item.name,
            value: item.name,
            icon: <GroupsIcon className="mr-3" />,
          }));
        }
  
        return [...clientOptions, ...coupleOptions, ...familyOptions];
      };
  
      setUserOptions(createUserOptions());
    }, [fetchedClientsList, coupleClientsList, familyClientsList]);
  
    return userOptions;
  };
  
  export default useUserOptions;
  