import React, { Suspense, lazy, useLayoutEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import Header from "components/Header";
import RouteConstants from "Routes/RouteConstants";
import { CoachSubscriptionContextProvider } from "utils/Context";
import { LogOutUserQuery } from "Pages/LoginPage/LoginQueries";

const Clients = lazy(() => import("Pages/Clients"));
const Courses = lazy(() => import("Pages/Courses"));
const Habits = lazy(() => import("Pages/Habits"));
const Practice = lazy(() => import("Pages/Practice"));
const Profile = lazy(() => import("Pages/Profile"));
const Checkins = lazy(() => import("Pages/Checkins"));
const CourseDetail = lazy(() => import("Pages/Courses/CourseDetail"));
const LessonDetail = lazy(() => import("Pages/Courses/LessonDetail"));

const HabitDetail = lazy(() => import("Pages/Habits/HabitDetail"));
const CheckinsDetail = lazy(() => import("Pages/Checkins/CheckinsDetail"));

const AppContainer = () => {
  const history = useHistory();
  const {mutate : logout} = LogOutUserQuery();

  //useEffects
  useLayoutEffect(() => {
    const accessToken = localStorage.getItem("token");
    if (accessToken === "null" || !accessToken) {
      logout()
    }
  }, []);

  return (
    <CoachSubscriptionContextProvider>
      <Header />
      <Suspense fallback={null}>
        <Switch>
          <Route path={RouteConstants.CLIENTS} component={Clients} />
          <Route exact path={RouteConstants.COURSES} component={Courses} />
          <Route
            path={`${RouteConstants.COURSES}/:courseID`}
            component={CourseDetail}
          />
          <Route
            path={`${RouteConstants.LESSONS}/:lessonID`}
            component={LessonDetail}
          />
          <Route exact path={RouteConstants.HABITS} component={Habits} />
          <Route
            path={`${RouteConstants.HABITS}/:habitID`}
            component={HabitDetail}
          />
          <Route path={RouteConstants.PRACTICE} component={Practice} />
          <Route path={RouteConstants.PROFILE} component={Profile} />
          <Route exact path={RouteConstants.CHECKINS} component={Checkins} />        
          <Route
            path={`${RouteConstants.CHECKINS}/:checkinID`}
            component={CheckinsDetail}
          />
        </Switch>
      </Suspense>
    </CoachSubscriptionContextProvider>
  );
};

export default AppContainer;
