import {
  Autocomplete,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { FetchDiagnosisDataQuery } from "Pages/Clients/ClientQueries";
import CustomTextField from "components/FormComponents/CustomTextField";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { HiInformationCircle } from "react-icons/hi";

const SuicideRisk = ({ disabled }) => {
  const BehavioralDefinitions = [
    "Frequent thoughts of or fixation with death",
    "Frequent or continuing suicidal thoughts without any arrangements",
    "Constant suicidal thoughts with a definite plan.",
    "Recent suicide attempt.",
    "Record of suicide attempts that required needed intervention on some level, personal or professional.",
    "Family record of depression.",
    "Family record of fixation with suicidal thoughts.",
    "Social estrangement, indolence, and lack of interest with verbalization of wanting to die",
    "A hopeless, depressing attitude about life tied with recent life events that strengthen this.",
    "Abrupt change in attitude from being depressed to cheerful and in tranquility while there has been no real solution to any dilemmas, issues, or problems.",
  ];

  const shortTermGoals = ["Goal 1", "Goal 2", "Goal 3"];
  const LongTermGoals = ["Goal 1", "Goal 2"];

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const presenting_data = useWatch({
    control,
    name: "presenting_data",
  });

  //  queries
  const { data: diagnosisData } = FetchDiagnosisDataQuery();

  const { ref: FrequentThoughtsRef, FrequentThoughtsReg } =
    register("Frequent_Thoughts");
  const { ref: continuingSuicidalRef, continuingSuicidalReg } = register(
    "Continuing_Suicidal"
  );
  const { ref: ConstantSuicidalRef, ConstantSuicidalReg } =
    register("Constant_Suicidal");
  const { ref: RecentSuicideRef, RecentSuicideReg } =
    register("Recent_Suicide");
  const { ref: RecordSuicideRef, RecordSuicideReg } =
    register("Record_Suicide");
  const { ref: RecordDepressionRef, RecordDepressionReg } =
    register("Record_Depression");
  const { ref: RecordFixationRef, RecordFixationReg } =
    register("Record_Fixation");
  const { ref: SocialEstrangementRef, SocialEstrangementReg } = register(
    "Social_Estrangement"
  );
  const { ref: DepressingAttitudeRef, DepressingAttitudeReg } = register(
    "Depressing_AttitudeRef"
  );
  const { ref: AbruptChangeRef, AbruptChangeReg } = register("Abrupt_Change");
  const { ref: GoalTermRef, GoalTermReg } = register("Goal_Term");
  const { ref: GoalTerm2Ref, GoalTerm2Reg } = register("Goal_Term2");
  const { ref: GoalTerm3Ref, GoalTerm3Reg } = register("Goal_Term3");
  const { ref: longGoalTermRef, longGoalTermReg } = register("long_goal_Term");
  const { ref: longGoalTerm2Ref, longGoalTerm2Reg } =
    register("long_goal_Term2");
  const { ref: longGoalTerm3Ref, longGoalTerm3Reg } =
    register("long_goal_Term3");

  const shortTermGoalsRef = [GoalTermRef, GoalTerm2Ref, GoalTerm3Ref];
  const shortTermGoalsReg = [GoalTermReg, GoalTerm2Reg, GoalTerm3Reg];
  const longTermGoalsRef = [
    longGoalTermRef,
    longGoalTerm2Ref,
    longGoalTerm3Ref,
  ];
  const longTermGoalsReg = [
    longGoalTermReg,
    longGoalTerm2Reg,
    longGoalTerm3Reg,
  ];

  const BehavioralDefinitionsRef = [
    FrequentThoughtsRef,
    continuingSuicidalRef,
    ConstantSuicidalRef,
    RecentSuicideRef,
    RecordSuicideRef,
    RecordDepressionRef,
    RecordFixationRef,
    SocialEstrangementRef,
    DepressingAttitudeRef,
    AbruptChangeRef,
  ];
  const BehavioralDefinitionsReg = [
    FrequentThoughtsReg,
    continuingSuicidalReg,
    ConstantSuicidalReg,
    RecentSuicideReg,
    RecordSuicideReg,
    RecordDepressionReg,
    RecordFixationReg,
    SocialEstrangementReg,
    DepressingAttitudeReg,
    AbruptChangeReg,
  ];

  return (
    <div className="my-8 space-y-8">
      <div>
        <Typography
          variant="body2"
          fontWeight={500}
          marginTop="2rem !important"
          marginBottom={1}
        >
          Diagnosis:
        </Typography>

        <Autocomplete
          value={presenting_data || []}
          onChange={(event, value) => {
            let ids = value?.map((val) => val.id);

            if (ids) setValue("presenting_data", value);
          }}
          options={
            diagnosisData?.data?.map((item) => {
              return {
                id: item.id,
                name: `${item.code} - ${item.name}`,
              };
            }) || []
          }
          disableCloseOnSelect
          disabled={disabled}
          getOptionLabel={(option) => option.name}
          renderOption={(prop, option) => <li {...prop}>{option?.name}</li>}
          isOptionEqualToValue={(option, value) => {
            return Boolean(option.id === value.id);
          }}
          multiple
          renderInput={(params) => (
            <TextField
              className="borderless-select"
              {...params}
              variant="standard"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={disabled}
                      onClick={() =>
                        openInNewTab("https://habitbetter.com/diagnosticcodes/")
                      }
                    >
                      <HiInformationCircle className="w-6 text-hb_orange-dark" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </div>
      <Typography variant="body" fontWeight={500} marginTop="2rem !important">
        Behavioral Definitions for Individuals with Suicidal Problems:
      </Typography>

      {BehavioralDefinitions?.map((el, idx) => {
        return (
          <CustomTextField
            key={idx}
            label={el}
            variant="standard"
            multiline
            rows={4}
            inputRef={BehavioralDefinitionsRef[idx]}
            {...BehavioralDefinitionsReg[idx]}
            disabled={disabled}
          />
        );
      })}
      <Typography
        variant="body"
        fontWeight={500}
        marginTop="2rem !important"
        className="mt-8 block"
      >
        Long Term Goals for Individudals with Suicidal Problems:
      </Typography>

      {LongTermGoals?.map((el, idx) => (
        <CustomTextField
          key={idx}
          label={el}
          variant="standard"
          multiline
          rows={4}
          inputRef={longTermGoalsRef[idx]}
          {...longTermGoalsReg[idx]}
          disabled={disabled}
        />
      ))}

      <Typography
        variant="body"
        fontWeight={500}
        marginTop="2rem !important"
        className="mt-8 block"
      >
        Short Term Goals for Individudals with Suicidal Problems:
      </Typography>

      {shortTermGoals?.map((el, idx) => (
        <CustomTextField
          label={el}
          variant="standard"
          multiline
          rows={4}
          inputRef={shortTermGoalsRef[idx]}
          {...shortTermGoalsReg[idx]}
          disabled={disabled}
          key={idx}
        />
      ))}
    </div>
  );
};

export default SuicideRisk;
