import { createContext, useState } from "react";

export const ClientContext = createContext(null);

export const ClientContextProvider = ({ children }) => {
  const [currentClient, setCurrentClient] = useState(null);
  const  [currentFamily, setCurrentFamily] = useState(null);

  return (
    <ClientContext.Provider value={{currentClient,setCurrentClient, currentFamily, setCurrentFamily }}>
      {children}
    </ClientContext.Provider>
  );
};
