import { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Grid } from "@mui/material";
import { CustomTextField } from "components";
import { PasswordField } from "components";
import { LoginUserQuery } from "./LoginQueries";
import { useToast } from "services";
import { EMAIL_NOT_VERIFIED } from "utils/Enums";
import { useLocalStorage } from "utils/CustomHooks";
import { useHistory } from "react-router-dom";

const LoginFormSchema = yup.object().shape({
  username: yup.string().required("Username or Email is a required field"),
  password: yup.string().required("Password is a required field"),
});

const LoginForm = () => {
  const responseToast = useToast();
  const history = useHistory();

  //states
  const [unVerifiedUser, setUnVerifiedUser] = useState("");
  const [formStep, setFormStep] = useLocalStorage("signupStep", 0);

  // react-hook-form
  const {
    register,
    handleSubmit,
    watch: loginFormCredentials,
    formState: { errors },
    setFocus,
  } = useForm({ resolver: yupResolver(LoginFormSchema) });

  const { ref: refRegisterUsername, ...RegisterLoginUsername } =
    register("username");
  const { ref: refRegisterPassword, ...RegisterLoginPassword } =
    register("password");

  //queries
  const { mutate: LoginUser } = LoginUserQuery(loginFormCredentials);

  //useEffects
  useEffect(() => {
    setFocus("username");
  }, [setFocus]);

  useEffect(() => {
    if (unVerifiedUser) {
      history.push(`/sign-up?email=${unVerifiedUser}`);
      setFormStep(1);
    } else {
      setFormStep(0);
    }
  }, [unVerifiedUser]);

  //handlers
  const submitLoginForm = (formData) => {
    LoginUser(formData, {
      onSuccess: () =>
        localStorage.setItem("userEmail-forgotPassword", formData.email),
      onError: (error) => {
        if (error?.errors) {
          responseToast.toastError("Something went wrong!", error.errors);
        } else if (error.message) {
          if (error.message === EMAIL_NOT_VERIFIED) {
            setUnVerifiedUser(error.data);
          }
          responseToast.toastError(error.message);
        }
      },
    });
  };

  return (
    <>
      <form id="login-form" onSubmit={handleSubmit(submitLoginForm)}>
        <Grid container>
          <Grid item xs={12} className="my-2">
            <CustomTextField
              required
              label="Username or Email *"
              type="text"
              inputRef={refRegisterUsername}
              {...RegisterLoginUsername}
              error={Boolean(errors.username)}
              helperText={errors.username && errors.username.message}
              autoComplete="username"
            />
          </Grid>
          <Grid item xs={12} className="my-2">
            <PasswordField
              required
              label="Password *"
              variant="standard"
              type="password"
              inputRef={refRegisterPassword}
              {...RegisterLoginPassword}
              error={Boolean(errors.password)}
              helperText={errors && errors.password && errors.password.message}
              autoComplete="password"
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default LoginForm;
