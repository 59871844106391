import { CustomTextField } from "components";
import MuiPhoneNumber from "material-ui-phone-number";
import { Controller } from "react-hook-form";

const EmergencyContactForm = ({errors, inputKey, register, control, onKeyDown, clientType}) => {
  const { ref: refparentFirstName, ...RegisterparentFirstName } = register("parent_firstname");
  const { ref: refparentLastName, ...RegisterparentLastName } = register("parent_lastname");
  const { ref: refparentEmail, ...RegisterparentEmail } = register("parent_email");
  const { ref: refEmergencyFirstName, ...RegisterEmergencyFirstName } = register("emergency_firstname");
  const { ref: refEmergencyLastName, ...RegisterEmergencyLastName } = register("emergency_lastname");
  const { ref: refEmergencyEmail, ...RegisterEmergencyEmail } = register("emergency_email");


  const refsAndRegisters = {
    parent: {
      refFirstName: refparentFirstName,
      refLastName: refparentLastName,
      refEmail: refparentEmail,
      RegisterFirstName : RegisterparentFirstName,
      RegisterLastName : RegisterparentLastName,
      RegisterEmail : RegisterparentEmail
    },
    emergency: {
      refFirstName:refEmergencyFirstName,
      refLastName: refEmergencyLastName,
      refEmail: refEmergencyEmail,
      RegisterFirstName : RegisterEmergencyFirstName,
      RegisterLastName : RegisterEmergencyLastName,
      RegisterEmail : RegisterEmergencyEmail
    }
  }

  return (
    <fieldset className="kamal-test bg-gray-100 p-5">
      <div className="my-4">
        <Controller
          name={`${inputKey}_firstname`}
          control={control}
          defaultValue=""
          render={({ field }) => (
            <CustomTextField
              {...field}
              required
              label={`First name ${clientType === "child" && inputKey === "parent" ? "*" :""}`}
              type="text"
              inputRef={refsAndRegisters?.[`${inputKey}`]?.refFirstName}
              {...refsAndRegisters?.[`${inputKey}`]?.RegisterFirstName}
              error={Boolean(errors?.[`${inputKey}_firstname`])}
              helperText={errors?.[`${inputKey}_firstname`] && errors?.[`${inputKey}_firstname`]?.message}
              autoComplete={`${inputKey}_firstname`}
              onKeyDown={onKeyDown}
            />
          )}
        />
      </div>
      <div className="my-4">
        <Controller
          name={`${inputKey}_lastname`}
          control={control}
          defaultValue=""
          render={({ field }) => (
            <CustomTextField
              {...field}
              required
              label={`Last name ${clientType === "child" && inputKey === "parent" ? "*" :""}`}
              type="text"
              inputRef={refsAndRegisters?.[`${inputKey}`].refLastName}
              {...refsAndRegisters?.[`${inputKey}`].RegisterLastName}
              error={Boolean(errors?.[`${inputKey}_lastname`])}
              helperText={errors?.[`${inputKey}_lastname`] && errors?.[`${inputKey}_lastname`].message}
              autoComplete={`${inputKey}_lastname`}
              onKeyDown={onKeyDown}
            />
          )}
        />
      </div>
      <div className="my-4">
        <Controller
          name={`${inputKey}_email`}
          control={control}
          defaultValue=""
          render={({ field }) => (
            <CustomTextField
              {...field}
              required
              label={`Email ${clientType === "child" && inputKey === "parent" ? "*" :""}`}
              type="email"
              inputRef={refsAndRegisters?.[`${inputKey}`]?.refEmail}
              {...refsAndRegisters?.[`${inputKey}`]?.RegisterEmail}
              error={Boolean(errors?.[`${inputKey}_email`])}
              helperText={errors?.[`${inputKey}_email`] && errors?.[`${inputKey}_email`]?.message}
              autoComplete={`${inputKey}_email`}
              onKeyDown={onKeyDown}
            />
          )}
        />
      </div>
      <div className="pt-2">
        <Controller
          control={control}
          name={`${inputKey}_phone`}
          render={({ field, fieldState }) => (
            <MuiPhoneNumber
              defaultCountry={"us"}
              value={field.value}
              onChange={field.onChange}
              sx={{
                width: "100%",
                input: { color: "#264265", fontSize: "1rem" },
                "& .MuiInputLabel-root": {
                  color: "#264265B2",
                  fontSize: "1rem",
                  marginTop: "-.4rem",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#000000CC",
                },
              }}
              onBlur={field.onBlur}
              name={field.name}
              error={Boolean(fieldState.error)}
              helperText={fieldState.error && fieldState.error.message}
              label={`Phone ${clientType === "child" && inputKey === "parent" ? "*" :""}`}
              onKeyDown={onKeyDown}
            />
          )}
        />
      </div>
    </fieldset>
  );
};
export default EmergencyContactForm;
