import React, { useState, useEffect, useContext } from "react";
import ChatIcon from "@mui/icons-material/Chat";
import { Button } from "@mui/material";
import "../../index.scss";
import AddClientModal from "Pages/Clients/InviteClientModal";
import { ClientContext } from "utils/Context";

const { useChannelStateContext } = require("stream-chat-react");

export const CustomChannelHeader = (props) => {
  const {currentFamily} = useContext(ClientContext);
  const { isAdd, memberEmail, handleClose } = props;
  const [userName, setUsername] = useState("");

  const { channel } = useChannelStateContext();
  const [openAddClientModal, setOpenAddClientModal] = useState(false);
  const handleClickOpenAddClientModal = () => {
    setOpenAddClientModal(true);
  };
  const handleCloseAddClientModal = () => {
    setOpenAddClientModal(false);
  };

  useEffect(() => {
    if (Boolean(channel)) {
      const user = Object.keys(channel.state.members).filter(
        (key) => channel.state.members[key]?.user?.type === "user"
      );
      setUsername(channel.state.members[user]?.user?.name || channel?.data?.name);
    }
  }, [channel]);

  return (
    <>
      <AddClientModal
        handleCloseInvite={handleClose}
        open={openAddClientModal}
        handleClose={handleCloseAddClientModal}
        clientRequestEmail={memberEmail}
      />
      <div className="str-chat__header-livestream flex items-center justify-between">
        <div className="flex items-center rounded-t bg-white">
          <ChatIcon
            sx={{ color: "#56CCF2", fontSize: "1.8rem", marginRight: ".5rem" }}
          />
          <p className="text-lg text-hb_blue-800">Message- {userName}</p>
        </div>
        <div>
          {isAdd && (
            <Button
              className="mt-8 bg-hb_orange-dark px-8 py-2 text-white "
              variant="contained"
              onClick={handleClickOpenAddClientModal}
            >
              Invite client
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
