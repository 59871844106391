import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import "./StripeCardComponent.scss";
import { Button, Grid } from "@mui/material";
import { useMemo } from "react";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const StripeCardComponent = (props) => {
  const { handleStepChange } = props;
  return (
    <div className="mx-auto">
      <Elements stripe={stripePromise}>
        <SplitForm handleStepChange={handleStepChange} />
      </Elements>
    </div>
  );
};
const CardElementWrapper = ({ children }) => {
  return (
    <Grid item className="!mb-2 !mt-2 rounded-lg border-2 px-4 py-3">
      {children}
    </Grid>
  );
};

// const useOptions = () => {
//   return {
//     style: {
//       base: {
//         color: "#424770",
//         letterSpacing: "0.025em",
//         fontFamily: "Source Code Pro, monospace",
//         "::placeholder": {
//           color: "#aab7c4",
//         },
//       },
//       invalid: {
//         color: "#9e2146",
//       },
//     },
//   };
// };

const SplitForm = (props) => {
  const { handleStepChange } = props;
  const stripe = useStripe();
  const elements = useElements();

  // stripe.createToken({
  //   type: "BankAccount",
  //   accountHolderName: getValues("bank_name"),
  //   accountHolderType: "Company",
  //   accountNumber: getValues("account_number").toString(),
  //   country: "US",
  //   currency: "usd",
  //   routingNumber: getValues("routing_number").toString(),
  // });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
    });

    if (payload.paymentMethod?.id) {
      handleStepChange(2, payload.paymentMethod?.id);
    }
  };
  const cardNumberOption = useMemo(
    () => ({
      showIcon: true,
      style: {
        base: {
          fontSize: "15px",
          color: "#424770",
          letterSpacing: "0.025em",
          height: "100px",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#AAB7C4",
          },
        },
        invalid: {
          color: "#9E2146",
        },
      },
      placeholder: "Card number",
    }),
    []
  );
  const cardExpiryOption = useMemo(
    () => ({
      style: {
        base: {
          fontSize: "15px",
          color: "#424770",
          letterSpacing: "0.025em",
          height: "100px",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#AAB7C4",
          },
        },
        invalid: {
          color: "#9E2146",
        },
      },
      placeholder: "Expiration date (MM/YY)",
    }),
    []
  );
  const cardCvvOption = useMemo(
    () => ({
      style: {
        base: {
          fontSize: "15px",
          color: "#424770",
          letterSpacing: "0.025em",
          height: "100px",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#AAB7C4",
          },
        },
        invalid: {
          color: "#9E2146",
        },
      },
      placeholder: "Security code (CVV)",
    }),
    []
  );

  return (
    <form onSubmit={handleSubmit} className="stripe-card-component">
      <div className="my-2">
        <CardElementWrapper>
          <CardNumberElement options={cardNumberOption} />
        </CardElementWrapper>
      </div>
      <div className="my-2">
        <CardElementWrapper>
          <CardExpiryElement options={cardExpiryOption} />
        </CardElementWrapper>
      </div>
      <div className="my-2">
        <CardElementWrapper>
          <CardCvcElement options={cardCvvOption} />
        </CardElementWrapper>
      </div>
      <div className="my-2 flex  justify-center">
        <Button
          color="orange"
          variant="contained"
          type="submit"
          fullWidth
          disabled={!stripe}
          style={{ paddingBlock: ".5rem" }}
        >
          ADD CARD
        </Button>
      </div>
    </form>
  );
};

export default StripeCardComponent;
